import React, { useState } from "react";
import IntlTelInput from "react-intl-tel-input";

import { INCLUDE_COUNTRIES } from "constants/country";
import { useUtils } from "hooks";

const IntTelInput = ({
    fieldName = "",
    fieldId = "",
    inputClassName = "",
    placeholder = "",
    separateDialCode = true,
    defaultCountry = "de",
    preferredCountries = ["de", "at", "ch", "lu", "gb"],
    onPhoneNumberChange = null,
    onSelectFlag = null,
    onPhoneNumberBlur = null,
    defaultValue = "",
    value = "",
    error = "",
    label = "",
    errorIcon = true,
    errorMessage = "",
    className = "",
    errorClassName = "error-element",
    style = {},
    showPlaceholderOnTop = true,
    formatOnInit = false,
    disabled = false,
    ...rest
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { isTenantFinexity } = useUtils();
    const countriesWithoutUAE = INCLUDE_COUNTRIES.filter((item) => item !== "ae");

    return (
        <div
            className={`intlTelInput form-element ${className} ${error ? errorClassName : ""} ${
                showPlaceholderOnTop && "show-placeholder-on-top"
            }`}
            style={style}
        >
            {label && <label className="mb-2 d-inline-block">{label}</label>}
            <div className="position-relative">
                <IntlTelInput
                    {...rest}
                    formatOnInit={formatOnInit}
                    onPhoneNumberFocus={() => {
                        setIsFocused(true);
                    }}
                    disabled={disabled}
                    fieldName={fieldName}
                    fieldId={fieldId}
                    useMobileFullscreenDropdown={false}
                    inputClassName={`form-input ${inputClassName}`}
                    separateDialCode={separateDialCode}
                    defaultCountry={defaultCountry}
                    preferredCountries={preferredCountries}
                    onPhoneNumberChange={onPhoneNumberChange}
                    placeholder={!showPlaceholderOnTop ? placeholder : ""}
                    onSelectFlag={onSelectFlag}
                    onPhoneNumberBlur={(event) => {
                        onPhoneNumberBlur(event);
                        setIsFocused(false);
                    }}
                    defaultValue={defaultValue}
                    value={value}
                    onlyCountries={isTenantFinexity ? [...INCLUDE_COUNTRIES] : [...countriesWithoutUAE]}
                />
                {showPlaceholderOnTop && (
                    <span className={`form-label ${isFocused || value ? "lable-top" : ""}`}>{placeholder}</span>
                )}
                {error && (
                    <>
                        {errorMessage && errorIcon && <i className={`fas fa-exclamation-triangle ${label && "with-label"}`} />}
                        {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                    </>
                )}
            </div>
        </div>
    );
};

export default IntTelInput;

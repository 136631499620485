import React, { useEffect } from "react";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import TopGreyDiv from "../components/topGreyDiv";
import PrevButton from "components/PrevButton";

const messages = {
    rifCloseTitle: "wallet_payout_title",
    boEditWalletBalanceTitle: "available_balance",
    availableAmount: "available_balance",
    rifStartTitle: "activation_for_withdrawals",
    rifStartContent1: "expired_identification_data",
    rifStartContent2: "re_enter_id",
    rifStartContent3: "update_your_id",
    rifStartStepTitle: "how_activation_work",
    rifStartStepContent: "use_webcame_or_camera",
    rifStartStep1: "capture_front_id_card",
    rifStartStep2: "capture_back_id_card",
    rifStartStepBottomText: "data_then_transmitted",
    rifStartButtonText: "enter_id_card_data",
};

const Start = ({ data, setCloseTitle, setStep, setRetakeStep, backSlideEffect = "", setBackSlideEffect }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setCloseTitle(formatMessage(messages.rifCloseTitle));
    }, []);

    return (
        <div className={`retakeif-start ${backSlideEffect}`}>
            <TopGreyDiv amount={data.payInWalletBalance} text={formatMessage(messages.boEditWalletBalanceTitle)} />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3 className="mb-6">{formatMessage(messages.rifStartTitle)}</h3>
                <p className="text-16 mb-6">{formatMessage(messages.rifStartContent1)}</p>
                <p className="text-16 mb-8">{formatMessage(messages.rifStartContent2)}</p>
                <p className="text-16 mb-8 fw-600">{formatMessage(messages.rifStartContent3)}</p>
                <h3 className="mb-6">{formatMessage(messages.rifStartStepTitle)}</h3>
                <p className="text-16 mb-6">{formatMessage(messages.rifStartStepContent)}</p>
                <h4 className="mb-4">{formatMessage(messages.rifStartStep1)}</h4>
                <img className="mb-6 mw-100" src={"/images/id-front.jpg"} alt="" />
                <h4 className="mt-0 mb-4">{formatMessage(messages.rifStartStep2)}</h4>
                <img className="mb-6 mw-100" src={"/images/id-back.jpg"} alt="" />
                <p className="text-16 mb-8">{formatMessage(messages.rifStartStepBottomText)}</p>
                <button
                    className="mw-100"
                    onClick={() => {
                        setRetakeStep("front");
                        setBackSlideEffect("step-slide-left");
                    }}
                >
                    {formatMessage(messages.rifStartButtonText)}
                </button>
            </div>
            <div className="bottom-bar-container">
                <PrevButton
                    onClick={() => {
                        setStep("walletOverview");
                        setBackSlideEffect("step-slide-right");
                    }}
                />
            </div>
        </div>
    );
};

export default Start;

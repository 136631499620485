import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useRouter } from "next/router";

import {
    selectIsUserStatusEmailVerified,
    selectUserTermsAndConditionsAccepted,
    selectIsUserFetched,
    selectIsUserOnboardingCompleted,
} from "store/user/account/selectors";

import { PRIVATE_ROUTE } from "constants/index";

const CheckUserRole = ({
    children,
    isUserFetched,
    isUserStatusEmailVerified,
    isUserOnboardingCompleted,
    isUserTermsAndConditionsAccepted,
}) => {
    const router = useRouter();

    useEffect(() => {
        if (isUserFetched) {
            const url = window.location.href;
            if (
                PRIVATE_ROUTE.findIndex((route) => url.search(route) > -1) > -1 ||
                url.includes(`${window.location.origin}/token#`)
            ) {
                if (!url.includes("email-confirmation") && !isUserStatusEmailVerified) router.push("/email-confirmation");
                else if (!url.includes("user-onboarding") && isUserStatusEmailVerified && !isUserOnboardingCompleted)
                    router.push("/user-onboarding");
                else if (
                    !url.includes("retail/accept-terms-conditions") &&
                    !isUserTermsAndConditionsAccepted &&
                    isUserOnboardingCompleted &&
                    isUserStatusEmailVerified
                )
                    router.push("/retail/accept-terms-conditions");
                else if (
                    isUserStatusEmailVerified &&
                    isUserOnboardingCompleted &&
                    isUserTermsAndConditionsAccepted &&
                    (url.includes("/token") ||
                        url.includes("email-confirmation") ||
                        url.includes("user-onboarding") ||
                        url.includes("retail/accept-terms-conditions"))
                )
                    router.push("/dashboard");
            }
        }
    }, [isUserFetched, isUserStatusEmailVerified, isUserOnboardingCompleted, isUserTermsAndConditionsAccepted]);

    return children;
};

const mapStateToProps = createStructuredSelector({
    isUserFetched: selectIsUserFetched,
    isUserStatusEmailVerified: selectIsUserStatusEmailVerified,
    isUserTermsAndConditionsAccepted: selectUserTermsAndConditionsAccepted,
    isUserOnboardingCompleted: selectIsUserOnboardingCompleted,
});

export default connect(mapStateToProps)(CheckUserRole);

import KYCActionTypes from "./types";
import { extractKYCdata } from "store/helpers";

const USER_INITIAL_STATE = {
    kycData: {
        Phone: [],
        Birthday: "1995-01-01",
        Address: [],
        BankAccount: [],
        Identification: {},
    },
    kycFormData: {
        identification: {},
        personal: {},
        bankAccount: {},
    },
    hasUserInitiated: false,
    isUserKYCed: false,
    isSubmittingKYC: false,
    idNowStatus: null,
    submittedPostIdent: false,
    idNowRedirectUrl: null,
    reKYC: false,
    idNowStatus: null,
    providerData: {},
    isKYCServerError: false,
};
const INITIAL_STATE = {
    ...USER_INITIAL_STATE,
    adminSelectedUser: {
        ...USER_INITIAL_STATE,
    },
};

const kycReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        //Combined as return is same, split if need to change singularly.
        case KYCActionTypes.SET_KYC_START:
            return {
                ...state,
                isSubmittingKYC: true,
                error: null,
            };
        case KYCActionTypes.FETCH_KYC_START:
        case KYCActionTypes.FETCH_KYC_FROM_PROVIDER_START:
        case KYCActionTypes.FETCH_IDNOW_START:
        case KYCActionTypes.FETCH_POSTIDENT_START:
        case KYCActionTypes.FETCH_REKYC_START:
        case KYCActionTypes.SET_REKYC_START:
            return {
                ...state,
                isLoading: true,
            };
        case KYCActionTypes.SET_KYC_SUCCESS:
        case KYCActionTypes.FETCH_KYC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSubmittingKYC: false,
                kycData: payload.kycData || state.kycData,
                kycFormData: payload.kycFormData || state.kycFormData,
                hasUserInitiated: payload.hasUserInitiated || state.hasUserInitiated,
            };
        case KYCActionTypes.FETCH_KYC_FROM_PROVIDER_SUCCESS:
            return {
                ...state,
                isUserKYCed: payload.isUserKYCed,
                providerData: payload.providerData,
            };
        case KYCActionTypes.FETCH_IDNOW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                idNowRedirectUrl: payload.idNowRedirectUrl,
                idNowStatus: payload.idNowStatus,
            };
        case KYCActionTypes.FETCH_POSTIDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                submittedPostIdent: payload.submittedPostIdent,
            };
        case KYCActionTypes.FETCH_REKYC_SUCCESS:
        case KYCActionTypes.SET_REKYC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                reKYC: payload.reKYC,
            };

        case KYCActionTypes.FETCH_KYC_SERVER_ERROR:
            return {
                ...state,
                isKYCServerError: payload,
            };
        //Combined as return is same, split if need to change singularly.
        case KYCActionTypes.SET_KYC_FAILURE:
        case KYCActionTypes.FETCH_KYC_FAILURE:
        case KYCActionTypes.FETCH_KYC_FROM_PROVIDER_FAILURE:
        case KYCActionTypes.FETCH_KYC_FAILURE:
        case KYCActionTypes.SET_KYC_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
                isSubmittingKYC: false,
            };
        case KYCActionTypes.UPDATE_KYC_FORM_DATA:
            return {
                ...state,
                isLoading: false,
                isSubmittingKYC: false,
                kycFormData: {
                    ...state.kycFormData,
                    identification: {
                        ...state.kycFormData?.identification,
                        ...payload.identification,
                    },
                    personal: {
                        ...state.kycFormData?.personal,
                        ...payload.personal,
                    },
                },
            };
        case KYCActionTypes.RESET_KYC_FORM_DATA:
            const kycFormData = extractKYCdata(state.kycData);
            return {
                ...state,
                kycFormData: kycFormData,
            };

        //
        // ADMIN
        //
        case KYCActionTypes.FETCH_KYC_START_ADMIN:
            return {
                ...state,
                adminSelectedUser: {
                    ...state?.adminSelectedUser,
                    isLoading: true,
                    kycData: {},
                    kycFormData: {},
                    isUserKYCed: false,
                    hasUserInitiated: false,
                    reKYC: false,
                    providerData: {},
                },
            };
        case KYCActionTypes.FETCH_KYC_SUCCESS_ADMIN:
            return {
                ...state,
                adminSelectedUser: {
                    ...state.adminSelectedUser,
                    isLoading: false,
                    kycData: payload.kycData || state.kycData,
                    kycFormData: payload.kycFormData || state.kycFormData,
                    isUserKYCed: payload.isUserKYCed,
                    hasUserInitiated: payload.hasUserInitiated || state.hasUserInitiated,
                    reKYC: payload.reKYC,
                    providerData: payload.providerData,
                },
            };
        case KYCActionTypes.FETCH_KYC_FAILURE_ADMIN:
            return {
                ...state,
                adminSelectedUser: {
                    ...state?.adminSelectedUser,
                    isLoading: false,
                    error: payload,
                },
            };
        case KYCActionTypes.FETCH_IDNOW_SUCCESS_ADMIN:
            return {
                ...state,
                adminSelectedUser: {
                    ...state?.adminSelectedUser,
                    isLoading: false,
                    idNowRedirectUrl: payload.idNowRedirectUrl,
                    idNowStatus: payload.idNowStatus,
                },
            };
        case KYCActionTypes.FETCH_REKYC_SUCCESS_ADMIN:
            return {
                ...state,
                adminSelectedUser: {
                    ...state?.adminSelectedUser,
                    isLoading: false,
                    reKYC: payload.reKYC,
                },
            };

        case KYCActionTypes.UPDATE_KYC_ADMIN:
            return {
                ...state,
                adminSelectedUser: {
                    ...state?.adminSelectedUser,
                    isUserKYCed: payload.isUserKYCed,
                    hasUserInitiated: payload.hasUserInitiated,
                    Provider: payload.Provider,
                    reKYC: payload.reKYC,
                },
            };

        default:
            return state;
    }
};

export default kycReducer;

export const inviteCustomers = "kunden_einladen";
export const clubDealForm = "bewerbung_einreichen";
export const createPartnerAccount = "create_partner_account";
export const createAccount = "account_erstellen";
export const login = "auth_login";
export const backButtonText = "back_button_text";
export const makeNoteButtonText = "make_note_button_text";
export const successfullyMarkedButtonText = "successfully_marked_button_text";
export const successfullyCompletedButtonText = "successfully_completed_button_text";
export const investNowButtonText = "invest_now_button";
export const joinNowButtonText = "join_now";
export const applyNowButtonText = "apply_now";
export const claimEBook = "claim_e_book";
export const toInvestment = "to_investment";
export const registerNow = "register_now_";
export const requestInformation = "request_information_button_text";
export const pausedOffersButtonText = "paused_offers_button_text";
export const successfullyPaidBackButtonText = "successfully_paid_back_button_text";
export const purchaseOffersButtonText = "buy_purchase_offers";
export const salesOffersButtonText = "sell";
export const sendDigitalPartsButtonText = "send_digital_parts_button_text";
export const requestInformationButtonText = "request_information_button_text";
export const makeAppointmentButtonText = "make_appointment";
export const nextButtonText = "continue_button";
export const pleaseWaitButtonText = "please_wait_button";
export const requestInformationClubButtonText = "request_information_button_text";
export const adequacyCancelReturnOverview = "cancle_and_return_to_overview";
export const adequacySkipLinkText = "cancle_adequacy_check";
export const investmentCancelLinkText = "form_cancel";
export const createBlockchainWallet = "create_blockchain_wallet";
export const finishButtonText = "finish_button_text";

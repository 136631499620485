import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import AdequacyModal from "components/AdequacyModal";
import { setAdequacyStart } from "store/user/adequacy/actions";
import { selectAdequacy } from "store/user/adequacy/selectors";
import { userDashboardDetailsStart } from "store/user/account/actions";
import { useFormattedMessage } from "hooks";
import { TILE_STATUS } from "constants/status";
import ArrowNext from "assets/images/svg/next.svg";
import IntlFormattedMessage from "components/IntlFormattedMessage";

const messages = {
    adequacyTileTitle: "experience_and_knowledge",
    adequacyTileContent: "adequacy_tile_content",
    adequacyTileErrorContent: "adequacy_tile_error_content",
    tileComingSoonDeactivateButtonText: "available_shortly",
    tileComingSoonPendingButtonText: "in_processing",
};

const WrappedAdequacyModal = ({
    StatusAdequacy,
    adequacyData,
    userDashboardDetailsStart,
    setAdequacyModalFlag,
    adequacyModalFlag,
    onCloseButtonClick,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [activeAdequacyStep, setActiveAdequacyStep] = useState(adequacyData.step);
    const [adequacyAtStep1, setAdequacyAtStep1] = useState(false);
    const [adequacyBack, setAdequacyBack] = useState(false);

    const comingSoonMessages = {
        [TILE_STATUS.DEACTIVATE]: formatMessage(messages.tileComingSoonDeactivateButtonText),
        [TILE_STATUS.PENDING]: formatMessage(messages.tileComingSoonPendingButtonText),
    };

    const statusClassName = {
        success: "tile-success",
        deactivate: "tile-deactive",
        initiated: "tile-initiate",
        pending: "tile-deactive",
        error: "tile-error",
    };

    const statusIcon = {
        success: "check",
        error: "times",
    };

    useEffect(() => {
        if (adequacyAtStep1) {
            setAdequacyModalFlag(false);
            setAdequacyAtStep1(false);
        }
    }, [adequacyAtStep1]);

    useEffect(() => {
        !adequacyData && setAdequacyStart({ step: 1 });
    }, []);

    useEffect(() => {
        adequacyData && setActiveAdequacyStep(adequacyData.step);
    }, [adequacyData]);
    const tileMessage =
        (StatusAdequacy === TILE_STATUS.ERROR ? messages.adequacyTileErrorContent : messages.adequacyTileContent) || {};
    return (
        <Fragment>
            <AdequacyModal
                open={adequacyModalFlag}
                adequacyBack={adequacyBack}
                setAdequacyBack={() => setAdequacyBack(false)}
                setAdequacyAtStep1={() => setAdequacyAtStep1(true)}
                onCloseButtonClick={onCloseButtonClick}
                onBackButtonClick={() => setAdequacyModalFlag(false)}
                step={activeAdequacyStep}
                userDashboardDetailsStart={userDashboardDetailsStart}
                isWithSetting
                StatusAdequacy={StatusAdequacy}
            />
            {StatusAdequacy != "renew" && (
                <div
                    className={`identity-block adequacy-block experience-block pointer border-radius bg-black ${
                        statusClassName[StatusAdequacy] || ""
                    }`}
                    onClick={() => setAdequacyModalFlag(true)}
                >
                    {StatusAdequacy === TILE_STATUS.ERROR && (
                        <div className="status-icon-container">
                            <div className="status-icon">
                                <i className={`fas fa-${statusIcon[StatusAdequacy] || ""}`} />
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-between slign-center">
                        <span className="body-medium">{formatMessage(messages.adequacyTileTitle)}</span>
                        <span className="next-arrow">
                            <ArrowNext />
                        </span>
                    </div>
                    <div className="equal description">
                        <div className="my-0 text-16 fw-600">
                            <IntlFormattedMessage id={tileMessage} />
                        </div>
                    </div>
                    {comingSoonMessages[StatusAdequacy] && (
                        <button className="coming-soon-btn medium mb-0">{comingSoonMessages[StatusAdequacy]}</button>
                    )}
                </div>
            )}
        </Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
});

const mapDispatchToProps = (dispatch) => ({
    userDashboardDetailsStart: () => dispatch(userDashboardDetailsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdequacyModal);

import React from "react";
import { isClient } from "utils/index";

const decodeHtmlEntities = (inputStr) => {
    if (!isClient()) return inputStr;
    const textarea = document.createElement("textarea");
    textarea.innerHTML = inputStr;
    return textarea.value;
};

const TextArea = ({
    className = "",
    name = "",
    placeholder = "",
    value = "",
    errorMessage = "",
    onChange,
    textAreaClassName = "",
    errorClassName = "error-element",
    success = false,
    currency = "",
    errorIcon = true,
    error,
    label,
    labelClassName,
    disabled,
    ...rest
}) => (
    <div className={`form-element mb-0 ${className} ${error ? errorClassName : ""}`} data-role="fieldcontain">
        {label && <label className={`mb-2 d-inline-block ${labelClassName}`}>{label}</label>}
        <textarea
            disabled={disabled}
            {...rest}
            name={name}
            className={textAreaClassName}
            placeholder={placeholder}
            value={decodeHtmlEntities(value)}
            onChange={onChange}
        />
        {currency && <span className="currency">{currency}</span>}
        {success && !errorMessage && <i className="fas fa-check" />}
        {error && (
            <>
                {errorMessage && errorIcon && <i className="fas fa-exclamation-triangle" />}
                {errorMessage && <label className="error-label mt-0">{errorMessage}</label>}
            </>
        )}
    </div>
);

export default TextArea;

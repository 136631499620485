import React from "react";
import Bank from "assets/images/svg/bank.svg";
import { useFormattedMessage } from "hooks";
import { friendlyFormatIBAN } from "ibantools";
import CustomFormatNumber from "components/CustomFormatNumber";
import ToolTip from "components/ToolTip";

// let data1 = {
//     Owner: "Julian Volz",
//     IBAN: "DE89370400440532013000",
//     BIC: "GENODEF1HH2",
//     referenceNumber: "-------",
// };

const fallback = {
    Owner: "------",
    IBAN: "------",
    BIC: "------",
    referenceNumber: "------",
};

const messages = {
    amount: "amount",
    accountOwner: "owner",
    referenceNumber: "reference_number",
    country: "country_placeholder",
    ibanInfoText: "iban_info_text",
};

const AccountDetailsBlock = ({ className = "", data = {}, showReferrenceNumber = false, amount = 0 }) => {
    const { formatMessage } = useFormattedMessage();
    const newData = data || fallback;

    return (
        <div className={`card-container p-5 flex ${className}`}>
            <div className="round-holder mr-4 bg-dark">
                <Bank />
            </div>
            <div className="w-100">
                <span className="text-14 d-block mb-3">
                    <span className="another-grey-color d-inline-block mr-2 fw-500">{formatMessage(messages.accountOwner)}</span>
                    <span className="fw-600">{newData.Owner}</span>
                </span>
                <span className="text-14 d-block mb-3">
                    <span className="another-grey-color d-inline-flex mr-2 fw-500">
                        IBAN{" "}
                        <span
                            className="question ml-2"
                            data-tooltip-id="iban"
                            data-tooltip-content={formatMessage(messages.ibanInfoText)}
                        >
                            ?
                        </span>
                    </span>
                    <span className="fw-600 font-noto-serif">{friendlyFormatIBAN(newData.IBAN)}</span>
                </span>
                {showReferrenceNumber ? (
                    <span className="text-14 d-block mb-3">
                        <span className="another-grey-color d-inline-block mr-2 fw-500">BIC</span>
                        <span className="fw-600 font-noto-serif">{newData.BIC}</span>
                    </span>
                ) : (
                    <div className="flex justify-between mb-3">
                        <span className="text-14">
                            <span className="another-grey-color d-inline-block mr-2 fw-500">BIC</span>
                            <span className="fw-600 font-noto-serif">{newData.BIC}</span>
                        </span>
                        {/* <span className="text-14 another-grey-color mr-4 d-inline-block fw-500">Heute</span> */}
                    </div>
                )}
                {data?.Country ? (
                    <span className="text-14 d-block mb-3">
                        <span className="another-grey-color d-inline-block mr-2 fw-500">{formatMessage(messages.country)}</span>
                        <span className="fw-600">{data.Country}</span>
                    </span>
                ) : null}
                {amount !== 0 ? (
                    <span className="text-14 d-block mb-3">
                        <span className="another-grey-color d-inline-block mr-2 fw-500">{formatMessage(messages.amount)}</span>
                        <span className="fw-600">
                            <CustomFormatNumber value={amount} />
                        </span>
                    </span>
                ) : null}
                {showReferrenceNumber ? (
                    <span className="text-14 d-block">
                        <span className="another-grey-color d-inline-block mr-2 fw-500">
                            {formatMessage(messages.referenceNumber)}
                        </span>
                        <span className="fw-600">{newData.referenceNumber}</span>
                    </span>
                ) : null}
            </div>
            <ToolTip id="iban" />
        </div>
    );
};

export default AccountDetailsBlock;

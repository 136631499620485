import CrmActionTypes from "./types";

export function fetchUserCallLogs(payload) {
    return {
        type: CrmActionTypes.FETCH_USER_CALL_LOGS,
        payload,
    };
}

export function fetchAllInfoForAUser(payload) {
    return {
        type: CrmActionTypes.FETCH_ALL_INFO_FOR_A_USER,
        payload,
    };
}

export function fetchSelectedCallLog(payload) {
    return {
        type: CrmActionTypes.FETCH_SELECTED_CALL_LOG,
        payload,
    };
}

export function setSelectedCallLog(payload) {
    return {
        type: CrmActionTypes.SET_SELECTED_CALL_LOG,
        payload,
    };
}

export function updateUserActivity(payload) {
    return {
        type: CrmActionTypes.UPDATE_USER_ACTIVITY,
        payload,
    };
}

export function updateData(payload) {
    return {
        type: CrmActionTypes.UPDATE_DATA,
        payload,
    };
}

export function setPrefillNumber(payload) {
    return {
        type: CrmActionTypes.SET_PREFILL_NUMBER,
        payload,
    };
}

export function setCreateNewUserStatus(payload) {
    return {
        type: CrmActionTypes.SET_CREATE_NEW_USER_STATUS,
        payload,
    };
}

export function setActionsData(payload) {
    return {
        type: CrmActionTypes.SET_ACTIONS_DATA,
        payload,
    };
}

export function setSelectedAction(payload) {
    return {
        type: CrmActionTypes.SET_SELECTED_ACTION,
        payload,
    };
}

export function addActivity(payload) {
    return {
        type: CrmActionTypes.ADD_ACTIVITY,
        payload,
    };
}

export function fetchAllInfoForAnInvestment(payload) {
    return {
        type: CrmActionTypes.FETCH_ALL_INFO_FOR_AN_INVESTMENT,
        payload,
    };
}

export function updateInvestmentData(payload) {
    return {
        type: CrmActionTypes.UPDATE_INVESTMENT_DATA,
        payload,
    };
}

export function setInvestmentActionsData(payload) {
    return {
        type: CrmActionTypes.SET_INVESTMENT_ACTIONS_DATA,
        payload,
    };
}

export function deleteActivity(payload) {
    return {
        type: CrmActionTypes.DELETE_ACTIVITY,
        payload,
    };
}

export function removeEmail(payload) {
    return {
        type: CrmActionTypes.REMOVE_Email,
        payload,
    };
}

export function addEmail(payload) {
    return {
        type: CrmActionTypes.ADD_EMAIL,
        payload,
    };
}

import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage } from "hooks";
import SideModal from "components/SideModal";
import LanguageModal from "components/Settings/language";
import NewsLetterNotification from "components/Settings/newsLetternotification";
import DeleteAccountModal from "components/Settings/deleteAccount";
import DeleteAccountConfirmationModal from "components/Settings/DeleteAccountConfirmation";
import OverviewStep from "components/AdequacyModal/OverviewStep";
import ChangePasswordModal from "components/ChangePassword";
import ProfileModal from "components/Profile";
import { setAdequacyStart } from "store/user/adequacy/actions";
import { fetchUserSubscriptionsStart, userDashboardDetailsSuccess } from "store/user/account/actions";

import { selectAdequacy } from "store/user/adequacy/selectors";
import { selectUserDashboardDetails, selectUserDetails } from "store/user/account/selectors";
import { emailSubscriptionSupportedTenants } from "constants/index";

const defineText = {
    languageHeading: "settings_modal_language_heading",
    notificationHeading: "settings_modal_notification_heading",
    deleteAccountHeading: "delete_account",
    HeadingThird: "message_details",
    adequacyHeading: "experience_and_kowledge",
    changePasswordHeading: "change_password",
    myData: "meta_title_account_profile_page",
};

export const RenderInboxStep = ({
    step,
    setStep,
    onBackButtonClick,
    setAdequacyModalFlag,
    setAdequacyStart,
    userDashboardDetailsSuccess,
    onCloseButtonClick,
    preferredLanguage,
    setKycModalFlag,
}) => {
    const [backSlideEffect, setBackSlideEffect] = useState("");

    function renderStep(step) {
        switch (step) {
            case 1:
                return <LanguageModal locale={preferredLanguage} />;
            case 2:
                return <NewsLetterNotification step={step} setStep={setStep} onCloseButtonClick={onCloseButtonClick} />;
            case 3:
                return (
                    <DeleteAccountModal
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        step={step}
                        setStep={setStep}
                        onBackButtonClick={onBackButtonClick}
                    />
                );
            case 4:
                return (
                    <DeleteAccountConfirmationModal
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        step={step}
                        setStep={setStep}
                        onBackButtonClick={onBackButtonClick}
                    />
                );
            case 5:
                return (
                    <OverviewStep
                        backAndCountinueDisabled
                        onRenew={(userDashboardData, adequacyData) => {
                            setAdequacyStart &&
                                setAdequacyStart({
                                    step: 1,
                                    survey_second_step: {
                                        ...adequacyData.survey_second_step,
                                        step: 1,
                                    },
                                    survey_third_step: {
                                        ...adequacyData.survey_third_step,
                                        step: 1,
                                    },
                                });
                            userDashboardDetailsSuccess &&
                                userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "renew" });
                            setAdequacyModalFlag(true);
                        }}
                    />
                );
            case 6:
                return <ChangePasswordModal step={step} setStep={setStep} />;
            case 7:
                return (
                    <ProfileModal
                        open={step === 7}
                        onCloseButtonClick={() => {
                            setStep(null), onCloseButtonClick();
                        }}
                        onBackButtonClick={() => {
                            onBackButtonClick(), setStep(null);
                        }}
                        setKycModalFlag={setKycModalFlag}
                    />
                );

            default:
                return <LanguageModal step={step} setStep={setStep} locale={preferredLanguage} />;
        }
    }
    return renderStep(step);
};

const SettingsModal = ({
    open,
    onCloseButtonClick,
    locale,
    step,
    setStep,
    onBackButtonClick,
    setAdequacyModalFlag,
    adequacyData,
    setAdequacyStart,
    userDashboardDetailsSuccess,
    userDashboardData,
    preferredLanguage = "de",
    setKycModalFlag,
    fetchUserSubscriptionsStart,
    userData,
    currentTenant,
}) => {
    const { formatMessage } = useFormattedMessage();

    const renderHeading = (step) => {
        switch (step) {
            case 1:
                return formatMessage(defineText.languageHeading);
            case 2:
                return formatMessage(defineText.notificationHeading);
            case 3:
            case 4:
                return formatMessage(defineText.deleteAccountHeading);
            case 5:
                return formatMessage(defineText.adequacyHeading);
            case 6:
                return formatMessage(defineText.changePasswordHeading);
            case 7:
                return formatMessage(defineText.myData);
            default:
                return formatMessage(defineText.languageHeading);
        }
    };

    const memoizedRenderInboxStep = useMemo(() => {
        return (
            <RenderInboxStep
                step={step}
                setStep={setStep}
                onBackButtonClick={onBackButtonClick}
                onCloseButtonClick={onCloseButtonClick}
                locale={locale}
                setAdequacyModalFlag={setAdequacyModalFlag}
                adequacyData={adequacyData}
                setAdequacyStart={setAdequacyStart}
                userDashboardDetailsSuccess={userDashboardDetailsSuccess}
                userDashboardData={userDashboardData}
                preferredLanguage={preferredLanguage}
                setKycModalFlag={setKycModalFlag}
            />
        );
    }, [open, step, preferredLanguage]);

    useEffect(() => {
        emailSubscriptionSupportedTenants.includes(currentTenant) && fetchUserSubscriptionsStart(userData.uid);
    }, [open, userData?.uid]);

    return (
        <SideModal
            className="settings-modal"
            heading={renderHeading(step)}
            open={open}
            isBackArrowOnTop
            isCloseArrowOnRight
            onCloseButtonClick={onCloseButtonClick}
            onBackButtonClick={() => {
                step == 4 ? setStep(3) : (setStep(0), onBackButtonClick());
            }}
        >
            {memoizedRenderInboxStep}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
    userDashboardData: selectUserDashboardDetails,
    userData: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
    fetchUserSubscriptionsStart: (userId) => dispatch(fetchUserSubscriptionsStart(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createStructuredSelector } from "reselect";
import cn from "classnames";
import { withFormik } from "formik";
// import * as yup from "yup";
import { selectLocale } from "store/app/selectors";
import TopGreyDiv from "../components/topGreyDiv";
import AccountDetailsBlock from "../components/accountDetailsBlock";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import CurrencyInput from "components/CurrencyInput";
import { withdrawAmount as amountMessages } from "i18n/messages/formElements";
// import { payOutValidations } from "utils/yupValidation";
import { toGermanCurrency, toEnglishCurrency } from "utils";
import { mangopayPayOut } from "api";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { sendFormError } from "i18n/messages/errors";

const messages = {
    payOutTitle: "wallet_payout_title",
    availableAmount: "available_balance",
    payOutheading: "withdrawal_to_bank_account",
    amountInputLabel: "what_amount_to_paid_out",
    amountInputPlaceholder: "enter_amount_placeholder",
    totalAmount: "payout_total_amount",
    note: "wallet_payout_note",
    yourPayoutAccount: "your_payout_amount",
    withdrawlBankAccount: "payout_withdrawal_to_bank_account",
    edit: "form_edit",
    blockedPayOutErrorMessage: "wallet_blocked_payout_error_message",
};

const PayOut = ({
    setShowDate,
    setCloseTitle,
    data,
    locale,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    submitForm,
    validateForm,
    setFieldTouched,
    setFieldValue,
    setStep,
    status,
    stepData,
    setStepData,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.payOutTitle));
    }, []);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(
                formatMessage(
                    status.requestError === "mangopay_beneficiary_account_not_available"
                        ? messages.mangopayBeneficiaryAccountNotAvailable
                        : status.requestError === "mangopay_out_flows_blocked"
                        ? messages.blockedPayOutErrorMessage
                        : status.requestError === "mangopay_invalid_min_payout_amount"
                        ? amountMessages["minimumWithdrawAmount"]
                        : status.requestError === "mangopay_wallet_balance_insufficient"
                        ? amountMessages["amountExceed"]
                        : sendFormError
                )
            );
        }
    }, [status]);

    const setCurrencyData = (formattedAmount, resetAmount) => {
        setFieldValue("intlAmount", formattedAmount);
        setFieldValue("withdrawAmount", resetAmount);
        setFieldTouched("intlAmount", true);
        setStepData({
            ...stepData,
            payOut: {
                intlAmount: formattedAmount,
                withdrawAmount: resetAmount,
            },
        });
    };

    const setCompleteWithdrawAmount = () => {
        const walletBalance = data.payInWalletBalance.toString();
        const { formattedAmount, resetAmount } =
            locale === "en" ? toEnglishCurrency(walletBalance) : toGermanCurrency(walletBalance);
        setFieldValue("intlAmount", formattedAmount);
        setFieldValue("withdrawAmount", resetAmount);
        setFieldTouched("intlAmount", true);
        setStepData({
            ...stepData,
            payOut: {
                intlAmount: formattedAmount,
                withdrawAmount: resetAmount,
            },
        });
    };

    return (
        <div className={`pay-out ${backSlideEffect}`}>
            <TopGreyDiv text={formatMessage(messages.availableAmount)} amount={data.payInWalletBalance} />
            <div className="px-5 py-4 mw-450 mx-auto">
                <span className="text-22 fw-600 d-inline-block mt-6">{formatMessage(messages.withdrawlBankAccount)}</span>
                <span className="text-16 d-inline-block mb-8 mt-6">{formatMessage(messages.note)}</span>
                <span className="text-14 fw-600 d-inline-block mb-3">{formatMessage(messages.amountInputLabel)}</span>
                <CurrencyInput
                    className="mb-3"
                    inputSymbolClass="black-color"
                    inputClassName="text-input form-item"
                    id="intlAmount"
                    name="intlAmount"
                    hiddenInputName="withdrawAmount"
                    placeholder={formatMessage(messages.amountInputPlaceholder)}
                    onCurrencyInputChange={(formattedAmount, resetAmount) => setCurrencyData(formattedAmount, resetAmount)}
                    values={values}
                    onBlur={handleBlur}
                    errorMessage={
                        touched.intlAmount && errors.intlAmount
                            ? `${formatMessage(amountMessages[errors.intlAmount])} ${
                                  errors.intlAmount === "amountExceed"
                                      ? `${data.payInWalletBalance}${formatMessage(amountMessages["amountExceedEuros"])}`
                                      : ""
                              }`
                            : null
                    }
                    error={touched.intlAmount && errors.intlAmount}
                />
                <span
                    className="text-right d-block text-12 secondary-light-color cursor-pointer"
                    onClick={() => setCompleteWithdrawAmount()}
                >
                    {formatMessage(messages.totalAmount)}
                </span>
                <div className="flex justify-between">
                    <span className="text-16 d-inline-block mt-10 secondary-light-color">
                        {formatMessage(messages.yourPayoutAccount)}
                    </span>
                    <span
                        className="text-14 d-inline-block mt-10 primary-color cursor-pointer"
                        onClick={() => {
                            setStep("allBankAccount");
                            setStepData({
                                ...stepData,
                                allBankAccount: data,
                            });
                            setBackSlideEffect("step-slide-left");
                        }}
                    >
                        {formatMessage(messages.edit)}
                    </span>
                </div>

                <AccountDetailsBlock
                    className="mt-8 mb-5"
                    data={
                        data.selectedBankAccount && Object.keys(data.selectedBankAccount).length
                            ? data.selectedBankAccount
                            : data.bankAccount
                    }
                />

                {requestError && <span className="error pl-0">{requestError}</span>}
                <div className="bottom-bar-container mt-25">
                    <PrevButton
                        onClick={() => {
                            setStep("walletOverview");
                            setBackSlideEffect("step-slide-right");
                        }}
                        disable={isSubmitting}
                    />
                    <NextButton
                        className={cn({
                            "disable-grey": isSubmitting || errors.intlAmount,
                        })}
                        disable={isSubmitting || errors.intlAmount}
                        onClick={submitForm}
                        isLoading={isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            withdrawAmount: 0.0,
            intlAmount: "",
            ...(props.stepData && props.stepData.payOut && Object.keys(props.stepData.payOut).length
                ? props.stepData.payOut
                : {}),
        }),
        validateOnBlur: true,
        validateOnChange: true,
        // validationSchema: ({ data }) => {
        //     const { withdrawAmount } = payOutValidations
        //     return yup.object().shape({
        //         withdrawAmount: withdrawAmount("withdrawAmount", data.payInWalletBalance)
        //     })
        // },
        validate: (values, { data }) => {
            const errors = {};

            if (!values.withdrawAmount) {
                errors.intlAmount = "invalidAmount";
            } else if (parseFloat(values.withdrawAmount) > data.payInWalletBalance) {
                errors.intlAmount = "amountExceed";
            } else if (values.withdrawAmount < 20) {
                errors.intlAmount = "minimumWithdrawAmount";
            }

            return errors;
        },
        handleSubmit: (values, { props, setStatus, setSubmitting }) => {
            setSubmitting(true);

            const data = {
                bankAccountID:
                    props && props.data.selectedBankAccount && Object.keys(props.data.selectedBankAccount).length
                        ? props.data.selectedBankAccount.uid
                        : props.data.bankAccount && props.data.bankAccount.uid,
                paymentAmount: values.withdrawAmount,
            };

            mangopayPayOut(data)
                .then((res) => {
                    setSubmitting(false);
                    if (res.data && res.data.errors && res.data.errors[0]) {
                        setStatus({ requestError: res.data.errors[0].message });
                    }

                    if (!res || res.errors || res.data.errors || res.data.data.errors) return;

                    props.setStep("payOutConfirmation");
                    props.setStepData({
                        ...props.stepData,
                        payOutConfirmation: data,
                        payOut: values,
                    });
                    props.setBackSlideEffect("step-slide-left");
                })
                .catch(() => {
                    setSubmitting(false);
                });
        },
    })
)(PayOut);

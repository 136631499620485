import InvestmentActionTypes from "./types";

export const setInvestmentStepData = (payload) => ({
    type: InvestmentActionTypes.SET_INVESTMENT_STEP_DATA,
    payload,
});

export const clearInvestmentStepData = () => ({
    type: InvestmentActionTypes.CLEAR_INVESTMENT_STEP_DATA,
});

export const resetInvestmentStepData = () => ({
    type: InvestmentActionTypes.RESET_INVESTMENT_STEP_DATA,
});

export const setFilters = (filterBy) => ({
    type: InvestmentActionTypes.SET_FILTERS,
    payload: { filterBy },
});

export const setSortFilters = (sortBy) => ({
    type: InvestmentActionTypes.SET_SORT_FILTERS,
    payload: { sortBy },
});

export const createInvestMentDataStart = (payload) => ({
    type: InvestmentActionTypes.CREATE_INVESTMENT_DATA_START,
    payload,
});
export const createInvestMentDataSuccess = (payload) => ({
    type: InvestmentActionTypes.CREATE_INVESTMENT_DATA_SUCCESS,
    payload,
});
export const createInvestMentDataFailure = (error) => ({
    type: InvestmentActionTypes.CREATE_INVESTMENT_DATA_FAILURE,
    payload: error,
});

export const setInitialInvestMentDataStart = (payload) => ({
    type: InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_START,
    payload,
});

export const setInitialInvestMentDataSuccess = (payload) => ({
    type: InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_SUCCESS,
    payload,
});

export const setInitialInvestMentDataFailure = (error) => ({
    type: InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_FAILURE,
    payload: error,
});

export const fetchUserInvestmentsStart = ({ projectID, email }) => ({
    type: InvestmentActionTypes.FETCH_USER_INVESTMENTS_START,
    payload: {
        projectID,
        email,
    },
});

export const fetchUserInvestmentsSuccess = (payload) => ({
    type: InvestmentActionTypes.FETCH_USER_INVESTMENTS_SUCCESS,
    payload,
});

export const fetchUserInvestmentsFailure = (payload) => ({
    type: InvestmentActionTypes.FETCH_USER_INVESTMENTS_FAILURE,
    payload,
});

export const fetchTotatInvestmentAmountStart = (projectID) => {
    return {
        type: InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_START,
        payload: {
            projectID,
        },
    };
};

export const fetchTotatInvestmentAmountSuccess = (payload) => ({
    type: InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_SUCCESS,
    payload,
});

export const fetchTotatInvestmentAmountFailure = (payload) => ({
    type: InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_FAILURE,
    payload,
});

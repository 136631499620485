import { emailRegex, nameRegex, phoneRegex, taxIdentificationNumberRegex } from "constants/validationRegex";
import * as yup from "yup";
import { isValidBIC, isValidIBAN, electronicFormatIBAN } from "ibantools";
import { toGermanCurrency, toEnglishCurrency } from "utils";

export const validationObject = {
    email: yup
        .string()
        .trim()
        .email("invalidEmail")
        .matches(emailRegex, "invalidEmail")
        .matches(/^([^\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/, "invalidEmail") // Matches and reject umlaut characters  like: ä, ö, ü
        .required("emailRequired"),
    loginPassword: yup.string().required("passwordRequired"),
    password: yup.string().min(6, "minimumPasswordLength").required("passwordRequired"),
    oldPassword: yup.string().required("oldPasswordRequired"),
    newPassword: yup.string().min(6, "minimumPasswordLength").required("newPasswordRequired"),
    confirmPassword: yup
        .string()
        .required("confirmPasswordRequired")
        .oneOf([yup.ref("newPassword"), null], "mismatchPassword"),
    firstName: yup
        .string()
        .matches(nameRegex, "firstNameInvalid")
        .required("firstNameRequired")
        .test("firstName", "specialCharactersNotAllowed", (value) => {
            return !value?.startsWith(" ");
        }),
    lastName: yup
        .string()
        .matches(nameRegex, "lastNameInvalid")
        .test("lastName", "specialCharactersNotAllowed", (value) => {
            return !value?.startsWith(" ");
        })
        .required("lastNameRequired"),
    phone: yup.string().matches(phoneRegex, "phoneInvalid").required("phoneRequired"),
    company: yup.string().required("companyRequired"),
    license: yup.object().required("licenseRequired"),
    street: yup.string().required("streetRequired"),
    streetNumber: yup.string().required("streetNumberRequired"),
    city: yup.string().required("cityRequired"),
    zipcode: yup.string().required("zipcodeRequired"),
    BIC: yup
        .string()
        .test("BIC", "bicInvalid", (value) => {
            return !!(value ? isValidBIC(value.replace(/\s/g, "")) : true);
        })
        .required("bicRequired"),
    owner: yup.string().required("ownerRequired"),
    IBAN: yup
        .string()
        .trim()
        .test("IBAN", "ibanInvalid", (value) => {
            return !!(value ? isValidIBAN(electronicFormatIBAN(value)) : true);
        })
        .required("ibanRequired"),
};

export const validationButNotRequired = {
    name: yup.string().matches(nameRegex, "nameInvalid"),
    firstName: yup.string().matches(nameRegex, "firstNameInvalid"),
    lastName: yup.string().matches(nameRegex, "lastNameInvalid"),
    phone: yup.string().matches(phoneRegex, "phoneInvalid"),
    BIC: yup.string().test("BIC", "bicInvalid", (value) => {
        return !!(value ? isValidBIC(value.replace(/\s/g, "")) : true);
    }),
    IBAN: yup.string().test("IBAN", "ibanInvalid", (value) => {
        return !!(value ? isValidIBAN(electronicFormatIBAN(value)) : true);
    }),
    checkForString: yup.string(),
    taxIdentificationNumber: yup.string().matches(taxIdentificationNumberRegex, "taxIdentificationNumberInvalid"),
};

export const checkValueInArray = (fieldName, message, array, required, message2 = "") => {
    if (required) {
        return yup
            .mixed()
            .test(fieldName, message, (value) => (value && value.value ? !!array.find((ele) => ele === value.value) : true))
            .required(message2);
    } else {
        return yup
            .mixed()
            .test(fieldName, message, (value) => (!value || !value.value ? true : !!array.find((ele) => value.value === ele)));
    }
};

export const euroWalletValidations = {
    transferAmount: (fieldName, maxValue, maxAmount, minAmount, locale) =>
        yup
            .string()
            // .matches(floatNumberRegex, "invalidTransferAmount")
            .test(fieldName, "transferAmountMaximumAmountError", (value) => {
                const { resetAmount } =
                    locale === "en" ? (value && toEnglishCurrency(value)) || "" : (value && toGermanCurrency(value)) || "";
                return !!(resetAmount ? parseFloat(resetAmount) <= maxAmount : true);
            })
            .test(fieldName, "transferAmountMaximumValueError", (value) => {
                const { resetAmount } =
                    locale === "en" ? (value && toEnglishCurrency(value)) || "" : (value && toGermanCurrency(value)) || "";
                return !!(resetAmount ? parseFloat(resetAmount) <= maxValue : true);
            })
            .test(fieldName, "transferAmountMinimumAmountError", (value) => {
                const { resetAmount } =
                    locale === "en" ? (value && toEnglishCurrency(value)) || "" : (value && toGermanCurrency(value)) || "";
                return resetAmount && parseFloat(resetAmount) >= minAmount;
            })
            .test(fieldName, "transferAmountCannotBeZero", (value) => {
                const { resetAmount } =
                    locale === "en" ? (value && toEnglishCurrency(value)) || "" : (value && toGermanCurrency(value)) || "";
                return resetAmount && parseFloat(resetAmount) !== 0.0;
            })
            .required("transferAmountRequired"),

    email: (fieldName, compareValue) =>
        yup
            .string()
            .email("invalidEmail")
            .test(fieldName, "emailRecipientAlready", (value) => {
                return value !== compareValue;
            })
            .required("emailRequired"),
};

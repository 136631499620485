import IssuersActionTypes from "./types";

export const fetchIssuersStart = (offset, limit, managersIssuers) => ({
    type: IssuersActionTypes.FETCH_ISSUERS_START,
    payload: { offset, limit, managersIssuers },
});

export const fetchIssuersSuccess = ({ issuers, totalIssuers }) => ({
    type: IssuersActionTypes.FETCH_ISSUERS_SUCCESS,
    payload: { issuers, totalIssuers },
});

export const fetchIssuersFailure = (error) => ({
    type: IssuersActionTypes.FETCH_ISSUERS_FAILURE,
    payload: error,
});

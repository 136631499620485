import React, { useEffect } from "react";
import { useFormattedMessage } from "hooks";

const messages = {
    title: "activation_pending",
    subTitle: "activation_ewallet_pending",
    content1: "moment_of_activation_ewallet",
    content2: "activation_within_few_hours",
    content3: "release_access_to_ewallet",
    buttonText: "close_btn",
};

const Pending = ({ onCloseButtonClick }) => {
    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        jQuery(".side-modal").animate({ scrollTop: 0 });
    }, []);

    return (
        <div className="mb-20">
            <h3 className="text-center">{formatMessage(messages.title)}</h3>
            <p>{formatMessage(messages.subTitle)}</p>
            <img className="mt-18 mb-15 mx-auto d-block" src={"/images/pause.png"} alt="" />
            <p className="text-center mb-10 text-16">{formatMessage(messages.content1)}</p>
            <p className="text-center mb-10 text-16">{formatMessage(messages.content2)}</p>
            <p className="text-center mb-15 text-16">{formatMessage(messages.content3)}</p>
            {/* <NavLink
                href="/dashboard"> */}
            <button onClick={() => onCloseButtonClick()} className="">
                {formatMessage(messages.buttonText)}
            </button>
            {/* </NavLink> */}
        </div>
    );
};

export default Pending;

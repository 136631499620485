import React, { useEffect } from "react";
import { useFormattedMessage } from "hooks";
import { Player } from "@lottiefiles/react-lottie-player";
import FailedIcon from "assets/images/failed-icon.json";

const messages = {
    title: "release_rejected",
    subTitle: "access_secondary_market_rejected",
    content1: "rejection_due_to_several_reason",
    content2: "contact_you",
    buttonText: "close_btn",
};

const Failed = ({ onCloseButtonClick }) => {
    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        jQuery(".side-modal").animate({ scrollTop: 0 });
    }, []);

    return (
        <div className="mb-20">
            <h3 className="text-center">{formatMessage(messages.title)}</h3>
            <p>{formatMessage(messages.subTitle)}</p>
            <div className="mt-18 mb-15 text-center d-block">
                <Player autoplay loop={false} keepLastFrame speed={0.8} src={FailedIcon} style={{ width: 123 }} />
            </div>
            <p className="text-center mb-10 text-16">{formatMessage(messages.content1)}</p>
            <p className="text-center mb-10 text-16">{formatMessage(messages.content2)}</p>
            {/* <NavLink
                href="/dashboard"> */}
            <button onClick={() => onCloseButtonClick()} className="">
                {formatMessage(messages.buttonText)}
            </button>
            {/* </NavLink> */}
        </div>
    );
};

export default Failed;

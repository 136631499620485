import InvestmentActionTypes from "./types";

export const fetchInvestmentsStart = (offset, limit) => ({
  type: InvestmentActionTypes.FETCH_INVESTMENTS_START,
  payload: { offset, limit },
});

export const fetchInvestmentsSuccess = (investmentsData, totalInvestments) => ({
  type: InvestmentActionTypes.FETCH_INVESTMENTS_SUCCESS,
  payload: {investmentsData, totalInvestments},
});

export const fetchInvestmentsFailure = (error) => ({
  type: InvestmentActionTypes.FETCH_INVESTMENTS_FAILURE,
  payload: error,
});

export function setTotalInvestments(totalInvestments) {
  return {
      type: InvestmentActionTypes.SET_TOTAL_INVESTMENTS,
      payload: totalInvestments
  };
}
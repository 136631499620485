import DashboardActionTypes from "./types";

export const fetchDashboardDetailsStart = (payload) => ({
    type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_START,
    payload,
});
export const fetchDashboardDetailsSuccess = (dashboardData) => ({
    type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS,
    payload: dashboardData,
});
export const fetchDashboardDetailsFailure = (error) => ({
    type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_FAILURE,
    payload: error,
});

export const fetchUserDashboardDetailsStart = (payload) => ({
    type: DashboardActionTypes.FETCH_USER_DASHBOARD_DETAILS_START,
    payload,
});
export const fetchUserDashboardDetailsSuccess = (dashboardData) => ({
    type: DashboardActionTypes.FETCH_USER_DASHBOARD_DETAILS_SUCCESS,
    payload: dashboardData,
});
export const fetchUserDashboardDetailsFailure = (error) => ({
    type: DashboardActionTypes.FETCH_USER_DASHBOARD_DETAILS_FAILURE,
    payload: error,
});

export const setTipsterData = (payload) => ({
    type: DashboardActionTypes.SET_TIPSTER_DATA,
    payload,
});

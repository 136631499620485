import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy } from "store/user/adequacy/selectors";
import { setAdequacyStart } from "store/user/adequacy/actions";
import { useFormattedMessage } from "hooks";
import InvestmentFunds from "./InvestmentFunds";
import Bonds from "./Bonds";
import Shares from "./Shares";
import SharingRights from "./SharingRights";
import Subordinated from "./Subordinated";

const messages = {
    yesText: "yes_text",
    noText: "no_text",
    pleaseSelect: "please_select",
    firstHeading: "question_about_investment_funds",
    firstQuestion: "knowledge_of_dealing_with_investment",
    firstQuestionOther: "how_long_experience_with_investment_funds",
    secondHeading: "question_about_bonds",
    secondQuestion: "any_knowledge_of_dealing_with_bonds",
    secondQuestionOther: "how_long_experience_with_bonds",
    thirdHeading: "question_about_shares",
    thirdQuestion: "any_knowledge_of_dealing_with_shares",
    thirdQuestionOther: "how_long_experience_with_shares",
    fourthHeading: "question_about_participation_rights",
    fourthQuestion: "any_knowledge_of_dealing_participation_rights",
    fourthQuestionOther: "how_long_experience_with_participation_shares",
    fifthHeading: "question_about_subordinated_loans",
    fifthQuestion: "any_knowledge_of_dealing_with_loans",
    fifthQuestionOther: "how_long_experience_with_loans",
};

const FinancialInvestmentsStep = ({
    className,
    setAdequacyStart,
    adequacyData,
    changeStepBack,
    hitButtonBack,
    open,
    backSlideEffect = "",
    setBackSlideEffect,
    setPreviousStep,
    StatusAdequacy,
}) => {
    const { formatMessage } = useFormattedMessage();

    const [activeStep, setActiveStep] = useState(adequacyData?.survey_second_step.step || 1);
    const [formData, setFormData] = useState(adequacyData?.survey_second_step);

    useEffect(() => {
        if (changeStepBack) {
            hitButtonBack();
            if (activeStep === 1) {
                setAdequacyStart({ step: 5, survey_second_step: { ...formData, step: 1 } });
            } else {
                setActiveStep(activeStep - 1);
            }
        }
    }, [changeStepBack]);

    useEffect(() => {
        setFormData(adequacyData.survey_second_step);
    }, [adequacyData]);

    const isInvestmentFundsVaild =
        Object.keys(formData?.investment_funds).length && formData?.investment_funds?.IsInvestFundsXPConfirmed === false
            ? true
            : formData?.investment_funds?.IsInvestFundsXPConfirmed === true
            ? !!formData?.investment_funds?.IsInvestFundsXPConfirmedDate
            : false;

    const isBondsVaild =
        Object.keys(formData?.bonds).length && formData?.bonds?.IsBondsXPConfirmed === false
            ? true
            : formData?.bonds?.IsBondsXPConfirmed === true
            ? !!formData?.bonds?.IsBondsXPConfirmedDate
            : false;

    const isSharesVaild =
        Object.keys(formData?.shares).length && formData?.shares?.IsSharesXPConfirmed === false
            ? true
            : formData?.shares?.IsSharesXPConfirmed === true
            ? !!formData?.shares?.IsSharesXPConfirmedDate
            : false;

    const isSharingRightsVaild =
        Object.keys(formData?.sharing_rights).length && formData?.sharing_rights?.IsProfitPartXPConfirmed === false
            ? true
            : formData?.sharing_rights?.IsProfitPartXPConfirmed === true
            ? !!formData?.sharing_rights?.IsProfitPartXPConfirmedDate
            : false;

    const isSubordinatedVaild =
        Object.keys(formData?.subordinated).length && formData?.subordinated?.IsSubordLoanXpConfirmed === false
            ? true
            : formData?.subordinated?.IsSubordLoanXpConfirmed === true
            ? !!formData?.subordinated?.IsSubordLoanXpConfirmedDate
            : false;

    const renderStep = (step) => {
        switch (step) {
            case 2:
                return (
                    <Bonds
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formatMessage={formatMessage}
                        messages={messages}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        setActiveStep={setActiveStep}
                        isBondsVaild={isBondsVaild}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 3:
                return (
                    <Shares
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formatMessage={formatMessage}
                        messages={messages}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        setActiveStep={setActiveStep}
                        isSharesVaild={isSharesVaild}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 4:
                return (
                    <SharingRights
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formatMessage={formatMessage}
                        messages={messages}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        setActiveStep={setActiveStep}
                        isSharingRightsVaild={isSharingRightsVaild}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 5:
                return (
                    <Subordinated
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formatMessage={formatMessage}
                        messages={messages}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        setActiveStep={setActiveStep}
                        isSubordinatedVaild={isSubordinatedVaild}
                        isInvestmentFundsVaild={isInvestmentFundsVaild}
                        isBondsVaild={isBondsVaild}
                        isSharesVaild={isSharesVaild}
                        isSharingRightsVaild={isSharingRightsVaild}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 1:
            default:
                return (
                    <InvestmentFunds
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formatMessage={formatMessage}
                        messages={messages}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        setActiveStep={setActiveStep}
                        isInvestmentFundsVaild={isInvestmentFundsVaild}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
        }
    };

    return <div className={`${className} adequacy-container sixth-step`}>{renderStep(activeStep)}</div>;
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInvestmentsStep);

import React from "react";
import CustomFormatNumber from "components/CustomFormatNumber";
import { useFormattedMessage } from "hooks";

const messages = {
    digitalSharesFor: "digital_shares_for",
    digitalShareFor: "digital_share_for",
    perShare: "per_share",
};

const InvestmentPictureOverview = ({ image, title, location, shares, amount, createdDate, price, className = "mt-8 mb-10" }) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`shadow investment-property-block flex ${className}`}>
            {image ? (
                <img className="property-image" src={image} alt="" />
            ) : (
                <div className="property-image bg-lighter-grey"> </div>
            )}
            <div className="property-details-block">
                {title && (
                    <h5 className="my-0 property-title property-item-text-overflow mt-2 mb-1" title={title}>
                        {title}
                    </h5>
                )}
                {location && (
                    <span className="text-16 fw-500 property-country property-item-text-overflow" title={location}>
                        {location}
                    </span>
                )}
                {amount && (
                    <span className="text-14 fw-600 d-block property-fund">
                        {<CustomFormatNumber value={shares} fixed={0} noSymbol />}{" "}
                        {shares == 1 ? formatMessage(messages.digitalShareFor) : formatMessage(messages.digitalSharesFor)}{" "}
                        <CustomFormatNumber value={amount} />{" "}
                    </span>
                )}
                <div className="flex justify-between align-center">
                    <span className="text-14 fw-600">{createdDate}</span>
                    <span className="text-14 fw-600 share">
                        <CustomFormatNumber value={price} /> {formatMessage(messages.perShare)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InvestmentPictureOverview;

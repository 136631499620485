import { useEffect } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";

import Auth from "utils/auth0";
import { RESPONSE_ERRORS, PRIVATE_ROUTE } from "constants/index";
import { useUtils } from "hooks";
import { fetchUserDetailsFailure, logout } from "store/user/account/actions";

const EmailNotFoundInToken = ({ children, errorMessage, isLoggedIn, logout, fetchUserDetailsFailure }) => {
    const router = useRouter();
    const { isTenantFinexity, isProdEnv } = useUtils();

    useEffect(() => {
        const isPathPrivate =
            PRIVATE_ROUTE.findIndex((route) => router.pathname.search(route) > -1) > -1 ||
            window.location.href.includes(`${window.location.origin}/token#`);

        if (errorMessage === RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN && isPathPrivate && isLoggedIn) {
            if (isProdEnv && !isTenantFinexity) {
                Auth.customLogout({ redirectToLogin: false }).then(() => logout());
                router.push("/provide-email");
            } else {
                Auth.logout({ returnTo: `${window.location.origin}/provide-email` });
            }
        }

        return () => {
            fetchUserDetailsFailure(undefined);
        };
    }, [errorMessage]);

    return children;
};

const mapStateToProps = (state) => ({
    errorMessage: state.user.error,
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    fetchUserDetailsFailure: (payload) => dispatch(fetchUserDetailsFailure(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotFoundInToken);

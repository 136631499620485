import React, { useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAdequacy } from "store/user/adequacy/selectors";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import { userDashboardDetailsStart, userDashboardDetailsSuccess } from "store/user/account/actions";

import SuccessAnimation from "components/SuccessAnimation";

import { useFormattedMessage, useSideModalScrollToTop } from "hooks";

const messages = {
    heading: "thank_you_heading",
    subheading: "your_information_saved",
    content: "clicking_exit",
    buttonText: "exit",
};

const ThankYouStep = ({
    className,
    onCloseButtonClick,
    onBackButtonClick,
    setAdequacySuccessFlag,
    hitButtonBack,
    userDashboardDetailsStart,
    setAdequacyAtStep1,
    changeStepBack,
    backSlideEffect,
    userDashboardData,
    userDashboardDetailsSuccess,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        if (changeStepBack) {
            hitButtonBack();
            setAdequacyAtStep1();
            userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "hide" });
            onCloseButtonClick && onCloseButtonClick();
            setAdequacySuccessFlag && setAdequacySuccessFlag();
            setTimeout(() => {
                userDashboardDetailsStart();
            }, 5000);
        }
    }, [changeStepBack]);
    return (
        <div className={`adequacy-container adequacy-thank-you text-center ${className} ${backSlideEffect}`}>
            <h4>{formatMessage(messages.heading)}</h4>
            <p>{formatMessage(messages.subheading)}</p>
            <SuccessAnimation />
            <p>{formatMessage(messages.content)}</p>
            <button
                className="mt-7 mw-100"
                onClick={() => {
                    onBackButtonClick && onBackButtonClick();
                    setAdequacySuccessFlag && setAdequacySuccessFlag();
                    setTimeout(() => {
                        userDashboardDetailsStart();
                    }, 5000);
                }}
            >
                {formatMessage(messages.buttonText)}
            </button>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
    userDashboardData: selectUserDashboardDetails,
});

const mapDispatchToProps = (dispatch) => ({
    userDashboardDetailsStart: () => dispatch(userDashboardDetailsStart()),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouStep);

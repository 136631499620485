export const accountHeading = "account_heading";
export const profile = "meta_title_account_profile_page";
export const referral = "meta_title_account_referral_page";
export const notification = "meta_title_news_letter_tags_page";
export const logoutTitle = "nav_logout";
export const changePasswordTitle = "change_password";
export const inviteTitle = "invite_now";
export const informationTitle = "information";
export const changePinTitle = "change_pin";
export const contactTitle = "contact";
export const tax = "dashboard_nav_tax";

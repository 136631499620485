import React from "react";
import MoneyProfit from "assets/images/svg/moneyProfit.svg";
import CustomFormatNumber from "components/CustomFormatNumber";

const TopGreDiv = ({ text = "Verfügbares Guthaben", amount, icon = false, svgIcon }) => (
    <div className="top-grey-div bg-dark hf-50 flex-column justify-center align-center">
        {!icon ? <span className="d-inline-block text-16 fw-500 mb-2">{text}</span> : null}
        {icon ? (
            svgIcon ? (
                svgIcon
            ) : (
                <MoneyProfit />
            )
        ) : (
            <span className="d-inline-block text-34 fw-600">
                <CustomFormatNumber value={amount} />
            </span>
        )}
    </div>
);

export default TopGreDiv;

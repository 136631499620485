import React, { useEffect, useState } from "react";
import NextButton from "components/NextButton";
import TopGreyDiv from "../components/topGreyDiv";
import AccountDetailsBlock from "../components/accountDetailsBlock";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { sendFormError } from "i18n/messages/errors";

import { depositAmount as amountMessages } from "i18n/messages/formElements";

const messages = {
    payInTitle: "ewallet_payin",
    depositAmountToEwallet: "deposit_amount_to_ewallet",
    transferAmountToAccount: "transfer_amount_to_account",
    yourTransferDetails: "wallet_transfer_details",
    note: "paying_into_specified_account",
    toOverview: "to_overview",
};

const PayInOverview = ({ setShowDate, setCloseTitle, setStep, stepData, backSlideEffect = "", setBackSlideEffect }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.payInTitle));
    }, []);

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(
                formatMessage(
                    status.requestError === "mangopay_beneficiary_account_not_available"
                        ? messages.mangopayBeneficiaryAccountNotAvailable
                        : status.requestError === "mangopay_invalid_min_payin_amount"
                        ? amountMessages["minimumDepositAmountFifty"]
                        : status.requestError === "mangopay_invalid_max_payin_amount"
                        ? amountMessages["maximumDepositAmount"]
                        : status.requestError === "mangopay_payin_several_pendings"
                        ? amountMessages["maximumDepositAmount"]
                        : sendFormError
                )
            );
        }
    }, [status]);

    const onButtonClick = () => {
        setStep("walletOverview");
        setBackSlideEffect("step-slide-right");
    };

    return (
        <div className={`pay-in-confirmation ${backSlideEffect}`}>
            <TopGreyDiv text={formatMessage(messages.depositAmountToEwallet)} amount={stepData && stepData.depositAmount} />
            <div className="px-5 py-8 mw-450 mx-auto">
                <span className="text-22 fw-600 d-inline-block mb-10">{formatMessage(messages.transferAmountToAccount)}</span>
                <span className="text-16 mb-8 secondary-light-color d-inline-block">
                    {formatMessage(messages.yourTransferDetails)}
                </span>
                <AccountDetailsBlock data={stepData} amount={stepData && stepData.depositAmount} />
                <span className="text-14 d-block mt-8 mb-15">{formatMessage(messages.note)}</span>
                {requestError && <span className="error pl-0">{requestError}</span>}
                <div className="bottom-bar-container">
                    <NextButton onClick={onButtonClick} title={formatMessage(messages.toOverview)} />
                </div>
            </div>
        </div>
    );
};

export default PayInOverview;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MangoPayWalletBalance from "components/EuroWalletSideModal/MangoPayWalletBalance";
import EuroWalletSideModal from "components/EuroWalletSideModal";
import SecondaryMarketplaceOnboarding from "components/SecondaryMarketplaceOnboarding";
import { selectStatusMangopay, selectMangopayActive, selectMangopayBlocked, selectEmail } from "store/user/account/selectors";
import { selectPlatformSourceFromApp } from "store/app/selectors";
import { ACCOUNT_HASH_VARIABLES } from "constants/index";
import { MANGOPAY_STATUS } from "constants/status";
import { useFormattedMessage } from "hooks";
import ArrowNext from "assets/images/svg/next.svg";

const messages = {
    CTAWalletHeading: "CTA_wallet_heading",
    CTAWalletDescription: "CTA_wallet_description",
};

const WrappedMangopayWallet = ({
    statusMangopay,
    isMangopayActive,
    isMangoPayBlocked,
    onCloseButtonClick,
    userEmail,
    addHash,
    removeHash,
    isApp,
    isMainAccountModalOpen,
    setMainAccountModalOpen,
    isShowTile = true,
}) => {
    const { formatMessage } = useFormattedMessage();

    const [openEuroModal, setOpenEuroModal] = useState(false);
    const [isSmModalOpen, setSmModalOpen] = useState(false);
    const walletShow =
        statusMangopay &&
        statusMangopay !== MANGOPAY_STATUS.HIDE &&
        statusMangopay !== "" &&
        statusMangopay !== MANGOPAY_STATUS.INACTIVE;

    useEffect(() => {
        const onHashChanged = () => {
            const hash = window.location.hash.substring(1);
            if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_EWALLET) {
                //Opening account modal primarily to avoid unexpected behavior on different browsers
                if (!isMainAccountModalOpen && !isApp) setMainAccountModalOpen(true);
                setOpenEuroModal(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_SM_ONBOARDING && !walletShow) {
                //Opening account modal primarily to avoid unexpected behavior on different browsers
                if (!isMainAccountModalOpen && !isApp) setMainAccountModalOpen(true);
                setSmModalOpen(true);
            }
        };

        window.addEventListener("hashchange", onHashChanged);

        return () => {
            window.removeEventListener("hashchange", onHashChanged);
        };
    }, []);

    useEffect(() => {
        const hash = window?.location?.hash?.substring(1);
        if (userEmail) {
            if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_EWALLET) {
                setOpenEuroModal(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_SM_ONBOARDING && !walletShow) {
                setSmModalOpen(true);
            }
        }
    }, [userEmail]);

    return (
        <>
            <EuroWalletSideModal
                initiateClose={false}
                open={openEuroModal}
                onBackButtonClick={() => {
                    setOpenEuroModal(false);
                    addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN);
                    isApp && removeHash?.();
                }}
                onCloseButtonClick={() => {
                    setOpenEuroModal(false);
                    onCloseButtonClick && onCloseButtonClick();
                    isApp && removeHash?.();
                }}
            />
            <SecondaryMarketplaceOnboarding
                openEuroWallet={() => {
                    setSmModalOpen(false);
                    setOpenEuroModal(true);
                    addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_EWALLET);
                }}
                open={isSmModalOpen}
                onBackButtonClick={() => {
                    setSmModalOpen(false);
                    addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN);
                    isApp && removeHash?.();
                }}
                onCloseButtonClick={() => {
                    onCloseButtonClick && onCloseButtonClick();
                    setSmModalOpen(false);
                    isApp && removeHash?.();
                }}
            />
            {isShowTile && (
                <>
                    {!walletShow && (
                        <div
                            className="identity-block pointer border-radius bg-primary-light"
                            onClick={() => addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_SM_ONBOARDING)}
                        >
                            <div className="d-flex justify-between slign-center">
                                <span className="body-medium">{formatMessage(messages.CTAWalletHeading)}</span>
                                <span className="next-arrow">
                                    <ArrowNext />
                                </span>
                            </div>
                            <div className="equal description">
                                <div className="my-0 text-16 fw-600">{formatMessage(messages.CTAWalletDescription)}</div>
                            </div>
                        </div>
                    )}
                    <div
                        className="flex align-center py-4 w-100 px-5 cursor-pointer menu-tab"
                        onClick={() =>
                            isMangopayActive || isMangoPayBlocked
                                ? addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_EWALLET)
                                : addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_SM_ONBOARDING)
                        }
                    >
                        <div className="fw-500 text-16 flex align-center">
                            <MangoPayWalletBalance />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    statusMangopay: selectStatusMangopay,
    isMangopayActive: selectMangopayActive,
    isMangoPayBlocked: selectMangopayBlocked,
    userEmail: selectEmail,
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps, null)(WrappedMangopayWallet);

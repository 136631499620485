import React from "react";

const isChecked = (values, value) => {
    switch (typeof values) {
        case "string":
        case "boolean":
        case "number":
            return values === value;
        case "object":
        default:
            return values.indexOf(value) > -1;
    }
};

const Checkbox = ({
    className = "",
    inputId = "",
    inputClassName = "",
    labelClassName = "",
    name = "",
    value = "",
    values = true,
    label,
    isError = false,
    isDisabled = false,
    indeterminate = false,
    onChange,
    onClick,
    size = "medium",
    ...rest
}) => {
    return (
        <div
            className={`input-type-checkbox ${className} ${isError ? "checkbox-error" : ""} ${
                isDisabled ? "checkbox-disabled" : ""
            }`}
        >
            <input
                className={`${inputClassName} ${size} ${indeterminate ? "indeterminate" : ""}`}
                id={inputId}
                name={name}
                value={value}
                checked={isChecked(values, value)}
                onChange={(e) => {
                    onChange && onChange(e);
                    onClick && onClick(e);
                }}
                type="checkbox"
                {...rest}
            />
            {label && (
                <label htmlFor={inputId} className={`ml-3 ${labelClassName}`}>
                    {label}
                </label>
            )}
        </div>
    );
};

export default Checkbox;

import React, { useState } from "react";

const PasswordInput = ({
    id = "",
    className = "",
    readOnly,
    name = "",
    placeholder = "",
    defaultValue,
    value = "",
    inputSymbol,
    maxLength,
    errorMessage = "",
    onChange,
    inputClassName = "",
    errorClassName = "error-element",
    success = false,
    currency = "",
    errorIcon = true,
    error,
    onBlur,
    label,
    labelClassName="",
    ...rest
}) => {
    const [passwordType, setPasswordType] = useState("password");
    const [passwordClassName, setPasswordClassName] = useState(label? "fas fa-eye eye-icon top-12" : "fas fa-eye eye-icon");
    const [isFocused, setIsFocused] = useState(false);

    const eyeIconHandler = () => {
        if (passwordType === "type") {
            setPasswordType("password");
            setPasswordClassName( label? "fas fa-eye eye-icon top-12" : "fas fa-eye eye-icon");
        } else {
            setPasswordType("type");
            setPasswordClassName( label? "fas fa-eye-slash eye-icon top-12": "fas fa-eye-slash eye-icon");
        }
    };

    return (
        <div
            className={`form-element fancy-input input-holder password-input relative ${className} ${error ? errorClassName : ""
                } ${inputSymbol && "symbol-container"}`}
            data-role="fieldcontain"
        >
            {label && <label className={`mb-2 d-inline-block ${labelClassName}`}>{label}</label>}
            <input
                {...rest}
                className={`form-input ${inputClassName}`}
                id={id}
                name={name}
                onFocus={() => setIsFocused(true)}
                onBlur={(event) => {
                    onBlur(event);
                    setIsFocused(false);
                }}
                type={passwordType}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                readOnly={readOnly}
                maxLength={maxLength}
            />
            <span className={`form-label ${isFocused || value ? "lable-top" : ""} ${label ? "top-12":""} ${label && (isFocused || value) ? "top-10":""}`}>{placeholder}</span>
            {inputSymbol && <span className="input-symbol">{inputSymbol}</span>}
            {currency && <span className="currency">{currency}</span>}
            {success && !errorMessage && <i className="fas fa-check" />}
            <i className={passwordClassName} onClick={eyeIconHandler} />
            {error && (
                <>
                    {errorMessage && errorIcon && <i className={`fas fa-exclamation-triangle ${label ? "top-12": ""}`}  />}
                    {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                </>
            )}
        </div>
    );
};

export default PasswordInput;

import { put, takeLatest, call, all } from "redux-saga/effects";

import {
    fetchUserInvestmentsByProjectID,
    createInitialInvestmentData,
    createInvestmentData,
    fetchTotalInvestmentAmount,
} from "api";

import InvestmentActionTypes from "./types";

import {
    fetchUserInvestmentsStart,
    fetchUserInvestmentsSuccess,
    fetchUserInvestmentsFailure,
    setInitialInvestMentDataSuccess,
    setInitialInvestMentDataFailure,
    createInvestMentDataSuccess,
    createInvestMentDataFailure,
    fetchTotatInvestmentAmountSuccess,
    fetchTotatInvestmentAmountFailure,
} from "./actions";

function* fetchUserInvestmentsAsync({ payload: { projectID, email } }) {
    try {
        const response = yield call(fetchUserInvestmentsByProjectID, projectID, email);
        const { investments } = response && response.data && response.data.data && response.data.data.investments;
        yield put(fetchUserInvestmentsSuccess(investments));
    } catch (err) {
        yield put(fetchUserInvestmentsFailure(err));
    }
}

function* setInitialInvestMentDataAsync({ payload }) {
    try {
        const response = yield call(createInitialInvestmentData, payload);
        yield put(
            fetchUserInvestmentsStart({
                projectID: payload.investment.ProjectID,
                email: payload.investment.UserEmail,
            })
        );

        if (response?.data?.errors) {
            const error = response?.data?.errors?.[0]?.message;
            yield put(setInitialInvestMentDataFailure(error));
        } else if (response) {
            yield put(
                setInitialInvestMentDataSuccess({
                    investmentID: response.data.data.initInvestment.investment.uid,
                    Documents: response.data.data.initInvestment.investment.Document,
                    LinkToken: response.data.data.initInvestment.investment.Token,
                })
            );
        }
    } catch (err) {
        yield put(setInitialInvestMentDataFailure(err));
    }
}

function* createInvestmentDataAsync({ payload }) {
    try {
        const response = yield call(createInvestmentData, payload);
        if (response?.data?.errors) {
            const error = response?.data?.errors?.[0]?.message;
            yield put(createInvestMentDataFailure(error));
            return;
        }
        const { investment } = response.data.data.createInvestment;
        if (investment && investment.uid) {
            yield put(fetchUserInvestmentsStart({ projectID: payload.projectID, email: payload.email }));
            yield put(createInvestMentDataSuccess(investment));
        }
        // const { ID } = investment;
        // const { bankAccount } = investment.project.financials;
        // const investmentData = {
        //     ID,
        //     ...bankAccount
        // }
    } catch (err) {
        yield put(createInvestMentDataFailure(err));
    }
}

function* fetchInvestmentAmountAsync({ payload }) {
    try {
        const response = yield call(fetchTotalInvestmentAmount, payload);
        if (response?.data?.data?.totalInvestmentAmount) {
            const { totalInvestmentAmount = 0 } = response?.data?.data?.totalInvestmentAmount;
            yield put(fetchTotatInvestmentAmountSuccess(totalInvestmentAmount));
        } else {
            const error = response?.data?.errors?.[0]?.message;
            yield put(fetchTotatInvestmentAmountFailure(error));
        }
    } catch (err) {
        yield put(createInvestMentDataFailure(err));
    }
}

function* fetchInvestmentAmountStart() {
    yield takeLatest(InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_START, fetchInvestmentAmountAsync);
}

function* fetchUserInvestmentStart() {
    yield takeLatest(InvestmentActionTypes.FETCH_USER_INVESTMENTS_START, fetchUserInvestmentsAsync);
}

function* setInitialInvestMentDataStart() {
    yield takeLatest(InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_START, setInitialInvestMentDataAsync);
}

function* createInvestmentDataStart() {
    yield takeLatest(InvestmentActionTypes.CREATE_INVESTMENT_DATA_START, createInvestmentDataAsync);
}

export function* investmentSagas() {
    yield all([
        call(fetchUserInvestmentStart),
        call(fetchInvestmentAmountStart),
        call(setInitialInvestMentDataStart),
        call(createInvestmentDataStart),
    ]);
}

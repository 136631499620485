export const phoneRegex = /^(\(0\))?\d{6,19}$/;
export const nameRegex = /^[a-z A-Z 0-9 äöüÄÖÜßé.]+(?!.*[.]{2})+(?:-?[a-z A-Z 0-9 äöüÄÖÜßé.]+)+([a-z A-Z 0-9 äöüÄÖÜßé.]+)(?=)+$/;
export const textNumberRegex = /^[a-z A-Z 0-9 äöüÄÖÜßé.,-]*$/;
export const numberRegex = /^[0-9]*$/;
export const floatNumberRegex = /^\d+(\.\d+)?$/;
export const singleNumberDigitRegex = /\d/;
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;
export const numberRegexAcceptDotAndComma = /^[0-9][0-9 \.,]*$/;

export const taxIdentificationNumberRegex = /^[0-9]{11}$/;

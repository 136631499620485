import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import cn from "classnames";
import NextButton from "components/NextButton";
import { selectMangopayActive, selectMangopayBlocked } from "store/user/account/selectors";

const messages = {
    title: "activation_of_secondary_eWallet",
    content1: "secondary_market_content1",
    content2: "secondary_market_content2",
    content3: "carry_out_your_identification",
    kycTitleText: "kyc_your_identification",
    kycButtonText: "kyc_start_identification",
    mobileTitleText: "confirm_your_mobile_number",
    mobileButtonText: "confirm_now",
    adequacyTitleText: "experience_knowledge",
    adequacyButtonText: "adequacy_questionnaire",
};

const Start = ({
    setStep,
    isKycPending,
    isKycCompleted,
    isMobileValidated,
    isAdequacyCompleted,
    isMangopayActive,
    isMangopayBlocked,
    onCloseButtonClick,
    openEuroWallet,
    open,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const statuses = {
        initiated: "fas fa-hourglass-half light-grey-color status-initiated",
        pending: "fas fa-hourglass-half light-grey-color status-pending",
        success: "fas fa-check-circle primary-color status-success",
        error: "fas fa-times-circle danger-red-color status-error",
        default: "far fa-circle light-grey-color status-default",
    };

    const processStatus = (status) => {
        return statuses[status] || statuses["default"];
    };

    return (
        <>
            <div className={backSlideEffect}>
                <h3>{formatMessage(messages.title)}</h3>
                <p>{formatMessage(messages.content1)}</p>
                <p>{formatMessage(messages.content2)}</p>
                <p>{formatMessage(messages.content3)}</p>
                <div className="secondary-market-status">
                    <div className="mt-8">
                        <div className="d-flex align-center">
                            <i
                                style={{ width: "30px" }}
                                className={`text-30 mr-5 text-center ${processStatus(
                                    isKycCompleted ? "success" : isKycPending ? "pending" : "default"
                                )}`}
                            />
                            <h4 className="my-0">{formatMessage(messages.kycTitleText)}</h4>
                        </div>
                        {isKycCompleted ? null : (
                            <div className="d-flex btn-container">
                                <i style={{ width: "30px" }} className="text-30 mr-5 text-center" />
                                <button className="mb-0 medium mw-100" onClick={() => setStep("userData")}>
                                    {formatMessage(messages.kycButtonText)}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="mt-8">
                        <div className="d-flex align-center">
                            <i
                                style={{ width: "30px" }}
                                className={`text-30 mr-5 text-center ${processStatus(isMobileValidated && "success")}`}
                            />
                            <h4 className="my-0">{formatMessage(messages.mobileTitleText)}</h4>
                        </div>
                        {isMobileValidated ? null : (
                            <div className="d-flex btn-container">
                                <i style={{ width: "30px" }} className="text-30 mr-5 text-center" />
                                <button className="mb-0 medium mw-100" onClick={() => setStep("mobileData")}>
                                    {formatMessage(messages.mobileButtonText)}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="mt-8">
                        <div className="d-flex align-center">
                            <i
                                style={{ width: "30px" }}
                                className={`text-30 mr-5 text-center ${processStatus(isAdequacyCompleted && "success")}`}
                            />
                            <h4 className="my-0">{formatMessage(messages.adequacyTitleText)}</h4>
                        </div>
                        {isAdequacyCompleted ? null : (
                            <div className="d-flex btn-container">
                                <i style={{ width: "30px" }} className="text-30 mr-5 text-center" />
                                <button className="mb-0 medium mw-100" onClick={() => setStep("adequacyData")}>
                                    {formatMessage(messages.adequacyButtonText)}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    {isKycCompleted && isMobileValidated && isAdequacyCompleted ? (
                        <NextButton
                            onClick={() => {
                                if (isMangopayActive || isMangopayBlocked) {
                                    onCloseButtonClick?.();
                                    openEuroWallet?.();
                                } else {
                                    setStep("termsAndCondition");
                                    setBackSlideEffect("step-slide-left");
                                }
                            }}
                        />
                    ) : (
                        <NextButton
                            disable={!isKycCompleted || !isMobileValidated || !isAdequacyCompleted}
                            className={cn({
                                "disable-grey": !isKycCompleted || !isMobileValidated || !isAdequacyCompleted,
                            })}
                        />
                    )}
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isMangopayActive: selectMangopayActive,
    isMangoPayBlocked: selectMangopayBlocked,
});

export default connect(mapStateToProps)(Start);

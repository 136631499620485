import React, { useEffect, useState } from "react";
import Link from "next/link";
import Router, { useRouter } from "next/router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Dashboard from "assets/images/svg/ico-dashboard.svg";
import House from "assets/images/svg/ico-house.svg";
import TagSolid from "assets/images/svg/tags-solid.svg";
import User from "assets/images/svg/ico-user.svg";

import { selectIsAppClubDeal, selectPlatformSourceFromApp, selectTenant } from "store/app/selectors";
import { TENANTS_LIST } from "constants/index";
import { removeOverlay } from "utils";
import { tab_bar_blocked_path as TAB_BLOCKED_PATH } from "constants/routes_config";

export function Tab(tab) {
    return (
        <Link href={tab.link} replace passHref className="tab-item boder-radius align-center justify-center">
            <div className="tab-icon">{tab?.icon?.()}</div>
        </Link>
    );
}
let windowHeight = 0;
function AppTabBar({ children, isApp, isShowClubdeal, currentTenant }) {
    const showClubdeal = currentTenant !== TENANTS_LIST.GOLDENCIRCLE && isShowClubdeal;
    const [tabs, setTabs] = useState([]);
    const [hideTab, setHideTab] = useState(false);
    const [indicatorClassName, setIndicatorClassName] = useState("three");
    const router = useRouter();
    const tabList = [
        {
            id: "dashboard",
            link: "/dashboard",
            icon: () => <Dashboard />,
        },
        {
            id: "marketplace",
            link: "/marketplace",
            icon: () => <House />,
        },
        {
            id: "club-deals",
            link: "/club-deals",
            icon: () => <TagSolid />,
            isHidden: !showClubdeal,
        },
        {
            id: "account",
            link: "/account",
            icon: () => <User />,
        },
    ];

    function onRouteChangeHandler(url) {
        removeOverlay();
        const index = tabs.findIndex((item) => url?.includes?.(item.id));
        if (TAB_BLOCKED_PATH.find((item) => url?.includes?.(item))) {
            hideTab && setHideTab(true);
        } else {
            !hideTab && setHideTab(false);
        }
        if (!!(index || index == 0)) {
            // eslint-disable-next-line no-use-before-define
            switchTabTo(index);
            $(".main-app-container").scrollTop(0);
        }
    }

    const onClickTab = (tab) => {
        Router.push(tab?.link || "/dashboard");
    };

    function switchTabTo(index) {
        // Switch to particular index
        const margin = (100 / tabs.length) * index;
        $(".tab-indicator").css({
            "margin-left": margin + "%",
            transition: "all .2s",
        });
    }

    useEffect(() => {
        Router.events.on("routeChangeStart", (url) => onRouteChangeHandler(url));
        return () => {
            Router.events.off("routeChangeStart", (url) => onRouteChangeHandler(url));
        };
    }, [tabs]);

    useEffect(() => {
        const currentPath = Router.asPath;
        const index = tabs.findIndex((e) => currentPath?.includes(e.id)) || 0;
        switchTabTo(index);

        if (!!TAB_BLOCKED_PATH.find((item) => router?.asPath?.includes?.(item))) {
            !hideTab && setHideTab(true);
        } else {
            hideTab && setHideTab(false);
        }
    }, [tabs]);

    useEffect(() => {
        windowHeight = window.innerHeight;

        isApp &&
            window.addEventListener("resize", function () {
                const latestWindowHeight = window.innerHeight;
                var fixedDiv = document.querySelector(".app-tab");
                if (fixedDiv?.style) {
                    fixedDiv.style.bottom = latestWindowHeight < windowHeight ? `${latestWindowHeight - windowHeight}px` : "0px";
                }
            });
    }, []);

    useEffect(() => {
        const filteredTabs = tabList.filter((e) => !e.isHidden);
        setTabs([...filteredTabs]); // Tabs filtering when clubdeal status available
        setIndicatorClassName(showClubdeal ? "four" : "three");
    }, [showClubdeal]);

    return (
        <>
            {children}
            {isApp && !TAB_BLOCKED_PATH.find((item) => router?.pathname?.includes?.(item)) ? (
                <>
                    <div className="app-tab">
                        <div className={`tab-indicator ${indicatorClassName}`} />
                        <div className="tab-container pt-2">
                            {tabs.map((tab, index) => (
                                <Tab key={index} {...tab} index={index} onClickTab={onClickTab} />
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
    isShowClubdeal: selectIsAppClubDeal,
    currentTenant: selectTenant,
});

export default connect(mapStateToProps)(AppTabBar);

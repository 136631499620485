import PaymentActionTypes from "./types";

export const fetchPaymentsStart = (reqData, oldPayments, result) => ({
    type: PaymentActionTypes.FETCH_PAYMENTS_START,
    payload: {
        reqData,
        oldPayments,
        result,
    },
});

export const setTotalPayments = (totalPayments) => ({
    type: PaymentActionTypes.SET_TOTAL_PAYMENTS,
    payload: totalPayments,
});

export const fetchPaymentsSuccess = (paymentsData) => ({
    type: PaymentActionTypes.FETCH_PAYMENTS_SUCCESS,
    payload: paymentsData,
});

export const fetchPaymentsFailure = (error) => ({
    type: PaymentActionTypes.FETCH_PAYMENTS_FAILURE,
    payload: error,
});

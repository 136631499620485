import React from "react";

const RoundIconContainer = ({ borderColor = "", iconBackgroundColor = "#f1f5f5", iconComponent }) => {
    return (
        <div className="round-icon-container">
            <div
                className="round-holder"
                style={
                    borderColor
                        ? { backgroundColor: iconBackgroundColor, border: `1px solid ${borderColor}` }
                        : { backgroundColor: iconBackgroundColor }
                }
            >
                {iconComponent()}
            </div>
        </div>
    );
};

export default RoundIconContainer;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import TopGreyDiv from "../components/topGreyDiv";
import { getInitialsFromEmail } from "utils";
import { mangopayTransfer } from "api";
import { sendFormError } from "i18n/messages/errors";
import { transferAmount as amountMessages } from "i18n/messages/formElements";
import { selectPayInWalletBalance } from "store/user/account/selectors";
import { fetchPayInWalletBalanceSuccess } from "store/user/account/actions";
import OTPInput from "components/OTPInput";

const messages = {
    transferTitle: "ewallet_send_credit",
    amountToSend: "amount_to_send",
    transferConfirmHeading: "please_confirm_transaction",
    accountAsSender: "account_as_sender",
    transferRecipient: "credit_recipient",
    releaseTransaction: "release_transaction",
    releaseTransactionContent: "otp_code",
    resendTAN: "resend_otp_code",
    invalidTAN: "invalid_code",
    mobileTANcodeExpired: "mobile_tan_code_expired",
    buttonText: "send_amount",
    mangopayBeneficiaryAccountNotAvailable: "mangopay_beneficiary_account_not_available",
    blockedTransferErrorMessage: "blocked_transfer_error",
};

function TransferEMoneyConfirmation({
    data,
    stepData,
    setShowDate,
    setCloseTitle,
    setStep,
    setStepAndData,
    payInAmount,
    fetchPayInWalletBalanceSuccess,
    backSlideEffect = "",
    setBackSlideEffect,
}) {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [OTP, setOTP] = useState("");

    const creditedUserEmail = (stepData && stepData.CreditedUserEmail) || "";
    const transferAmount = (stepData && stepData.TransferAmount) || "";

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.transferTitle));
    }, []);

    useEffect(() => {
        if (errorCode === "invalid_input") {
            setTimeout(() => {
                setOTP("");
                setErrorCode("");
            }, 500);
        }
    }, [errorCode]);

    useEffect(() => {
        OTP.length === 0 && setRequestError("");
    }, [OTP]);

    const onTransfer = () => {
        setIsLoading(true);
        mangopayTransfer({
            CreditedUserEmail: stepData.CreditedUserEmail,
            TransferAmount: stepData.TransferAmount,
            TAN: OTP,
        })
            .then((res) => {
                setIsLoading(false);
                if (res.data && res.data.errors && res.data.errors[0]) {
                    setErrorCode(res.data.errors[0].message);
                    setRequestError(
                        formatMessage(
                            res.data.errors[0].message === "invalid_input"
                                ? messages.invalidTAN
                                : res.data.errors[0].message === "TAN_expired"
                                ? messages.mobileTANcodeExpired
                                : res.data.errors[0].message === "mangopay_out_flows_blocked"
                                ? messages.blockedTransferErrorMessage
                                : res.data.errors[0].message === "mangopay_beneficiary_account_not_available"
                                ? messages.mangopayBeneficiaryAccountNotAvailable
                                : res.data.errors[0].message === "mangopay_invalid_min_transfer_amount"
                                ? amountMessages["transferAmountMinimumAmountError"]
                                : res.data.errors[0].message === "mangopay_invalid_max_transfer_amount"
                                ? amountMessages["transferAmountMaximumAmountError"]
                                : sendFormError
                        )
                    );
                }

                if (!res || res.errors || res.data.errors || res.data.data.errors) return;

                // Update payIn wallet balance
                const Amount = parseFloat(payInAmount) - parseFloat(stepData.TransferAmount);

                fetchPayInWalletBalanceSuccess({ Amount });

                setStep("transferEMoneyOverview");
                setStepAndData("transferEMoneyOverview", {
                    transferAmount: stepData.TransferAmount,
                    creditedUserEmail: stepData.CreditedUserEmail,
                    transcationID: res.data.data.mangopayTransfer.payment.uid,
                });
                setBackSlideEffect("step-slide-left");
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={`transfer-e-money-overview ${backSlideEffect}`}>
            <TopGreyDiv text={formatMessage(messages.amountToSend)} amount={transferAmount} />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3 className="mb-6">{formatMessage(messages.transferConfirmHeading)}</h3>
                <div className="flex align-center justify-between mb-4" />

                <span className="text-14 fw-500 d-inline-block mb-4">{formatMessage(messages.accountAsSender)}</span>
                <div className="card-container flex">
                    <div className="round-icon-container">
                        <div className="round-holder bg-dark">{getInitialsFromEmail(data.currentUser.email)}</div>
                    </div>
                    <div className="flex align-center hf-20">
                        <span className="text-16 fw-500 d-inline-block">{data.currentUser.email}</span>
                    </div>
                </div>

                <span className="text-14 fw-500 d-inline-block mb-4  mt-10">{formatMessage(messages.transferRecipient)}</span>
                <div className="card-container flex mb-12">
                    <div className="round-icon-container">
                        <div className="round-holder bg-dark">{getInitialsFromEmail(creditedUserEmail)}</div>
                    </div>
                    <div className="flex align-center hf-20">
                        <span className="text-16 fw-500 d-inline-block">{creditedUserEmail}</span>
                    </div>
                </div>

                <span className="divider mb-10" />

                <h4>{formatMessage(messages.releaseTransaction)}</h4>
                <p className="mb-5">{formatMessage(messages.releaseTransactionContent)}</p>
                <OTPInput
                    errorCode={errorCode}
                    value={OTP}
                    onChange={(value) => setOTP(value)}
                    isSubmitting={isLoading}
                    errorMessage={requestError}
                    shouldAutoFocus
                />
                <div className="flex justify-end">
                    <span className="light-grey-color text-12 mb-5 cursor-pointer">{formatMessage(messages.resendTAN)}</span>
                </div>

                {!isLoading && requestError && <span className="error pl-0">{requestError}</span>}
                <div className="bottom-bar-container">
                    <PrevButton
                        disable={isLoading}
                        onClick={() => {
                            setStep("transferEMoney");
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        disable={isLoading || OTP?.length !== 6}
                        title={formatMessage(messages.buttonText)}
                        className="mw-fit"
                        onClick={onTransfer}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    payInAmount: selectPayInWalletBalance,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPayInWalletBalanceSuccess: (value) => dispatch(fetchPayInWalletBalanceSuccess(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferEMoneyConfirmation);

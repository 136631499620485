import React from "react";

const Input = ({
    id = "",
    className = "",
    readOnly,
    type,
    name = "",
    label,
    sublabel,
    placeholder,
    defaultValue,
    value = "",
    inputSymbol,
    inputSymbolClass,
    labelClassName,
    maxLength = "",
    errorMessage = "",
    onChange,
    inputClassName = "",
    errorClassName = "error-element",
    success = false,
    currency = "",
    errorIcon = true,
    error,
    onBlur,
    extraIconClassname = "",
    iconOnclickHandler,
    renderLabel,
    isDateIcon = false,
    min,
    max,
    ...rest
}) => (
    <div className={`form-element ${className} ${error ? errorClassName : ""}`} data-role="fieldcontain">
        {label && <label className={`mb-2 d-inline-block ${labelClassName}`}>{label}</label>}
        {sublabel && Object.keys(sublabel).length > 0 && (
            <div className="d-flex justify-between mb-2">
                {sublabel.left && <span className="secondary-light-color text-14">{sublabel.left}</span>}
                {sublabel.right && <span className="text-14 fw-500">{sublabel.right}</span>}
            </div>
        )}
        {typeof renderLabel === "function" && renderLabel()}
        <div className="position-relative">
            <input
                {...rest}
                onBlur={onBlur}
                id={id}
                name={name}
                placeholder={placeholder}
                className={`form-input ${inputClassName}`}
                type={type}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                readOnly={readOnly}
                maxLength={maxLength}
                min={min}
                max={max}
            />
            {isDateIcon && <i className="fas fa-calendar-alt"></i>}
            {inputSymbol && (
                <span className={`input-symbol ${inputSymbolClass || ""} ${label && "with-label"}`}>{inputSymbol}</span>
            )}
            {currency && <span className="currency">{currency}</span>}
            {success && !errorMessage && <i className="fas fa-check" />}
            {extraIconClassname && <i className={extraIconClassname} onClick={iconOnclickHandler} />}
            {error && (
                <>
                    {errorMessage && errorIcon && (
                        <i className={`fas fa-exclamation-triangle ${label && "with-label"}`} />
                    )}
                    {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                </>
            )}
        </div>
    </div>
);

export default Input;

import actions from "./types.js"

export function fetchTestingSuiteRequest(offset, limit) {
    return {
        type: actions.FETCH_TEST_USER_START,
        payload: {
            offset,
            limit,
        },
    };
}
export function fetchTestingSuiteSuccess(testUserData) {
    return {
        type: actions.FETCH_TEST_USER_SUCCESS,
        payload: testUserData,
    };
}
export function fetchTestingSuiteFailure(error) {
    return {
        type: actions.FETCH_TEST_USER_FAILURE,
        payload: error,
    };
}
export const userEmails = `
    users {
        Email
    }
`

export const activityColumnMails = `
    ID
    date
    subject
    body
`
export const nativeUserActivityData = `
    uid
    TypeActivity
    StatusActivity
    ActivityContent
    CreatedDate
    UpdatedDate
    LinkUsers
`

export const UserEmailActivityData = `
    ${nativeUserActivityData}
    LinkEmail {
        uid
        From
        To
        Subject
        CreatedDate
        Title
        Body
        Cc
        Bcc
        Preview
        Body
    }
`

export const UserSmsActivityData = `
    ${nativeUserActivityData}
    LinkSMS {
        uid
        Message
        LinkPhoneNumber {
            uid
            TypePhone
            CountryCode
            DialCode
            Number
            Is2FA
            IsPreferredPrimaryContact
        }
        CreatedDate 
    }
`
export const userDetailsCallsData = `
    uid
    StartTime
    Duration
    Direction
    LinkPhoneFromNumber {
        DialCode
        Number
    }
    LinkPhoneToNumber {
        DialCode
        Number
    }
    StatusCall
`
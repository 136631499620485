import { createSelector } from "reselect";

const setAdequacy = (state) => state.adequacy.adequacyData;
const setAdequacyRenew = (state) => state.adequacy.adequacyRenewData;
const fetchAdequacy = (state) => state.adequacy.fetchAdequacyData;
const setAdequacyBackup = (state) => state.adequacy.adequacyBackup;

export const selectAdequacy = createSelector(setAdequacy, (adequacyData) => adequacyData);

export const selectRenewAdequacy = createSelector(setAdequacyRenew, (setAdequacyRenew) => setAdequacyRenew);

export const selectAdequacyBackup = createSelector(setAdequacyBackup, (setAdequacyBackup) => setAdequacyBackup);

export const selectFetchAdequacy = createSelector(fetchAdequacy, (fetchAdequacyData) => fetchAdequacyData);

export const selectAdequacyStep = createSelector(setAdequacy, (adequacyData) => adequacyData.step);

export const selectAdequacyIsAccepted = createSelector(setAdequacy, (adequacyData) => adequacyData.isAccepted);

import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { formatGermanNumber, formatEnglishNumber } from "utils";
import { selectLocale } from "store/app/selectors";

const CustomFormatNumber = ({ className = "", locale, value = 0, symbol = "€", fixed = 2, noSymbol = false }) => {
    const newValue = value === "" ? 0 : value;
    return (
        <span className={`${className}`}>
            {locale === "en" ? formatEnglishNumber(newValue, fixed) : formatGermanNumber(newValue, fixed)}{" "}
            {!noSymbol ? symbol : ""}
        </span>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps)(CustomFormatNumber);

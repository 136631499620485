import { formatDateWithLocale } from "utils";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLocale } from "store/app/selectors";

const FormatDateWithLocale = ({ locale, date }) => {
    return formatDateWithLocale(locale, date);
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps)(FormatDateWithLocale);

import PerformanceActionTypes from "./types";

export const fetchPerformancesStart = (offset, limit) => ({
    type: PerformanceActionTypes.FETCH_PERFORMANCES_START,
    payload: { offset, limit },
});

export const fetchPerformancesSuccess = (payload) => {
    return {
        type: PerformanceActionTypes.FETCH_PERFORMANCES_SUCCESS,
        payload: payload,
    };
};

export const fetchPerformancesFailure = (error) => ({
    type: PerformanceActionTypes.FETCH_PERFORMANCES_FAILURE,
    payload: error,
});

import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop, useUtils } from "hooks";
import { Player } from "@lottiefiles/react-lottie-player";
import successIcon from "assets/images/success-icon.json";
import Waiting from "assets/images/svg/waiting.svg";
import NavLink from "components/NavLink";

import { selectXpectoCustomerCleared } from "store/user/account/selectors";
import { WEB_FLOW_ROUTES } from "constants/index";

const messages = {
    title: "successful",
    ewalletTitle: "ewallet_ready_to_use",
    smWaitingContent: "waiting_for_activation",
    smHeading: "ewallet_secondary_ready",
    fwHeading: "your_ewallet_ready",
    fwContent: "overview_deposit_investments_withdrawals",
    fwPointOne: "ewallet_credit_account",
    fwPointTwo: "own_iban",
    fwPointThree: "access_funds_to_invest",
    fwPointFour: "withdraw_balance_any_time",
    fwButtonText: "pay_in_credit_now",
    fwBottomLine: "more_about_ewallet",
    knowledgeBaseLinkText: "knowledge_base_link",
};

const Success = ({ openEuroWallet, onCloseButtonClick, isXpectoCustomerCleared, backSlideEffect }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const isTenantFinexity = useUtils().isTenantFinexity;

    return (
        <div className={`mb-20 ${backSlideEffect}`}>
            <div className="bg-dark p-21 text-center">
                <Player autoplay loop={false} keepLastFrame speed={1} src={successIcon} style={{ maxWidth: 80 }} />
                <span className="d-inline-block text-18 mt-3">
                    {isXpectoCustomerCleared ? formatMessage(messages.title) : formatMessage(messages.ewalletTitle)}
                </span>
            </div>
            <div className="px-5">
                {isXpectoCustomerCleared ? null : (
                    <div className="bg-lighter-grey border-radius p-4 mt-4 d-flex">
                        <Waiting className="mr-3" style={{ minWidth: "30px" }} />
                        <span className="text-16">{formatMessage(messages.smWaitingContent)}</span>
                    </div>
                )}
                <h3 className="mt-8">
                    {isXpectoCustomerCleared ? formatMessage(messages.smHeading) : formatMessage(messages.fwHeading)}
                </h3>
                <p className="text-16">{formatMessage(messages.fwContent)}</p>
                <div className="my-10">
                    <div className="d-flex align-start">
                        <i className="text-22 line-height-initial mr-5 fas fa-check-circle primary-color" />
                        <p className="text-16">{formatMessage(messages.fwPointOne)}</p>
                    </div>
                    <div className="d-flex align-start">
                        <i className="text-22 line-height-initial mr-5 fas fa-check-circle primary-color" />
                        <p className="text-16">{formatMessage(messages.fwPointTwo)}</p>
                    </div>
                    <div className="d-flex align-start">
                        <i className="text-22 line-height-initial mr-5 fas fa-check-circle primary-color" />
                        <p className="text-16">{formatMessage(messages.fwPointThree)}</p>
                    </div>
                    <div className="d-flex align-start">
                        <i className="text-22 line-height-initial mr-5 fas fa-check-circle primary-color" />
                        <p className="text-16">{formatMessage(messages.fwPointFour)}</p>
                    </div>
                </div>
                <button
                    className="mb-12"
                    onClick={() => {
                        onCloseButtonClick();
                        openEuroWallet();
                    }}
                >
                    {formatMessage(messages.fwButtonText)}
                </button>
                {isTenantFinexity && (
                    <p className="text-16">
                        {formatMessage(messages.fwBottomLine)}
                        <NavLink isExternal className="primary-color" href={WEB_FLOW_ROUTES.KNOWLEDGE_BASE}>
                            {formatMessage(messages.knowledgeBaseLinkText)}
                        </NavLink>
                    </p>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    isXpectoCustomerCleared: selectXpectoCustomerCleared,
});

export default connect(mapStateToProps)(Success);

const AdequacyActionTypes = {
    SET_ADEQUACY_START: "SET_ADEQUACY_START",
    SET_ADEQUACY_REVOKE: "SET_ADEQUACY_REVOKE",
    SET_ADEQUACY_SUCCESS: "SET_ADEQUACY_SUCCESS",
    SET_ADEQUACY_FAILURE: "SET_ADEQUACY_FAILURE",
    FETCH_ADEQUACY_START: "FETCH_ADEQUACY_START",
    FETCH_ADEQUACY_SUCCESS: "FETCH_ADEQUACY_SUCCESS",
    FETCH_ADEQUACY_FAILURE: "FETCH_ADEQUACY_FAILURE",
};

export default AdequacyActionTypes;

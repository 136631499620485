const InvestmentActionTypes = {
    SET_FILTERS: "SET_FILTERS",
    SET_SORT_FILTERS: "SET_SORT_FILTERS",
    SET_INVESTMENT_STEP_DATA: "SET_INVESTMENT_STEP_DATA",
    CLEAR_INVESTMENT_STEP_DATA: "CLEAR_INVESTMENT_STEP_DATA",
    RESET_INVESTMENT_STEP_DATA: "RESET_INVESTMENT_STEP_DATA",

    FETCH_USER_INVESTMENTS_START: "FETCH_USER_INVESTMENTS_START",
    FETCH_USER_INVESTMENTS_SUCCESS: "FETCH_USER_INVESTMENTS_SUCCESS",
    FETCH_USER_INVESTMENTS_FAILURE: "FETCH_USER_INVESTMENTS_FAILURE",

    FETCH_INVESTMENT_AMOUNT_START: "FETCH_INVESTMENT_AMOUNT_START",
    FETCH_INVESTMENT_AMOUNT_SUCCESS: "FETCH_INVESTMENT_AMOUNT_SUCCESS",
    FETCH_INVESTMENT_AMOUNT_FAILURE: "FETCH_INVESTMENT_AMOUNT_FAILURE",

    SET_INITIAL_INVESTMENT_DATA_START: "SET_INITIAL_INVESTMENT_DATA_START",
    SET_INITIAL_INVESTMENT_DATA_SUCCESS: "SET_INITIAL_INVESTMENT_DATA_SUCCESS",
    SET_INITIAL_INVESTMENT_DATA_FAILURE: "SET_INITIAL_INVESTMENT_DATA_FAILURE",

    CREATE_INVESTMENT_DATA_START: "CREATE_INVESTMENT_DATA_START",
    CREATE_INVESTMENT_DATA_SUCCESS: "CREATE_INVESTMENT_DATA_SUCCESS",
    CREATE_INVESTMENT_DATA_FAILURE: "CREATE_INVESTMENT_DATA_FAILURE",
};

export default InvestmentActionTypes;

const TenantActionTypes = {
    FETCH_TENANTS_START: "FETCH_TENANTS_START",
    FETCH_TENANTS_SUCCESS: "FETCH_TENANTS_SUCCESS",
    FETCH_TENANTS_FAILURE: "FETCH_TENANTS_FAILURE",
    FETCH_TENANT_USERS_START: "FETCH_TENANT_USERS_START",
    FETCH_TENANT_USERS_SUCCESS: "FETCH_TENANT_USERS_SUCCESS",
    FETCH_TENANT_USERS_FAILURE: "FETCH_TENANT_USERS_FAILURE",
};

export default TenantActionTypes;

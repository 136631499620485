import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy } from "store/user/adequacy/selectors";
import { setAdequacyStart } from "store/user/adequacy/actions";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { adequacySkipLinkText } from "i18n/messages/buttonTitles";

const messages = {
    heading: "question_about_financial_investment",
    content1: "question_relate_knowledge",
    content2: "state_your_experience_in_years",
    listItem1: "investment_funds",
    listItem2: "bonds",
    listItem3: "adequacy_shares",
    listItem4: "participation_rights",
    listItem5: "subordinated_loans",
};

const FinancialInvestmentsListStep = ({
    className = "",
    setAdequacyStart,
    open,
    backSlideEffect = "",
    setBackSlideEffect,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    return (
        <>
            <div className={`${className} adequacy-container fifth-step ${backSlideEffect}`}>
                <div className="header-part">
                    <h4 className="primary-color">{formatMessage(messages.heading)}</h4>
                </div>
                <div className="content">
                    <p>{formatMessage(messages.content1)}</p>
                    <ul>
                        <li>{formatMessage(messages.listItem1)}</li>
                        <li>{formatMessage(messages.listItem2)}</li>
                        <li>{formatMessage(messages.listItem3)}</li>
                        <li>{formatMessage(messages.listItem4)}</li>
                        <li>{formatMessage(messages.listItem5)}</li>
                    </ul>
                    <p>{formatMessage(messages.content2)}</p>
                </div>
                {StatusAdequacy != "renew" && (
                    <span
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-right");
                        }}
                        className="skip-link"
                    >
                        {formatMessage(adequacySkipLinkText)}
                    </span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setAdequacyStart({ step: 4 });
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        onClick={() => {
                            setAdequacyStart({ step: 6 });
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInvestmentsListStep);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy, selectFetchAdequacy } from "store/user/adequacy/selectors";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import { userDashboardDetailsSuccess } from "store/user/account/actions";
import { setAdequacyStart, fetchAdequacySuccess } from "store/user/adequacy/actions";
import { setAdequacyData, setUserAdditionalStatus } from "api";
import { useFormattedMessage } from "hooks";
import Securities from "./Securities";
import Transactions from "./Transactions";

const messages = {
    pleaseSelect: "please_select",
    firstHeading: "question_about_securities_services",
    firstQuestion: "experience_with_securities_services",
    firstQuestionOption1: "independent",
    firstQuestionOption2: "with_investment_advice",
    firstQuestionOption3: "with_wealth_management",
    secondHeading: "question_about_transaction",
    secondQuestion1: "transaction_on_an_average_peryear",
    secondQuestion2: "average_transaction_amount_initiate",
};

const SecurityServicesStep = ({
    className,
    setAdequacyStart,
    changeStepBack,
    hitButtonBack,
    adequacyData = {},
    userDashboardData = {},
    onPropsSubmit,
    fetchAdequacySuccess,
    fetchAdequacyData,
    isWithSecondaryMarket,
    userDashboardDetailsSuccess,
    backSlideEffect = "",
    setBackSlideEffect,
    open,
    setPreviousStep,
    StatusAdequacy,
}) => {
    const { formatMessage } = useFormattedMessage();

    const [activeStep, setActiveStep] = useState(adequacyData.survey_third_step.step || 1);
    const [formData, setFormData] = useState(adequacyData.survey_third_step || {});
    const [isAdequacyLoading, setAdequacyLoading] = useState(false);

    useEffect(() => {
        setFormData(adequacyData.survey_third_step);
    }, [adequacyData]);

    useEffect(() => {
        if (changeStepBack) {
            hitButtonBack();
            if (activeStep === 1) {
                setAdequacyStart({ step: 6 });
            } else {
                setActiveStep(1);
            }
        }
    }, [changeStepBack]);

    let submittedData = {
        TypeJob: adequacyData.survey_first_step.TypeJob,
        TypeEducation: adequacyData.survey_first_step.TypeEducation,
        IsBondsXPConfirmed: adequacyData.survey_second_step.bonds.IsBondsXPConfirmed,
        IsBondsXPConfirmedDate:
            adequacyData.survey_second_step.bonds.IsBondsXPConfirmedDate &&
            adequacyData.survey_second_step.bonds.IsBondsXPConfirmedDate.value,
        IsSharesXPConfirmed: adequacyData.survey_second_step.shares.IsSharesXPConfirmed,
        IsSharesXPConfirmedDate:
            adequacyData.survey_second_step.shares.IsSharesXPConfirmedDate &&
            adequacyData.survey_second_step.shares.IsSharesXPConfirmedDate.value,
        IsInvestFundsXPConfirmed: adequacyData.survey_second_step.investment_funds.IsInvestFundsXPConfirmed,
        IsInvestFundsXPConfirmedDate:
            adequacyData.survey_second_step.investment_funds.IsInvestFundsXPConfirmedDate &&
            adequacyData.survey_second_step.investment_funds.IsInvestFundsXPConfirmedDate.value,
        IsProfitPartXPConfirmed: adequacyData.survey_second_step.sharing_rights.IsProfitPartXPConfirmed,
        IsProfitPartXPConfirmedDate:
            adequacyData.survey_second_step.sharing_rights.IsProfitPartXPConfirmedDate &&
            adequacyData.survey_second_step.sharing_rights.IsProfitPartXPConfirmedDate.value,
        IsSubordLoanXpConfirmed: adequacyData.survey_second_step.subordinated.IsSubordLoanXpConfirmed,
        IsSubordLoanXpConfirmedDate:
            adequacyData.survey_second_step.subordinated.IsSubordLoanXpConfirmedDate &&
            adequacyData.survey_second_step.subordinated.IsSubordLoanXpConfirmedDate.value,
        BondsXPAssisted:
            adequacyData.survey_third_step.securities.BondsXPAssisted &&
            adequacyData.survey_third_step.securities.BondsXPAssisted.value,
        BondsXPIndependent:
            adequacyData.survey_third_step.securities.BondsXPIndependent &&
            adequacyData.survey_third_step.securities.BondsXPIndependent.value,
        BondsXPConsulted:
            adequacyData.survey_third_step.securities.BondsXPConsulted &&
            adequacyData.survey_third_step.securities.BondsXPConsulted.value,
        BondsTransactions:
            adequacyData.survey_third_step.transactions.BondsTransactions &&
            adequacyData.survey_third_step.transactions.BondsTransactions.value,
        BondsAmount:
            adequacyData.survey_third_step.transactions.BondsAmount &&
            adequacyData.survey_third_step.transactions.BondsAmount.value,
        IsMissingXPConfirmed: true,
    };

    async function onSubmit() {
        try {
            submittedData = {
                ...submittedData,
                BondsXPAssisted: formData.securities.BondsXPAssisted.value || "",
                BondsXPConsulted: formData.securities.BondsXPConsulted.value || "",
                BondsXPIndependent: formData.securities.BondsXPIndependent.value || "",
                BondsTransactions: formData.transactions.BondsTransactions.value || "",
                BondsAmount:
                    formData.transactions.BondsTransactions.value == "none"
                        ? "3.000"
                        : formData.transactions.BondsAmount.value || "",
            };

            const adequacyFormData = {
                ...formData,
                transactions: {
                    ...formData.transactions,
                    BondsAmount:
                        formData.transactions.BondsTransactions.value == "none"
                            ? {
                                  label: "up to 3,000 euros",
                                  value: "3.000",
                              }
                            : formData.transactions.BondsAmount || "",
                },
            };

            setAdequacyLoading(true);
            const res = await setAdequacyData(submittedData);
            res && setAdequacyLoading(false);
            const email = res?.data?.data?.adequacyTile?.user?.Email;
            email && fetchAdequacySuccess({ ...fetchAdequacyData, IsNotWantedConfirmed: false });
            const tempSubmitted_date = new Date();
            email &&
                setAdequacyStart({
                    ...adequacyData,
                    survey_third_step: { ...adequacyFormData, step: 2 },
                    submitted_date: tempSubmitted_date,
                    step: 9,
                });
            email && setBackSlideEffect("step-slide-left");
            const nextUserDashboardData = { ...userDashboardData, StatusAdequacy: "hide" };
            email && isWithSecondaryMarket && userDashboardDetailsSuccess(nextUserDashboardData);
            email && setUserAdditionalStatus({ user: { Email: email, StatusAdequacy: "hide" } });
            email && onPropsSubmit && onPropsSubmit(nextUserDashboardData);
        } catch (error) {
            /* error */
        }
    }

    const bondsTransactionsValue = formData?.transactions?.BondsTransactions?.value;

    useEffect(() => {
        if (bondsTransactionsValue === undefined || bondsTransactionsValue === "" || bondsTransactionsValue === "none") {
            jQuery(".survey-form").find(".second-question").slideUp();
        } else {
            jQuery(".survey-form").find(".second-question").slideDown();
        }
    });

    const renderStep = (step) => {
        switch (step) {
            case 2:
                return (
                    <Transactions
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        setActiveStep={setActiveStep}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        formatMessage={formatMessage}
                        messages={messages}
                        bondsTransactionsValue={bondsTransactionsValue}
                        isAdequacyLoading={isAdequacyLoading}
                        onSubmit={onSubmit}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 1:
            default:
                return (
                    <Securities
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        setActiveStep={setActiveStep}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        formatMessage={formatMessage}
                        messages={messages}
                        adequacyData={adequacyData}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
        }
    };

    return <div className={`${className} adequacy-container seventh-step`}>{renderStep(activeStep)}</div>;
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
    userDashboardData: selectUserDashboardDetails,
    fetchAdequacyData: selectFetchAdequacy,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
    fetchAdequacySuccess: (data) => dispatch(fetchAdequacySuccess(data)),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityServicesStep);

import React from "react";

export const SignUpFormRefContext = React.createContext(React.createRef(null));

export const IntlContext = React.createContext();

export const CalculatorRefContext = React.createContext(React.createRef(null));

export const CurrentInvestmentRefContext = React.createContext(React.createRef(null));

export const BibleFormRefContext = React.createContext(React.createRef(null));

export const PartnerProgramFormRefContext = React.createContext(React.createRef(null));

export const ClubdealFormRefContext = React.createContext(React.createRef(null));

export const CampaignFormRefContext = React.createContext(React.createRef(null));

export const RequestInformationFormRefContext = React.createContext(React.createRef(null));

export const ContactWebsiteRefContext = React.createContext(React.createRef(null));

export const BrokerProvisionCustomerTableRefContext = React.createContext(React.createRef(null));

export const UtilContext = React.createContext();
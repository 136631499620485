import { initializedServerStore } from "store/server-store";
import { initializedClientStore } from "store/client-store";

const createAppStore = (defaultState = {}, props) => {
    if (props.isServer) {
        return initializedServerStore(props, defaultState);
    } else {
        return initializedClientStore(defaultState);
    }
};

export default createAppStore;

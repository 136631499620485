import actions from "./types.js";

export function fetchOrdersRequest(offset, limit, result) {
    return {
        type: actions.FETCH_ORDERS_START,
        payload: {
            offset,
            limit,
            result,
        },
    };
}
export function fetchOrdersSuccess(payload) {
    return {
        type: actions.FETCH_ORDERS_SUCCESS,
        payload: payload,
    };
}
export function fetchOrdersFailure(error) {
    return {
        type: actions.FETCH_ORDERS_FAILURE,
        payload: error,
    };
}

import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { UtilContext } from "contexts";
import {
    selectFinxConfig,
    selectHost,
    selectIsCurrentTenantFinexity,
    selectPlatformSourceFromApp,
    selectIsProdEnv,
    selectDocumentHost,
    selectCurrentTenant,
} from "store/app/selectors";

function UtilContextComponent({ children, ...props }) {
    const combinedProps = {
        ...props,
        host: props.isTenantFinexity ? "finexity.com" : props.host,
        tenant: { host: props.isTenantFinexity ? "finexity.com" : props.host, ...props.tenant },
    };

    return <UtilContext.Provider value={{ ...combinedProps }}>{children}</UtilContext.Provider>;
}

const mapStateToProps = createStructuredSelector({
    host: selectHost,
    isProdEnv: selectIsProdEnv,
    tenant: selectCurrentTenant,
    finxConfig: selectFinxConfig,
    documentHost: selectDocumentHost,
    isTenantFinexity: selectIsCurrentTenantFinexity,
    isPlatformSourceFromApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(UtilContextComponent);

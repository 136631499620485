import { useUtils } from "hooks";
import { Trans } from "react-i18next";

export default ({ id }) => {
    const currentTenant = useUtils().tenant;
    return (
        <Trans
            i18nKey={id} // optional -> fallbacks to defaults if not provided
            defaults={id}
            values={{
                tenant: currentTenant["Name"],
                host: currentTenant.host,
                tenant_smtp_user: currentTenant?.SMTPUser,
                tenant_legal_name: currentTenant?.LegalName,
            }}
            transSupportBasicHtmlNodes
            transKeepBasicHtmlNodesFor={["br", "strong", "i", "p", "span"]}
        />
    );
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import Card from "assets/images/svg/card.svg";
import TopGreyDiv from "../components/topGreyDiv";
import { updateUserMangopayKYCLevel } from "store/user/account/actions";
import { MANGOPAY_KYC_LEVEL } from "constants/index";
import { Player } from "@lottiefiles/react-lottie-player";
import successIcon from "assets/images/success-icon.json";

const messages = {
    rifCloseTitle: "wallet_payout_title",
    rifSuccessTitle: "activation_under_review",
    rifSuccessContent1: "thankyou_for_registering_id_card",
    rifSuccessContent2: "data_check_and_inform",
    rifSuccessButtonText: "to_the_ewallet_overview",
};

const Success = ({ setCloseTitle, setStep, updateUserMangopayKYCLevel, backSlideEffect = "", setBackSlideEffect }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setCloseTitle(formatMessage(messages.rifCloseTitle));
        updateUserMangopayKYCLevel(MANGOPAY_KYC_LEVEL.LIGHT);
    }, []);

    return (
        <div className={`retakeif-success ${backSlideEffect}`}>
            <TopGreyDiv icon svgIcon={<Card style={{ maxWidth: "100px" }} />} />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3>{formatMessage(messages.rifSuccessTitle)}</h3>
                <Player
                    autoplay
                    loop={false}
                    keepLastFrame
                    speed={1}
                    src={successIcon}
                    style={{ width: 94, marginTop: 60, marginBottom: 60 }}
                />
                <p className="text-16 mb-1">{formatMessage(messages.rifSuccessContent1)}</p>
                <p className="text-16 mb-10">{formatMessage(messages.rifSuccessContent2)}</p>
                <button
                    className="mw-100"
                    onClick={() => {
                        setStep("walletOverview");
                        setBackSlideEffect("step-slide-right");
                    }}
                >
                    {formatMessage(messages.rifSuccessButtonText)}
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateUserMangopayKYCLevel: (value) => dispatch(updateUserMangopayKYCLevel(value)),
});

export default connect(null, mapDispatchToProps)(Success);

import ProjectActionTypes from "./types";

const INITIAL_STATE = {
    data: [],
    singleProjectData: [],
    error: "",
    isLoading: false,
};

const projectReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ProjectActionTypes.FETCH_PROJECT_START:
        case ProjectActionTypes.FETCH_LIMITED_PROJECT_START:
            return {
                ...state,
                isLoading: true,
            };
        case ProjectActionTypes.FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        case ProjectActionTypes.FETCH_MARKETPLACE_PROJECT_SUCCESS:
            return {
                ...state,
                singleProjectData: payload,
                isLoading: false,
            };
        case ProjectActionTypes.FETCH_LIMITED_PROJECT_SUCCESS:
            return {
                ...state,
                dataLimited: payload,
                isLoading: false,
            };
        case ProjectActionTypes.FETCH_PROJECT_FAILURE:
        case ProjectActionTypes.FETCH_LIMITED_PROJECT_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
            };

        case ProjectActionTypes.SET_SELECTED_PROJECT_ID:
            return {
                ...state,
                selectedProjectID: payload,
            };
        default:
            return state;
    }
};

export default projectReducer;

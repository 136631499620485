import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useUtils } from "hooks";
import { getTenantAssets, removeOverlay } from "utils";
import { selectLocale } from "store/app/selectors";
import { WEB_FLOW_ROUTES } from "constants/index";
import NextButton from "components/NextButton";
import NavLink from "components/NavLink";

const messages = {
    heading: "secondary_market_for_digital_assets",
    title: "how_it_works_title",
    subTitle: "buy_and_sell_digital_shares",
    step1: "create_an_account",
    step2: "activated_for_secondary",
    step3: "choose_asset_trade",
    step4: "buy_sell_digitally",
    moreInfo1: "see_more_information",
    moreInfo2: "knowledge_base",
    moreInfo3: "and_in_our",
    moreInfo4: "terms_conditions",
    moreInfo5: "full_stop_dot",
    noteTitle: "adequacy_note",
    noteText: "trading_on_secondary_market",
    buttonText: "join_now",
};

const Step = ({ className, step, text }) => {
    return (
        <div className={`d-flex mb-6 ${className}`}>
            <cite className="mr-4">{step}.</cite>
            <p className="body-medium mb-0 pt-2">{text}</p>
        </div>
    );
};

const NotRegistered = ({ locale, setCloseTitle, open }) => {
    const router = useRouter();
    const { formatMessage } = useFormattedMessage();
    const currentTenant = useUtils().tenant.TechnicalName;
    const documentHost = useUtils().documentHost;

    const tenantMarketPlaceFile = getTenantAssets(documentHost, currentTenant).tenantMarketPlaceFile;

    useEffect(() => {
        setCloseTitle(formatMessage(messages.heading));
        jQuery(".side-modal").animate({ scrollTop: 0 });
    }, []);

    return (
        <>
            <div className="mb-15 not-registered">
                <h3>{formatMessage(messages.title)}</h3>
                <p>{formatMessage(messages.subTitle)}</p>
                <img
                    className="image mt-7 mb-10 mw-100"
                    src={locale === "en" ? "/images/orderDummyEN.png" : "/images/orderDummyDE.png"}
                    alt=""
                />
                <div className="steps">
                    <Step step="1" text={formatMessage(messages.step1)} />
                    <Step step="2" text={formatMessage(messages.step2)} />
                    <Step step="3" text={formatMessage(messages.step3)} />
                    <Step className="mb-10" step="4" text={formatMessage(messages.step4)} />
                    <p className="text-14 mb-10">
                        {formatMessage(messages.moreInfo1)}
                        <NavLink isExternal className="primary-color" isForcePushed href={WEB_FLOW_ROUTES.KNOWLEDGE_BASE}>
                            {formatMessage(messages.moreInfo2)}
                        </NavLink>
                        {formatMessage(messages.moreInfo3)}
                        <NavLink isExternal className="primary-color" href={tenantMarketPlaceFile}>
                            {formatMessage(messages.moreInfo4)}
                        </NavLink>
                        {formatMessage(messages.moreInfo5)}
                    </p>
                    <div className="border-2 border-radius p-4">
                        <p className="text-14 secondary-light-color mb-3">
                            <i className="fas fa-info-circle mr-3" />
                            {formatMessage(messages.noteTitle)}
                        </p>
                        <p className="text-14 secondary-light-color mb-1">{formatMessage(messages.noteText)}</p>
                    </div>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container single-button">
                    <NextButton
                        className="mw-100 mr-1"
                        title={formatMessage(messages.buttonText)}
                        onClick={() => {
                            router.push("/register", undefined, { shallow: true });
                            removeOverlay();
                        }}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps)(NotRegistered);

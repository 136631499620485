import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// import Cookies from "js-cookie";

import CustomFormatNumber from "components/CustomFormatNumber";

import Wallet from "assets/images/svg/wallet.svg";

import { selectPayInWalletBalance } from "store/user/account/selectors";

const MangoPayWalletBalance = ({ amount }) => {
    amount = amount || 0;

    return (
        <>
            <span className="pr-3 flex wallet-svg">
                <Wallet />
            </span>
            <CustomFormatNumber value={amount} />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    amount: selectPayInWalletBalance,
});

export default connect(mapStateToProps)(MangoPayWalletBalance);

import React from "react";
import { connect } from "react-redux";
import { useFormattedMessage } from "hooks";
import { paymentStatusMapping, paymentTypeMapping } from "constants/index";
import { PAYMENT_TYPE } from "constants/status";
import { splitDateStringForProperDate, getRegionFormattedDate } from "utils";
import { selectDataFromUserDetails } from "store/user/account/selectors";
import BankActivity from "./bankActivity";
import BankActivityIcon from "assets/images/svg/bank-activity-icon.svg";
import BusinessAndFinance from "assets/images/svg/business-and-finance.svg";
import MoneyCurrency from "assets/images/svg/money-currency.svg";
import Donation from "assets/images/svg/donation.svg";
import EwalletTransferIcon from "assets/images/svg/profit-euro.svg";

const messages = {
    secondaryMarketSale: "secondary_market_sale",
    secondaryMarketBuy: "secondary_market_buy",
};

const AllActivitiesBlock = ({ payments, onBankActivityClick, showIconBorderColor, userEmail, colorForAll = "" }) => {
    const statusMapping = paymentStatusMapping();
    const typeMapping = paymentTypeMapping();

    const { formatMessage } = useFormattedMessage();

    return payments.map((activity) => {
        const {
                uid: ID,
                LinkBankAccount: linkBankAccount,
                Beneficiary: beneficiary,
                StatusPayment: statusPayment,
                Creditor: creditor,
                TypePayment: typePayment,
                Amount: amount,
                CreatedDate: createdDate,
            } = activity,
            color = colorForAll || "#f1f5f5";
        let isGreen = false,
            iconComponent,
            typeColor;
        const date = getRegionFormattedDate(new Date(...splitDateStringForProperDate(createdDate)));
        let emailName;

        if (
            (typePayment === PAYMENT_TYPE.USER_TRANSFER_EXTERNAL && beneficiary === userEmail) ||
            typePayment === PAYMENT_TYPE.USER_PAYIN ||
            typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE
        ) {
            isGreen = true;
        }

        if (typePayment === PAYMENT_TYPE.USER_TRANSFER_EXTERNAL) {
            // !colorForAll && (color = "#0B2027")
            // iconComponent = () => (
            //     <span className={`text-16 fw-600 ${initialsColor}`}>
            //         {linkBankAccount && getInitialsFromName(linkBankAccount.Owner)}
            //     </span>
            // )
            if (userEmail == creditor) {
                isGreen = false;
                emailName = beneficiary;
            } else {
                emailName = creditor;
                isGreen = true;
            }
            iconComponent = () => <BusinessAndFinance height={25.275} width={25.524} />;
        } else if (typePayment === PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER) {
            if (userEmail == creditor) {
                isGreen = false;
                emailName = beneficiary;
            } else {
                emailName = creditor;
                isGreen = true;
            }
            iconComponent = () => (
                <EwalletTransferIcon style={{ width: "25px", height: "25px" }} height={25.275} width={25.524} />
            );
        } else if (typePayment === PAYMENT_TYPE.USER_PAYIN) {
            iconComponent = () => <Donation height={25.275} width={25.524} />;
        } else if (typePayment === PAYMENT_TYPE.USER_PAYOUT) {
            iconComponent = () => <MoneyCurrency height={25.275} width={25.524} />;
        } else if (
            typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE ||
            typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY
        ) {
            iconComponent = () => <BusinessAndFinance height={25.275} width={25.524} />;
        } else if (typePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT || typePayment === PAYMENT_TYPE.USER_EXIT_PAYOUT) {
            if (userEmail == creditor) {
                isGreen = false;
                emailName = beneficiary;
            } else {
                emailName = creditor;
                isGreen = true;
            }
            iconComponent = () => <img src="/images/dividends.png" height={25.275} width={25.524} />;
        } else {
            iconComponent = () => <BankActivityIcon height={25.275} width={25.524} />;
        }

        return (
            <BankActivity
                showIconBorderColor={showIconBorderColor}
                onClick={() => {
                    onBankActivityClick(activity);
                }}
                key={ID}
                iconBackgroundColor={color}
                iconComponent={iconComponent}
                name={
                    typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE
                        ? formatMessage(messages.secondaryMarketSale)
                        : typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY
                        ? formatMessage(messages.secondaryMarketBuy)
                        : typePayment === PAYMENT_TYPE.USER_TRANSFER_EXTERNAL ||
                          typePayment === PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER ||
                          typePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT ||
                          typePayment === PAYMENT_TYPE.USER_EXIT_PAYOUT
                        ? emailName
                        : (linkBankAccount && linkBankAccount.Owner) || ""
                }
                isGreen={isGreen}
                amount={amount}
                date={date}
                typeColor={typeColor}
                type={typeMapping[typePayment]}
                status={statusMapping[statusPayment]}
            />
        );
    });
};

const mapStateToProps = (state) => ({
    userEmail: selectDataFromUserDetails(state, ["Email"]),
});

export default connect(mapStateToProps)(AllActivitiesBlock);

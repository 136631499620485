export const weakPassword = "weak_password";
export const commonPassword = "common_password";
export const incorrectPassword = "incorrect_password";
export const passwordSpecialCharacter = "password_special_character";
export const sendFormError = "send_form_error";
export const linkProjectExist = "link_project_exist";
export const symbolExist = "symbol_exist";
export const decimalsError = "decimals_MustLess";
export const access_denied = "access_denied";
export const blocked_user = "blocked_user";
export const somethingWrongText = "something_went_wrong";
export const serviceNotAvailable = "service_is_not_available";
export const sendRegistrationFormError = "sendRegistrationFormError";

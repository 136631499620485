const BrokerActionTypes = {
  SET_BROKER_TOTAL_USERS: "SET_ADMIN_BROKERS_TOTAL_USERS",
  FETCH_BROKERS_START: "FETCH_ADMIN_BROKERS_START",
  FETCH_BROKERS_SUCCESS: "FETCH_ADMIN_BROKERS_SUCCESS",
  FETCH_BROKERS_FAILURE: "FETCH_ADMIN_BROKERS_FAILURE",
  UPDATE_BROKER_ONBOARDING_ADDITIONAL: "UPDATE_BROKER_ONBOARDING_ADDITIONAL",
  UPDATE_BROKER_ACCOUNT_ADDITIONAL: "UPDATE_BROKER_ACCOUNT_ADDITIONAL"
};

export default BrokerActionTypes;

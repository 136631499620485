import React, { useEffect, useState } from "react";
import { useFormattedMessage } from "hooks";
import successIcon from "assets/images/success-icon.json";
import { Player } from "@lottiefiles/react-lottie-player";

const messages = {
    newsletterSuccessText: "newsletter_success_text",
    successAnimationTitle: "thank_you",
    successAnimationContent: "received_your_request",
};

const SuccessAnimation = ({
    id = "",
    className = "",
    isButtonRequired = false,
    isNewsletter = false,
    withContent = false,
    withContentTitle = true,
    withContentDesc,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [isAnimationClosed, setAnimationClosed] = useState(false);

    useEffect(() => {
        isButtonRequired && setTimeout(() => setAnimationClosed(true), 5000);
    });

    return (
        <div
            id={id}
            className={`flex column align-center check-container box-shadow-none email-success ${className} ${
                withContent ? "with-content" : ""
            }`}
        >
            {!isAnimationClosed ? (
                <>
                    <Player
                        autoplay
                        loop={false}
                        keepLastFrame
                        speed={1}
                        src={successIcon}
                        style={{ width: 94, height: 94, marginLeft: "auto", marginRight: "auto" }}
                    />
                    {isNewsletter && <span className="text-block">{formatMessage(messages.newsletterSuccessText)}</span>}
                    {withContent ? (
                        <>
                            {withContentTitle && <h3 className="pt-12">{formatMessage(messages.successAnimationTitle)}</h3>}
                            <p className={!withContentTitle ? "mt-12" : ""}>
                                {withContentDesc || formatMessage(messages.successAnimationContent)}
                            </p>
                        </>
                    ) : null}
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default SuccessAnimation;

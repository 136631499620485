import apisauce from "apisauce";
import { getBaseApiURL } from "common/helper";

var Singleton = (function () {
    var instance;

    function createInstance() {
        return apisauce.create({
            baseURL: getBaseApiURL(),
            headers: {
                "Cache-Control": "no-cache",
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            timeout: 150000,
        });
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default Singleton.getInstance();

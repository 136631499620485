import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withFormik } from "formik";
import { compose } from "recompose";
import * as yup from "yup";
import cn from "classnames";
import { mangopayOnboarding } from "api";
import { useFormattedMessage, useSideModalScrollToTop, useUtils } from "hooks";
import NextButton from "components/NextButton";
import Checkbox from "components/Checkbox";
import DownloadButton from "components/DownloadButton";
import NavLink from "components/NavLink";

import { getTenantAssets } from "utils";
import { getHost } from "common/helper";

import { selectPlatformSourceFromApp, selectLocale } from "store/app/selectors";
import { selectFinexityTermsConditionsConfirmed } from "store/user/account/selectors";
import { sendFormError } from "i18n/messages/errors";
import { GENERAL_CONSTANTS, WEB_FLOW_ROUTES } from "constants/index";
import IntlFormattedMessage from "components/IntlFormattedMessage";

const HOST = getHost();

const messages = {
    title: "agree_to_terms_conditions",
    ftcCheckbox: "taken_note_of_secondary_market",
    mtcCheckbox: "read_general_terms_conditions_mangopay",
    smpCheckbox1: "end_of_usage_contract",
    smpCheckbox2: "data_policy",
    smpCheckbox3: "dot",
    buttonText: "get_access_now",
    checkboxRequired: "you_must_agree",
};

const TermsAndCondition = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    validateForm,
    locale,
    isSubmitting,
    isFinexityTermsConditionsConfirmed,
    status,
    open,
    isApp,
    backSlideEffect = "",
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const tenantLegalName = useUtils().tenant.LegalName;
    const currentTenant = useUtils().tenant.TechnicalName;
    const documentHost = useUtils().documentHost;

    const tenantMarketPlaceFile = getTenantAssets(documentHost, currentTenant).tenantMarketPlaceFile;

    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(formatMessage(sendFormError));
        }
    }, [status]);

    const mtcPDFPath = locale === "en" ? GENERAL_CONSTANTS.MANGOPAY_TERMS_EN : GENERAL_CONSTANTS.MANGOPAY_TERMS_DE;
    return (
        <>
            <div className={backSlideEffect}>
                <h3>{formatMessage(messages.title)}</h3>
                <form method="post" onSubmit={handleSubmit}>
                    {isFinexityTermsConditionsConfirmed ? null : (
                        <div>
                            <div className="check-section d-flex">
                                <Checkbox
                                    inputId="ftc"
                                    name="ftc"
                                    value={values.ftc}
                                    label={formatMessage(messages.ftcCheckbox)}
                                    onChange={() => setFieldValue("ftc", !values.ftc)}
                                    isError={touched.ftc && errors.ftc}
                                />
                            </div>
                            <DownloadButton
                                className="mb-6 ml-12"
                                fileURL={tenantMarketPlaceFile}
                                title={`${tenantLegalName} AGB`}
                            />
                        </div>
                    )}
                    <div>
                        <div className="check-section d-flex">
                            <Checkbox
                                inputId="mtc"
                                name="mtc"
                                value={values.mtc}
                                label={<IntlFormattedMessage id={messages.mtcCheckbox} />}
                                onChange={() => setFieldValue("mtc", !values.mtc)}
                                isError={touched.mtc && errors.mtc}
                            />
                        </div>
                        <NavLink isExternal href={`${mtcPDFPath}`}>
                            <div className="property-document mb-3 mb-6 ml-12 d-block" target="_blank" rel="noopener noreferrer">
                                <div className="flex justify-between align-center">
                                    <div className="document-details">
                                        <h6 className="document-title my-0 word-break">Mangopay SA AGB</h6>
                                    </div>
                                    <div className="document-icon">
                                        <i className="fas fa-download" />
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div>
                        <div className="check-section d-flex">
                            <Checkbox
                                inputId="smp"
                                name="smp"
                                value={values.smp}
                                onChange={() => setFieldValue("smp", !values.smp)}
                                isError={touched.smp && errors.smp}
                            />
                            <p>
                                {`${formatMessage(messages.smpCheckbox1)} `}
                                {isApp ? (
                                    <span
                                        className="underline cursor-pointer"
                                        onClick={() => {
                                            window?.ReactNativeWebView?.postMessage(
                                                JSON.stringify({
                                                    external_url: `${HOST}${WEB_FLOW_ROUTES.PRIVACY_POLICY}`,
                                                })
                                            );
                                        }}
                                    >
                                        {formatMessage(messages.smpCheckbox2)}
                                    </span>
                                ) : (
                                    <NavLink isExternal isForcePushed className="underline" href={WEB_FLOW_ROUTES.PRIVACY_POLICY}>
                                        {formatMessage(messages.smpCheckbox2)}
                                    </NavLink>
                                )}
                                {` ${formatMessage(messages.smpCheckbox3)}`}
                            </p>
                        </div>
                    </div>
                </form>
                {requestError && <span className="error pl-0">{requestError}</span>}
            </div>
            {open && (
                <div className="bottom-bar-container single-button">
                    <NextButton
                        className={cn({
                            "mw-100 mr-4": true,
                            "disable-grey": isSubmitting || Object.keys(errors).length,
                        })}
                        title={formatMessage(messages.buttonText)}
                        onClick={handleSubmit}
                        disable={isSubmitting || Object.keys(errors).length}
                        isLoading={isSubmitting}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
    isFinexityTermsConditionsConfirmed: selectFinexityTermsConditionsConfirmed,
    isApp: selectPlatformSourceFromApp,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => {
            const generalOptions = {
                ftc: false,
                mtc: false,
                smp: false,
            };
            const tncAcceptedOptions = {
                mtc: false,
                smp: false,
            };
            const dataValues = props && props.isFinexityTermsConditionsConfirmed ? tncAcceptedOptions : generalOptions;
            return {
                ...dataValues,
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: (props) => {
            const generalOptions = {
                ftc: yup.bool().required("checkboxRequired").oneOf([true], "checkboxRequired"),
                mtc: yup.bool().required("checkboxRequired").oneOf([true], "checkboxRequired"),
                smp: yup.bool().required("checkboxRequired").oneOf([true], "checkboxRequired"),
            };
            const tncAcceptedOptions = {
                mtc: yup.bool().required("checkboxRequired").oneOf([true], "checkboxRequired"),
                smp: yup.bool().required("checkboxRequired").oneOf([true], "checkboxRequired"),
            };
            const dataValues = props && props.isFinexityTermsConditionsConfirmed ? tncAcceptedOptions : generalOptions;
            return yup.object().shape({
                ...dataValues,
            });
        },
        handleSubmit: (values, { props, setSubmitting, setStatus }) => {
            setSubmitting(true);

            const payload = {
                IsFinexityTermsConditionsConfirmed:
                    props && props.isMangopayTermsConditionsConfirmed ? "true" : values.ftc ? "true" : "false",
                IsMangopayTermsConditionsConfirmed: values.mtc ? "true" : "false",
                IsUserSecondaryMarketPassDataConfirmed: values.smp ? "true" : "false",
            };

            mangopayOnboarding(payload)
                .then((res) => {
                    setSubmitting(false);

                    if (!res || res.errors || res.data.errors || res.data.data.errors) {
                        const error =
                            res && res.data && res.data.errors && res.data.errors[0] ? res.data.errors[0].message : "error";
                        return setStatus({ requestError: error });
                    }
                    if (res.data.data.mangopayOnboarding) {
                        return props.submitSuccess && props.submitSuccess();
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                });
        },
        displayName: "TermsAndCondition",
    })
)(TermsAndCondition);

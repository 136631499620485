module.exports.EXCLUDE_COUNTRIES = [
    "dz",
    "bf",
    "td",
    "cd",
    "dj",
    "er",
    "tn",
    "ne",
    "ng",
    "re",
    "rw",
    "st",
    "sz",
    "pf",
    "gu",
    "fm",
    "nc",
    "nu",
    "nf",
    "mp",
    "pw",
    "ws",
    "sb",
    "to",
    "tv",
    "vu",
    "wf",
];

module.exports.INCLUDE_COUNTRIES = [
    "al",
    "ad",
    "at",
    "be",
    "ba",
    "bg",
    "ic",
    "hr",
    "cy",
    "cz",
    "dk",
    "ee",
    "fo",
    "ax",
    "fr",
    "de",
    "gi",
    "gr",
    "gg",
    "hu",
    "is",
    "ie",
    "im",
    "it",
    "xk",
    "lv",
    "li",
    "lt",
    "lu",
    "mt",
    "md",
    "mc",
    "me",
    "nl",
    "no",
    "pl",
    "pt",
    "mk",
    "ro",
    "sm",
    "rs",
    "sk",
    "si",
    "es",
    "se",
    "ch",
    "ua",
    "gb",
    "va",
    "sa",
    "th",
    "bh",
    "kw",
    "ae",
    "qa",
    "au",
    "as",
    "nz",
    "pg",
    "mh",
    "fj",
    "ki",
    "ck",
    "cf",
    "gh",
    "ke",
    "mg",
    "mu",
    "so",
    "ug",
    "ao",
    "bi",
    "et",
    "gn",
    "ls",
    "mw",
    "ma",
    "sn",
    "za",
    "tz",
    "zm",
    "bj",
    "cm",
    "km",
    "eg",
    "ga",
    "gw",
    "lr",
    "ml",
    "mz",
    "sc",
    "ss",
    "tg",
    "zw",
    "bw",
    "cv",
    "cg",
    "gq",
    "gm",
    "ci",
    "ly",
    "mr",
    "na",
    "sl",
    "sd",
    "ar",
    "bo",
    "br",
    "cl",
    "co",
    "ec",
    "fk",
    "gf",
    "gy",
    "py",
    "pe",
    "sr",
    "uy",
    "ve",
    "in",
    "tr",
    "vn",
    "am",
    "bt",
    "id",
    "jp",
    "kw",
    "mm",
    "mo",
    "tw",
    "tm",
    "ye",
    "bn",
    "bh",
    "ge",
    "my",
    "sg",
    "sa",
    "hk",
    "uz",
    "bs",
    "bb",
    "bz",
    "bm",
    "ca",
    "ky",
    "cr",
    "cu",
    "cw",
    "do",
    "sv",
    "gt",
    "ht",
    "hn",
    "jm",
    "mx",
    "pa",
    "pr",
    "tt",
    "us",
    "vg",
    "vi",
];

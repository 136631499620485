import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ArrowRight from "assets/images/svg/arrow-right.svg";
import { useRouter } from "next/router";
import { backButtonText } from "i18n/messages/buttonTitles";
import { useFormattedMessage, useUtils } from "hooks";
import { selectPlatformSourceFromApp } from "store/app/selectors";

const BackButton = ({ className = "", title, onClick }) => {
    const router = useRouter();
    const { formatMessage } = useFormattedMessage();

    return (
        <div
            className={`back-button flex row navigation-2 align-center ${className}`}
            onClick={() => (onClick ? onClick() : router.goBack)}
        >
            {/* <img src={images.backArrow} alt="Back" /> */}
            <ArrowRight />
            <span className="body-large d-block">{title || formatMessage(backButtonText)}</span>
        </div>
    );
};

export const BackButtonWithContainerApp = ({ className = "", title, onClick }) => {
    const router = useRouter();
    const isApp = useUtils().isPlatformSourceFromApp;
    const { formatMessage } = useFormattedMessage();

    return isApp ? (
        <div className="back-button-container-app">
            <div className="small-container flex justify-between align-center">
                <div
                    className={`back-button flex row navigation-2 align-center ${className}`}
                    onClick={() => (onClick ? onClick() : router.back?.())}
                >
                    <ArrowRight />
                    <span className="body-large d-block">{title || formatMessage(backButtonText)}</span>
                </div>
            </div>
        </div>
    ) : null;
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(BackButton);

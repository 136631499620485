import React from "react";
import { PatternFormat } from "react-number-format";

const DateInput = ({
    className = "",
    readOnly,
    format = "##/##/####",
    label,
    sublabel,
    placeholder,
    value = "",
    labelClassName,
    errorMessage = "",
    onChange = () => {},
    errorClassName = "error-element",
    success = false,
    errorIcon = true,
    error,
    onBlur = () => {},
    renderLabel,
    isDateIcon = false,
    allowEmptyFormatting = false,
    ...rest
}) => (
    <div className={`form-element ${className} ${error ? errorClassName : ""}`} data-role="fieldcontain">
        {label && <label className={`mb-2 d-inline-block ${labelClassName}`}>{label}</label>}
        {sublabel && Object.keys(sublabel).length > 0 && (
            <div className="d-flex justify-between mb-2">
                {sublabel.left && <span className="secondary-light-color text-14">{sublabel.left}</span>}
                {sublabel.right && <span className="text-14 fw-500">{sublabel.right}</span>}
            </div>
        )}
        {typeof renderLabel === "function" && renderLabel()}
        <div className="position-relative">
            <PatternFormat
                {...rest}
                format={format}
                allowEmptyFormatting={allowEmptyFormatting}
                mask="_"
                value={value}
                placeholder={placeholder}
                onValueChange={onChange}
                readOnly={readOnly}
                onBlur={onBlur}
            />
            {isDateIcon && <i className="fas fa-calendar-alt" />}
            {success && !errorMessage && <i className="fas fa-check" />}
            {error && (
                <>
                    {errorMessage && errorIcon && <i className={`fas fa-exclamation-triangle ${label && "with-label"}`} />}
                    {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                </>
            )}
        </div>
    </div>
);

export default DateInput;

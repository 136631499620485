import React, { useEffect } from "react";
import Block from "assets/images/svg/block.svg";
import { useFormattedMessage } from "hooks";

const messages = {
    title: "access_blocked",
    subTitle: "blocked_for_security_reasons",
    content1: "no_access_to_secondary_market",
    content2: "protecting_your_data_assets",
    content3: "contact_you",
    buttonText: "close_btn",
};

const Blocked = ({ onCloseButtonClick }) => {
    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        jQuery(".side-modal").animate({ scrollTop: 0 });
    }, []);

    return (
        <div className="mb-20">
            <h3 className="text-center">{formatMessage(messages.title)}</h3>
            <p>{formatMessage(messages.subTitle)}</p>
            {/* <img className="t-18 mb-15 mx-auto d-block" src={"/images/pause.png"} alt="" /> */}
            <div className="mt-18 mb-15 text-center d-block">
                <Block />
            </div>
            <p className="text-center mb-10 text-16">{formatMessage(messages.content1)}</p>
            <p className="text-center mb-10 text-16">{formatMessage(messages.content2)}</p>
            <p className="text-center mb-15 text-16">{formatMessage(messages.content3)}</p>
            {/* <NavLink
                href="/dashboard"> */}
            <button onClick={() => onCloseButtonClick()} className="">
                {formatMessage(messages.buttonText)}
            </button>
            {/* </NavLink> */}
        </div>
    );
};

export default Blocked;

import React, { useState, useEffect } from "react";
import { withFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { selectDataFromUserDetails } from "store/user/account/selectors";
import CustomFormatNumber from "components/CustomFormatNumber";
import Input from "components/Input";
import CurrencyInput from "components/CurrencyInput";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import { email as emailMessages, transferAmount as amountMessages } from "i18n/messages/formElements";
import { euroWalletValidations } from "utils/yupValidation";
import { mangopayTransfer } from "api";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { sendFormError } from "i18n/messages/errors";
import { selectLocale } from "store/app/selectors";

const messages = {
    transferTitle: "ewallet_send_credit",
    availableBalance: "available_balance",
    sendCredit: "send_credit",
    sendCreditDescription: "send_credit_description",
    recipientNameLabel: "who_is_the_recipient",
    recipientNamePlaceHolder: "recipient_name_placeholder",
    howMuchCreditLabel: "send_credit_label",
    howMuchCreditPlaceHolder: "send_credit_label",
    transferNote: "wallet_transfer_note",
    buttonText: "next",
    mangopayBeneficiaryAccountNotAvailable: "mangopay_beneficiary_account_not_available",
    emailErr: "transfer_Email_required",
    blockedTransferErrorMessage: "blocked_transfer_error",
};

const TransferEMoney = ({
    setShowDate,
    data,
    setCloseTitle,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    submitForm,
    setFieldValue,
    validateForm,
    setFieldTouched,
    setStep,
    status,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.transferTitle));
    }, []);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(
                formatMessage(
                    status.requestError === "mangopay_beneficiary_account_not_available"
                        ? messages.mangopayBeneficiaryAccountNotAvailable
                        : status.requestError === "mangopay_out_flows_blocked"
                        ? messages.blockedTransferErrorMessage
                        : status.requestError === "mangopay_invalid_min_transfer_amount"
                        ? amountMessages["transferAmountMinimumAmountError"]
                        : status.requestError === "mangopay_invalid_max_transfer_amount"
                        ? amountMessages["transferAmountMaximumAmountError"]
                        : sendFormError
                )
            );
        }
    }, [status]);

    const setCurrencyData = (formattedAmount, resetAmount) => {
        setFieldValue("intlAmount", formattedAmount);
        setFieldValue("transferAmount", resetAmount);
        setFieldTouched("intlAmount", true);
    };

    return (
        <div className={`transfer-e-money ${backSlideEffect}`}>
            <div className="hf-50 bg-dark flex-column justify-center align-center">
                <span className="d-inline-block text-16 fw-500 mb-2">{formatMessage(messages.availableBalance)}</span>
                <span className="d-inline-block text-34 fw-600">
                    <CustomFormatNumber value={data.payInWalletBalance} />
                </span>
            </div>
            <div className="px-5 py-4 mw-450 mx-auto">
                <span className="text-22 fw-600 d-inline-block mt-6 mb-6">{formatMessage(messages.sendCredit)}</span>
                <span className="text-16 d-inline-block">{formatMessage(messages.sendCreditDescription)}</span>
                <span className="text-14 fw-600 d-inline-block mt-8 mb-3">{formatMessage(messages.recipientNameLabel)}</span>
                <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder={formatMessage(messages.recipientNamePlaceHolder)}
                    className="mb-0"
                    inputClassName="text-input form-item"
                    onChange={(e) => {
                        setFieldTouched("email", true);
                        handleChange(e);
                    }}
                    value={values.email}
                    onBlur={handleBlur}
                    errorMessage={
                        touched.email && errors.email && errors.email === "email_recipient_already"
                            ? formatMessage(messages.emailErr)
                            : errors.email
                            ? formatMessage(emailMessages[errors.email])
                            : null
                    }
                    error={touched.email && errors.email}
                />
                <span className="text-14 fw-600 d-inline-block mt-7 mb-3">{formatMessage(messages.howMuchCreditLabel)}</span>
                <CurrencyInput
                    className="mb-0"
                    inputSymbolClass="black-color"
                    inputClassName="text-input form-item"
                    id="intlAmount"
                    name="intlAmount"
                    hiddenInputName="transferAmount"
                    onCurrencyInputChange={(formattedAmount, resetAmount) => setCurrencyData(formattedAmount, resetAmount)}
                    values={values}
                    onBlur={handleBlur}
                    errorMessage={
                        touched.intlAmount && errors.intlAmount
                            ? `${formatMessage(amountMessages[errors.intlAmount])} ${
                                  errors.intlAmount === "transferAmountMaximumValueError" ? data.payInWalletBalance : ""
                              }`
                            : null
                    }
                    error={touched.intlAmount && errors.intlAmount}
                />
                <span className="text-14 d-inline-block mb-5 mt-6">{formatMessage(messages.transferNote)}</span>
                {requestError && <span className="error pl-0">{requestError}</span>}
                <div className="bottom-bar-container">
                    <PrevButton
                        disable={isSubmitting}
                        onClick={() => {
                            setStep("walletOverview");
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        disable={errors.intlAmount || errors.email || isSubmitting}
                        onClick={submitForm}
                        title={formatMessage(messages.buttonText)}
                        isLoading={isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userEmail: selectDataFromUserDetails(state, ["Email"]),
    locale: selectLocale(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            email: "",
            transferAmount: 0.0,
            intlAmount: "",
        }),
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: ({ data, userEmail, locale }) => {
            const { email } = euroWalletValidations;
            const { transferAmount } = euroWalletValidations;
            const minAmount = 50;
            const maxAmount = 10000;
            return yup.object().shape({
                email: email("email", userEmail),
                intlAmount: transferAmount("intlAmount", data.payInWalletBalance, maxAmount, minAmount, locale),
            });
        },
        handleSubmit: (values, { props, setStatus, setSubmitting }) => {
            setSubmitting(true);

            const data = {
                CreditedUserEmail: values.email.trim(),
                TransferAmount: values.transferAmount,
            };

            mangopayTransfer(data)
                .then((res) => {
                    setSubmitting(false);
                    if (res.data && res.data.errors && res.data.errors[0]) {
                        setStatus({ requestError: res.data.errors[0].message });
                    }

                    if (!res || res.errors || res.data.errors || res.data.data.errors) return;

                    props.setStepAndData("transferEMoneyConfirmation", data);
                    props.setBackSlideEffect("step-slide-left");
                })
                .catch(() => {
                    setSubmitting(false);
                });
        },
    })
)(TransferEMoney);

const MessageActionTypes = {
    FETCH_MESSAGES_START: "FETCH_MESSAGES_START",
    FETCH_MESSAGES_SUCCESS: "FETCH_MESSAGES_SUCCESS",
    FETCH_MESSAGES_FAILURE: "FETCH_MESSAGES_FAILURE",
    UPDATE_MESSAGES_SUCCESS: "UPDATE_MESSAGES_SUCCESS",
    PUSH_MESSAGES_SUCCESS: "PUSH_MESSAGES_SUCCESS",

    FETCH_MESSAGE_START: "FETCH_MESSAGE_START",
    FETCH_MESSAGE_SUCCESS: "FETCH_MESSAGE_SUCCESS",
    FETCH_MESSAGE_FAILURE: "FETCH_MESSAGE_FAILURE",
};

export default MessageActionTypes;

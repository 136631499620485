import { createSelector } from "reselect";

import { PROJECT_STATUS, CLUB_DEAL_STATUS, PROJECT_STATUS_ALLOWED_DETAIL_PAGE } from "constants/status";
import { TENANTS_LIST, USER_ROLES, staticProjectRunningTimeInYears } from "constants/index";

import { selectUserRoles, selectUserDetails, selectSubscription } from "store/user/account/selectors";
import { PROJECT_SELECTOR_LIMITED } from "common/constants";

const uniqBy = require("lodash/uniqBy");

var regexForProjects = new RegExp("[A-Z]{2}-[A-Z]{1,3}-[0-9]{2,}");

const singleProjectDetail = (state) => {
    const singleProjectData = state.project.singleProjectData || [];
    return state.app.tenant == TENANTS_LIST.WATCHVEST
        ? singleProjectData.map((x) => {
              return {
                  ...x,
                  status: {
                      status: PROJECT_STATUS.MARKETPLACE_PAUSED,
                  },
              };
          })
        : singleProjectData;
};

const projectDetails = (state, type) => {
    const projectDetailsData = (type && type == PROJECT_SELECTOR_LIMITED ? state.project.dataLimited : state.project.data) || [];

    return state.app.tenant == TENANTS_LIST.WATCHVEST
        ? projectDetailsData.map((item) => {
              return {
                  ...item,
                  status: {
                      status: PROJECT_STATUS.MARKETPLACE_PAUSED,
                  },
              };
          })
        : projectDetailsData;
};
const projectID = (state) => state.project.selectedProjectID;

export const selectSubscribedProject = createSelector(projectDetails, selectSubscription, (projects, subscribed) => {
    const mapper = [];
    projects.map((project) => {
        subscribed?.LinksProjects?.map?.((item) => {
            project.projectID == item.ProjectID ? mapper.push(project) : "";
        });
    });
    return mapper;
});

export const selectProjectCategorized = createSelector([projectDetails], (projectData) => {
    if (!Array.isArray(projectData)) return { properties: [], clubDeals: [] };
    const obj = {},
        status = PROJECT_STATUS.CLUB_DEAL,
        arr1 = [],
        arr2 = [];
    let index1 = 0,
        index2 = 0;

    for (let i = 0, len = projectData.length; i < len; i++) {
        if (projectData[i].status.status !== status) {
            if (projectData[i]?.type?.type?.includes("Club")) {
                arr2[index2++] = projectData[i];
            } else {
                arr1[index1++] = projectData[i];
            }
        }
    }

    obj.properties = uniqBy(arr1, "projectID");
    obj.clubDeals = uniqBy(arr2, "projectID");

    return obj;
});

export const selectMarketplaceProjectCategorized = createSelector([selectProjectCategorized], (projectData) => {
    return {
        ...projectData,
        clubDeals: projectData?.clubDeals?.filter(
            (item) =>
                item?.status?.status !== PROJECT_STATUS.SILENT_SALE && item?.status?.status !== PROJECT_STATUS.MARKETPLACE_REVIEW
        ),
        properties: projectData?.properties?.filter(
            (item) =>
                item?.status?.status !== PROJECT_STATUS.SILENT_SALE && item?.status?.status !== PROJECT_STATUS.MARKETPLACE_REVIEW
        ),
    };
});

export const selectMarketplaceProject = createSelector([projectDetails], (projectData) =>
    projectData?.filter(
        (item) =>
            item?.status?.status !== PROJECT_STATUS.SILENT_SALE && item?.status?.status !== PROJECT_STATUS.MARKETPLACE_REVIEW
    )
);

export const selectProjectButton = createSelector(projectDetails, (projectData) => {
    const status = PROJECT_STATUS.CLUB_DEAL,
        arr2 = [];
    let index2 = 0;

    for (let i = 0, len = projectData.length; i < len; i++) {
        if (projectData[i].status.status !== status && !projectData[i].type.type.includes("Club")) {
            arr2[index2++] = projectData[i];
        }
    }

    const filter = arr2.map((project) => {
        if (!project.type.type.includes("Club")) {
            return project.type.type;
        }
    });

    return [...new Set(filter)];
});

export const selectProjectTypes = createSelector(projectDetails, (projectData) => {
    const filter = projectData.map((project) => {
        if (project.type.type.includes("Club")) {
            return `${project?.type?.type?.split("-")?.[0]}${
                project?.type?.type?.split("-")?.[1] ? "-" + project?.type?.type?.split("-")?.[1] : ""
            }`.trim();
        } else {
            return project.type.type.trim();
        }
    });
    return [...new Set(filter)];
});

export const selectProjectStatus = createSelector(projectDetails, (projectData) => {
    const status = PROJECT_STATUS.CLUB_DEAL,
        arr2 = [];
    let index2 = 0;

    for (let i = 0, len = projectData.length; i < len; i++) {
        if (projectData[i].status.status !== status && !projectData[i].type.type.includes("Club")) {
            arr2[index2++] = projectData[i];
        }
    }

    const filter = arr2.map((project) => {
        if (!project.type.type.includes("Club")) {
            return project.status.status;
        }
    });

    return [...new Set(filter)];
});

export const marketPlaceProjectIDs = createSelector(projectDetails, (projectData) => {
    const status = [PROJECT_STATUS.MARKETPLACE, PROJECT_STATUS.MARKETPLACE_PAUSED],
        arr2 = [];
    let index2 = 0;

    for (let i = 0, len = projectData.length; i < len; i++) {
        if (status.includes(projectData[i].status.status)) {
            arr2[index2++] = projectData[i];
        }
    }

    const filter = arr2.map((project) => {
        return project.projectID;
    });

    return [...new Set(filter)];
});

export const selectFundingActiveProjectsByStatus = createSelector(projectDetails, (projectData) => {
    if (!Array.isArray(projectData)) return [];

    const arr = [],
        status = PROJECT_STATUS.FUNDING_ACTIVE;
    let index = 0;

    for (let i = 0, len = projectData.length; i < len; i++) {
        if (projectData[i].status.status === status) {
            arr[index++] = projectData[i];
        }
    }

    return arr;
});

export const selectFundingActiveVIPProjectsByStatus = createSelector(projectDetails, (projectData) => {
    if (!Array.isArray(projectData)) return [];

    const arr = [],
        status = [PROJECT_STATUS.FUNDING_ACTIVE, PROJECT_STATUS.VIP_FUNDING, PROJECT_STATUS.ADVERTISING];
    let index = 0;

    for (let i = 0, len = projectData.length; i < len; i++) {
        if (status.find((x) => x === projectData[i].status.status)) {
            arr[index++] = projectData[i];
        }
    }

    return arr;
});

export const selectProjectDetails = createSelector(projectDetails, (projectData) => projectData);

export const selectProjectDetailsForId = createSelector([projectID, singleProjectDetail], (projectID, projectData) => {
    const data = projectData?.find(
        (item) => item.projectID === projectID && PROJECT_STATUS_ALLOWED_DETAIL_PAGE[item?.status?.status]
    );
    return Object.assign({}, data);
});

export const selectProjectWithProjectId = createSelector(
    [(state, projectId) => projectId, (state) => state.project.data],
    (id, projectData) => {
        const data = projectData?.find((item) => item.projectID === id);
        return Object.assign({}, data);
    }
);

const getInvestmentSum = (state) => state.investment.stepData.investSum.sum;

export const selectProjectCostBenefits = createSelector(
    [selectProjectDetailsForId, selectUserDetails],
    (projectData, userData) => {
        const obj = projectData.financials.costsAndBenefits,
            userTipster = userData?.Tipster ?? {};
        const yearValue =
            staticProjectRunningTimeInYears[projectData?.projectID] ||
            (parseInt(obj.runningTimeInYears) === 0 ? 1 : parseInt(obj.runningTimeInYears)) ||
            15;
        Object.keys(obj).map((key) => {
            obj[key] = parseFloat(obj[key] && obj[key].toFixed(2));
        });

        obj.tipsterCommission =
            userTipster?.WasReferredByTheTipster !== ""
                ? userTipster?.LinkProvision
                    ? userData?.Investments?.length > 0
                        ? userTipster?.LinkProvision?.FollowupPercentage
                        : userTipster?.LinkProvision?.InitialInvestmentPercentage
                    : 4
                : 0;

        obj.oneTimeCosts = obj.oneTimePlacementFee + obj.oneTimeCostsFi;
        obj.costPerYear = obj.runningCostsFiPa + obj.runningPlacementFeePa;
        obj.value1 = obj.oneTimeCosts + yearValue * obj.costPerYear;
        const temp = obj.value1 / yearValue;
        obj.value2 = parseFloat(temp && temp.toFixed(2));
        obj.value3 = obj.oneTimeCosts + obj.costPerYear;
        obj.value4 = obj.costPerYear;
        return Object.assign({}, obj);
    }
);

export const selectCalculatedCostBenefitsValues = createSelector(
    [selectProjectCostBenefits, getInvestmentSum, selectProjectDetailsForId],
    (costBenefits, sum, projectData) => {
        const {
            monetaryBenefitsToInstitute,
            monetaryBenefitsToBroker,
            tipsterCommission,
            oneTimePlacementFee,
            oneTimeGrants,
            oneTimeCostsFi,
            runningPlacementFeePa,
            runningGrantsPa,
            runningCostsFiPa,
            value1,
        } = costBenefits;

        const runningTimeInYears =
            staticProjectRunningTimeInYears[projectData?.projectID] ||
            (parseInt(projectData?.financials?.costsAndBenefits?.runningTimeInYears) === 0
                ? 1
                : parseInt(projectData?.financials?.costsAndBenefits?.runningTimeInYears)) ||
            15;
        const yearValue = runningTimeInYears;
        const yearSumValue = runningTimeInYears * 100;

        const obj = {
            monetaryBenefitsToInstituteValue: (sum / 100) * monetaryBenefitsToInstitute,
            monetaryBenefitsToBrokerValue: (sum / 100) * monetaryBenefitsToBroker,
            tipsterCommissionValue: (sum / 100) * tipsterCommission,
            oneTimePlacementFeeValue: (sum / 100) * oneTimePlacementFee,
            oneTimeGrantsValue: (sum / 100) * oneTimeGrants,
            oneTimeCostsFiValue: (sum / 100) * oneTimeCostsFi,
            runningPlacementFeePaValue: (sum / 100) * runningPlacementFeePa,
            runningGrantsPaValue: (sum / 100) * runningGrantsPa,
            runningCostsFiPaValue: (sum / 100) * runningCostsFiPa,
        };
        obj.oneTimeCostsValue = obj.oneTimePlacementFeeValue + obj.oneTimeCostsFiValue;
        obj.costPerYearValue = obj.runningCostsFiPaValue + obj.runningPlacementFeePaValue;
        obj.value1 = obj.oneTimeCostsValue + yearValue * obj.costPerYearValue;
        obj.value2 = (sum / yearSumValue) * value1;
        obj.value3 = obj.oneTimeCostsValue + obj.costPerYearValue;
        obj.value4 = obj.costPerYearValue;
        return Object.assign({}, obj);
    }
);

export const selectProjectIDNameMap = createSelector(
    projectDetails,
    selectUserRoles,
    selectUserDetails,
    (projects, roles, user) => {
        const mapper = {};
        const isUserHasClub =
            Array.isArray(roles) &&
            !!roles.find((role) => role === USER_ROLES.CLUBDEALS) &&
            user.StatusClubDeal === CLUB_DEAL_STATUS.COMPLETED;
        for (let i = 0, len = projects.length; i < len; i++) {
            // eslint-disable-next-line sonarjs/no-collapsible-if
            if (regexForProjects.test(projects[i].projectID)) {
                if (!projects[i].projectID.startsWith("TEST")) {
                    if (projects[i].projectID.startsWith("CD") && isUserHasClub) {
                        mapper[projects[i].projectID] = projects[i].title;
                        // eslint-disable-next-line sonarjs/no-duplicated-branches
                    } else if (!projects[i].projectID.startsWith("CD")) {
                        mapper[projects[i].projectID] = projects[i].title;
                    }
                }
            }
        }
        return mapper;
    }
);

export const selectProjectType1to8M = createSelector(selectProjectDetailsForId, (project) =>
    project?.typeInvestmentProduct?.includes("-1mto8m-")
);

import TenantActionTypes from "./types";

export const fetchTenantsStart = (offset, limit, result) => ({
    type: TenantActionTypes.FETCH_TENANTS_START,
    payload: { offset, limit, result },
});

export const fetchTenantsSuccess = (payload) => ({
    type: TenantActionTypes.FETCH_TENANTS_SUCCESS,
    payload: payload,
});

export const fetchTenantsFailure = (error) => ({
    type: TenantActionTypes.FETCH_TENANTS_FAILURE,
    payload: error,
});

export const fetchTenantUserStart = (offset, limit) => ({
    type: TenantActionTypes.FETCH_TENANT_USERS_START,
    payload: { offset, limit },
});

export const fetchTenantUserSuccess = (payload) => {
    return {
        type: TenantActionTypes.FETCH_TENANT_USERS_SUCCESS,
        payload: payload,
    };
};

export const fetchTenantUserFailure = (error) => ({
    type: TenantActionTypes.FETCH_TENANT_USERS_FAILURE,
    payload: error,
});

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withFormik } from "formik";
import { compose } from "recompose";
import cn from "classnames";
import { selectUserMinPayInAmount } from "store/user/account/selectors";
import { fetchUserMinPayInAmountStart } from "store/user/account/actions";
// import { toGermanCurrency, toEnglishCurrency } from "utils";
import TopGreyDiv from "../components/topGreyDiv";
// import AccountDetailsBlock from '../components/accountDetailsBlock'
import { sendFormError } from "i18n/messages/errors";
import Input from "components/Input";
import CurrencyInput from "components/CurrencyInput";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import { mangopayPayIn } from "api";
import { depositAmount as amountMessages } from "i18n/messages/formElements";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";

// import { numberRegexAcceptDotAndComma } from "utils/validationRegex";

const messages = {
    payInTitle: "ewallet_payin",
    depositIntoEwallet: "deposit_into_ewallet",
    depositDescription: "payIn_deposit_description",
    depositAmountToEwallet: "deposit_amount_to_ewallet",
    paymentMethod: "payment_method",
    bankTransfer: "bank_transfer",
    howMuchDeposit: "how_much_deposit",
    yourTransferDetails: "wallet_transfer_details",
    note: "paying_into_specified_account",
    enterAmount: "enter_amount",
    depositAmount: "deposit_amount",
    blockedPayInErrorMessage: "cannot_make_payment",
};

const PayIn = ({
    setShowDate,
    setCloseTitle,
    data,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    submitForm,
    validateForm,
    setFieldTouched,
    setFieldValue,
    setStep,
    minPayInAmount,
    status,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    // useEffect(() => {
    // 	fetchUserMinPayInAmountStart();
    // }, [fetchUserMinPayInAmountStart]);

    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.payInTitle));
    }, []);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(
                formatMessage(
                    status.requestError === "mangopay_beneficiary_account_not_available"
                        ? messages.mangopayBeneficiaryAccountNotAvailable
                        : status.requestError === "mangopay_in_flows_blocked"
                        ? messages.blockedPayInErrorMessage
                        : status.requestError === "mangopay_invalid_min_payin_amount"
                        ? amountMessages["minimumDepositAmountFifty"]
                        : status.requestError === "mangopay_invalid_max_payin_amount"
                        ? amountMessages["maximumDepositAmount"]
                        : status.requestError === "mangopay_payin_several_pendings"
                        ? amountMessages["severalDeposit"]
                        : sendFormError
                )
            );
        }
    }, [status]);

    useEffect(() => {
        const minAmoutPayIn = (minPayInAmount.status && minPayInAmount.minPayment) || 1;

        setFieldValue("minDepositBalance", parseFloat(minAmoutPayIn));
    }, [minPayInAmount]);

    const setCurrencyData = (formattedAmount, resetAmount) => {
        setFieldValue("intlAmount", formattedAmount);
        setFieldValue("payInAmount", resetAmount);
        setFieldTouched("intlAmount", true);
    };

    return (
        <div className={`pay-in ${backSlideEffect}`}>
            <TopGreyDiv icon amount={data.payInWalletBalance} />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3 className="mb-6">{formatMessage(messages.depositIntoEwallet)}</h3>
                <p className="text-16 mb-8">{formatMessage(messages.depositDescription)}</p>
                <span className="text-14 fw-600 d-inline-block mb-3">{formatMessage(messages.paymentMethod)}</span>

                <input id="minDepositBalance" name="minDepositBalance" type="hidden" value={values.minDepositBalance} />

                <Input
                    id="transferType"
                    type="text"
                    name="transferType"
                    className="mb-0"
                    readOnly
                    inputClassName="text-input form-item bg-lighter-grey secondary-light-color"
                    value={formatMessage(messages.bankTransfer)}
                />
                <span className="text-14 mt-8 fw-600 d-inline-block mb-3">{formatMessage(messages.howMuchDeposit)}</span>
                <CurrencyInput
                    className="mb-5"
                    inputSymbolClass="black-color"
                    inputClassName="text-input form-item"
                    id="intlAmount"
                    name="intlAmount"
                    hiddenInputName="payInAmount"
                    placeholder={formatMessage(messages.enterAmount)}
                    onCurrencyInputChange={(formattedAmount, resetAmount) => setCurrencyData(formattedAmount, resetAmount)}
                    values={values}
                    onBlur={handleBlur}
                    errorMessage={
                        touched.intlAmount && errors.intlAmount ? `${formatMessage(amountMessages[errors.intlAmount])}` : null
                    }
                    error={touched.intlAmount && errors.intlAmount}
                />
                {/* <div className="flex justify-between mt-15 mb-8">
                <span className="text-16 fw-500 secondary-light-color">Ihr persönliches Einzahlkonto</span>
                <span className="text-16 fw-500 primary-color">Edit</span>
            </div>
            <AccountDetailsBlock className="mb-25" /> */}
                {requestError && <span className="error pl-0">{requestError}</span>}
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setStep("walletOverview");
                            setBackSlideEffect("step-slide-right");
                        }}
                        disable={isSubmitting}
                    />
                    <NextButton
                        className={cn({
                            "mw-fit": true,
                            "disable-grey": isSubmitting || errors.intlAmount || errors.email,
                        })}
                        disable={isSubmitting || errors.intlAmount || errors.email}
                        onClick={submitForm}
                        title={formatMessage(messages.depositAmount)}
                        isLoading={isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    minPayInAmount: selectUserMinPayInAmount,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserMinPayInAmountStart: () => dispatch(fetchUserMinPayInAmountStart()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: () => ({
            payInAmount: 0.0,
            intlAmount: "",
            minDepositBalance: 1,
        }),
        validate: (values) => {
            const errors = {};
            if (!values.payInAmount) {
                errors.intlAmount = "amountRequired";
            } else if (values.payInAmount < 50) {
                errors.intlAmount = "minimumDepositAmountFifty";
            } else if (values.payInAmount > 1000000) {
                errors.intlAmount = "maximumDepositAmount";
            }
            // else if (values.payInAmount < values.minDepositBalance) {
            // 	if (values.minDepositBalance === 50) {
            // 		errors.intlAmount = "minimumDepositAmountFifty";
            // 	} else {
            // 		errors.intlAmount = "minimumDepositAmount";
            // 	}
            // }

            return errors;
        },

        validateOnBlur: true,
        validateOnChange: true,
        handleSubmit: (values, { props, setSubmitting, setStatus }) => {
            setSubmitting(true);
            // setErrors({ requestError: "mangopay_beneficiary_account_not_available" });
            mangopayPayIn(values.payInAmount, "bankWire")
                .then((res) => {
                    setSubmitting(false);
                    if (res.data && res.data.errors && res.data.errors[0]) {
                        setStatus({ requestError: res.data.errors[0].message });
                    }

                    if (!res || res.errors || res.data.errors || res.data.data.errors) return;

                    const data = res.data.data.mangopayPayIn.payment;

                    props.setStepAndData("payInOverview", {
                        depositAmount: data.Amount,
                        ...data.LinkBankAccount,
                        referenceNumber: data.ReferenceNumber,
                    });
                    props.setBackSlideEffect("step-slide-left");
                })
                .catch(() => {
                    setSubmitting(false);
                });
        },
    })
)(PayIn);

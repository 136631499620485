import { put, takeLatest, call, all } from "redux-saga/effects";

import { fetchDashboardOrders, userDashboardDetails, fetchDashboardInvestments } from "api";

import DashboardActionTypes from "./types";

import {
    fetchDashboardDetailsSuccess,
    fetchDashboardDetailsFailure,
    fetchUserDashboardDetailsSuccess,
    fetchUserDashboardDetailsFailure,
} from "./actions";

function* fetchDashboardDetailsAsync() {
    try {
        let user = {};

        const ordersResponse = yield call(fetchDashboardOrders);
        const orders = ordersResponse?.data?.data?.dashboardOrders.orders;

        const investmentResponse = yield call(fetchDashboardInvestments);
        const investments = investmentResponse?.data?.data?.dashboardInvestments.investments;
        user = {
            LinkInvestments: investments,
            LinkOrders: orders,
        };

        yield put(fetchDashboardDetailsSuccess(user));
    } catch (err) {
        yield put(fetchDashboardDetailsFailure(err));
    }
}

function* fetchUserDashboardDetailsAsync() {
    try {
        const response = yield call(userDashboardDetails);
        const { dashboard } = response.data.data.user.user;

        /**
      Commenting this out because after login we want user to submit Tipster by manually all their se
    */
        /**
      let tipsterId = typeof window !== "undefined" && require("js-cookie").get("tipsterId");
      if (tipsterId && dashboard.tipsterCompleted === "false") {
        const res = yield call(setTipsterId, { tipster: tipsterId });
        if (res.data.data) {
          dashboard.tipsterCompleted = "true";
          dashboard.tipsterStatus = "hide";
          yield put(setTipsterData({ ID: tipsterId }));
        }
        require("js-cookie").remove("tipsterId");
      }
    */
        yield put(fetchUserDashboardDetailsSuccess(dashboard));
    } catch (err) {
        yield put(fetchUserDashboardDetailsFailure(err));
    }
}

export function* fetchDashboardDetailsStart() {
    yield takeLatest(DashboardActionTypes.FETCH_DASHBOARD_DETAILS_START, fetchDashboardDetailsAsync);
}

export function* fetchUserDashboardDetailsStart() {
    yield takeLatest(DashboardActionTypes.FETCH_USER_DASHBOARD_DETAILS_START, fetchUserDashboardDetailsAsync);
}

export function* dashboardSagas() {
    yield all([call(fetchDashboardDetailsStart), call(fetchUserDashboardDetailsStart)]);
}

const NotificationActionTypes = {
    FETCH_NOTIFICATIONS_START: "FETCH_NOTIFICATIONS_START",
    FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
    FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",

    FETCH_NOTIFICATION_START: "FETCH_NOTIFICATION_START",
    FETCH_NOTIFICATION_SUCCESS: "FETCH_NOTIFICATION_SUCCESS",
    FETCH_NOTIFICATION_FAILURE: "FETCH_NOTIFICATION_FAILURE",

    PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
};

export default NotificationActionTypes;

const CrmActionTypes = {
    UPDATE_DATA: "UPDATE_DATA",
    SET_PREFILL_NUMBER: "SET_PREFILL_NUMBER",
    SET_EXTRA_DETAIL_FOR_CREATE_LEAD: "SET_EXTRA_DETAIL_FOR_CREATE_LEAD",
    ADD_ACTIVITY: "ADD_ACTIVITY",
    SET_ACTIONS_DATA: "SET_ACTIONS_DATA",
    SET_SELECTED_ACTION: "SET_SELECTED_ACTION",
    FETCH_ALL_INFO_FOR_AN_INVESTMENT: "FETCH_ALL_INFO_FOR_AN_INVESTMENT",
    UPDATE_INVESTMENT_DATA: "UPDATE_INVESTMENT_DATA",
    SET_INVESTMENT_ACTIONS_DATA: "SET_INVESTMENT_ACTIONS_DATA",
    FETCH_SELECTED_CALL_LOG: "FETCH_SELECTED_CALL_LOG",
    SET_SELECTED_CALL_LOG: "SET_SELECTED_CALL_LOG",
    UPDATE_USER_ACTIVITY: "UPDATE_USER_ACTIVITY",
    FETCH_USER_CALL_LOGS: "FETCH_USER_CALL_LOGS",
    SET_CREATE_NEW_USER_STATUS: "SET_CREATE_NEW_USER_STATUS",
    FETCH_ALL_INFO_FOR_A_USER: "FETCH_ALL_INFO_FOR_A_USER",
    DELETE_ACTIVITY: "DELETE_ACTIVITY",
    REMOVE_Email: "REMOVE_Email",
    ADD_EMAIL: "ADD_EMAIL",
};

export default CrmActionTypes;

import { put, all, takeLatest, call } from "redux-saga/effects";
import AppActionTypes from "./types";

import { fetchTenantSuccess, fetchTenantFailure } from "./actions";
import { getTenant } from "api";

function* fetchTenantAsync() {
    try {
        const res = yield call(getTenant);
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const tenant = res.data.data.tenant.tenant;
        yield put(fetchTenantSuccess({ tenant }));
    } catch (err) {
        yield put(fetchTenantFailure(err));
    }
}

function* fetchAapStart() {
    yield takeLatest(AppActionTypes.FETCH_TENANT_START, fetchTenantAsync);
}

export function* appSagas() {
    yield all([call(fetchAapStart)]);
}

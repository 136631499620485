import BrokerActionTypes from "./types";


export const updateBrokerOnboardingAdditional = (payload) => ({
  type: BrokerActionTypes.UPDATE_BROKER_ONBOARDING_ADDITIONAL,
  payload
});



export const updateBrokerAccountAdditional= (payload) => ({
  type: BrokerActionTypes.UPDATE_BROKER_ACCOUNT_ADDITIONAL,
  payload
});

export const setBrokerTotalUsers = (totalUsers) => ({
  type: BrokerActionTypes.SET_BROKER_TOTAL_USERS,
  payload: totalUsers,
});

export const fetchBrokersStart = (offset, limit) => ({
  type: BrokerActionTypes.FETCH_BROKERS_START,
  payload: {
    offset,
    limit,
  },
});

export const fetchBrokersSuccess = (usersData) => ({
  type: BrokerActionTypes.FETCH_BROKERS_SUCCESS,
  payload: usersData,
});

export const fetchBrokersFailure = (error) => ({
  type: BrokerActionTypes.FETCH_BROKERS_FAILURE,
  payload: error,
});

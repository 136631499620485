import React, { useState } from "react";
import { connect } from "react-redux";

import { selectAdequacy } from "store/user/adequacy/selectors";
import { setInvestmentStepData } from "store/user/investment/actions";
import { userDashboardDetailsSuccess } from "store/user/account/actions";
import { setAdequacyStart } from "store/user/adequacy/actions";

import Start from "./Start";
import UserData from "./UserData";
import MobileData from "./MobileData";
import { RenderAdequacyStep } from "components/AdequacyModal";
import TermsAndCondition from "./TermsAndCondition";
import WaitingForActivation from "./WaitingForActivation";
import Pending from "./Pending";
import Blocked from "./Blocked";
import Failed from "./Failed";
import NotRegistered from "./NotRegistered";
import Success from "./Success";

const SecondaryMarketStep = ({
    setCloseTitle,
    step,
    setStep,
    isKycPending,
    isKycCompleted,
    isMobileValidated,
    isAdequacyCompleted,
    onCloseButtonClick,
    isAccepted,
    adequacyStep,
    setInvestmentStepData,
    setAdequacyStart,
    userDashboardDetailsSuccess,
    openEuroWallet,
    isPropertyDetail,
    mobileSuccess,
    open,
    backSlideEffect,
    setBackSlideEffect,
}) => {
    const [isMobileSucceed, setMobileSucceed] = useState(false);

    const renderStep = {
        start: (
            <Start
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setStep={setStep}
                isKycPending={isKycPending}
                isKycCompleted={isKycCompleted}
                isMobileValidated={isMobileValidated === true || isMobileSucceed === true ? true : false}
                isAdequacyCompleted={isAdequacyCompleted}
                onCloseButtonClick={onCloseButtonClick}
                openEuroWallet={openEuroWallet}
                open={open}
            />
        ),
        userData: (
            <UserData
                open={open}
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setStep={setStep}
                onCloseButtonClick={onCloseButtonClick}
            />
        ),
        mobileData: (
            <MobileData
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setStep={setStep}
                mobileSuccess={() => {
                    mobileSuccess();
                    setMobileSucceed(true);
                }}
            />
        ),
        adequacyData: (
            <RenderAdequacyStep
                onAdequacyBack={() => {
                    setBackSlideEffect("step-slide-right");
                    setStep("start");
                }}
                isAccepted={isAccepted}
                step={adequacyStep}
                isWithSecondaryMarket
                kycCompleted={isKycCompleted}
                adequacyCompleted={isAdequacyCompleted}
                setInvestmentStepData={setInvestmentStepData}
                setAdequacyStart={setAdequacyStart}
                userDashboardDetailsSuccess={userDashboardDetailsSuccess}
                open={open}
            />
        ),
        termsAndCondition: (
            <TermsAndCondition
                open={open}
                backSlideEffect={backSlideEffect}
                submitSuccess={() => {
                    setBackSlideEffect("step-slide-left");
                    isPropertyDetail ? setStep("waitingForActivation") : setStep("success");
                }}
            />
        ),
        waitingForActivation: (
            <WaitingForActivation
                backSlideEffect={backSlideEffect}
                isPropertyDetail={isPropertyDetail}
                onCloseButtonClick={onCloseButtonClick}
            />
        ),
        pending: <Pending onCloseButtonClick={onCloseButtonClick} />,
        blocked: <Blocked onCloseButtonClick={onCloseButtonClick} />,
        failed: <Failed onCloseButtonClick={onCloseButtonClick} />,
        notRegistered: <NotRegistered setCloseTitle={setCloseTitle} open={open} />,
        success: (
            <Success backSlideEffect={backSlideEffect} openEuroWallet={openEuroWallet} onCloseButtonClick={onCloseButtonClick} />
        ),
    };

    return renderStep[step] || <Start setStep={setStep} />;
};

const mapStateToProps = (state) => ({
    adequacyStep: selectAdequacy(state).step,
    isAccepted: selectAdequacy(state).isAccepted,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryMarketStep);

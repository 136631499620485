import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { selectPlatformSourceFromApp } from "store/app/selectors";

import { sideModalScrollHidden } from "utils";
import Close from "components/Close";
import ArrowRight from "assets/images/svg/arrow-right.svg";
import { useSideModalScrollListener, useUtils } from "hooks";

const SideModal = (props) => {
    const isApp = useUtils().isPlatformSourceFromApp;
    useSideModalScrollListener(props, isApp);

    useEffect(() => {
        isApp && sideModalScrollHidden(props.open);

        const spaceClass = window.outerWidth < 992 ? "ml-8" : "ml-6";

        if (jQuery(".side-modal.opened .prev").length > 0) {
            jQuery(".side-modal.opened .modal-title").addClass(spaceClass);
        } else {
            jQuery(".side-modal.opened .modal-title").removeClass(spaceClass);
        }

        if (props.isScrollTop) {
            setTimeout(() => {
                const scrollElement = document.querySelectorAll(".common-side-modal.opened");
                scrollElement[0]?.scrollTo(0, 0);
            }, 100);
        }
    }, [props.open]);

    return (
        <div
            className={`${props.className || ""} side-modal common-side-modal shadow${props.open ? " opened" : " closed"}`}
            style={props.style}
        >
            <div className="close-container d-flex align-center justify-between">
                <div className="d-flex w-100 justify-between">
                    {props.isBackArrowOnTop && (
                        <div className="back-arrow-top">
                            <span
                                className="back-arrow"
                                onClick={props.onBackButtonClick ? props.onBackButtonClick : props.onCloseButtonClick}
                            >
                                <ArrowRight />
                            </span>
                        </div>
                    )}
                    {(props.heading || props.isHeading) && (
                        <h4 className={`flex-1 modal-title my-0 ml-1 ${props?.titleClassName || ""}`}>{props.heading}</h4>
                    )}
                    <Close
                        className={`close-button fw-600 ${props?.closeButtonClassName}`}
                        onCloseButtonClick={props.onCloseButtonClick}
                    />
                </div>

                {props.headingRight ? (
                    <span className="text-12 another-grey-color" onClick={props.onHeadingRightClick}>
                        {props.headingRight}
                    </span>
                ) : null}
                {props.timer ? props.timer : null}
            </div>
            <div className={`${props.wrapClassName || ""} side-modal-wrap flex`}>
                <div className="modal-body">
                    <div className={`${props.containerClassName || ""} side-modal-container`}>{props.children}</div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(SideModal);

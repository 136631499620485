import React, { useEffect } from "react";
import NextButton from "components/NextButton";
import TopGreyDiv from "../components/topGreyDiv";
import AccountDetailsBlock from "../components/accountDetailsBlock";
import { getRegionFormattedDate } from "utils";
import MoneyCurrency from "assets/images/svg/money-currency.svg";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import CustomFormatNumber from "components/CustomFormatNumber";

const Row = ({ left, right }) => (
    <div className="flex text-14 justify-between mb-3">
        <label className="another-grey-color d-inline-block mr-2">{left}</label>
        <span className="fw-600">{right}</span>
    </div>
);

const messages = {
    payOutTitle: "wallet_payout_title",
    heading: "payout_payment_successfully_submitted",
    yourPayoutAccount: "your_payout_account_with_colon",
    transactionInformation: "transaction_information",
    payoutAmount: "payout_amount",
    withdrawalID: "withdrawal_id",
    date: "date",
    buttonText: "to_overview",
};

const PayOutOverview = ({ data, setShowDate, setCloseTitle, setStep, stepData, backSlideEffect = "", setBackSlideEffect }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const currentDate = getRegionFormattedDate(Date.now());
    const paymentAmount = stepData && stepData.paymentAmount ? stepData.paymentAmount : 0.0;

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.payOutTitle));
    }, []);

    return (
        <div className={`transfer-e-money-success ${backSlideEffect}`}>
            <TopGreyDiv icon />
            <div className="px-5 py-15 mw-450 mx-auto">
                <h3 className="mt-0">{formatMessage(messages.heading)}</h3>
                <span className="text-16 d-inline-block mt-5 mb-8 fw-500 secondary-light-color">
                    {formatMessage(messages.transactionInformation)}
                </span>
                <div className="overview-details d-flex align-start p-4 mb-10">
                    <div className="round-holder mr-4 bg-dark">
                        <MoneyCurrency width="25" height="25" />
                    </div>
                    <div className="w-100">
                        <Row left={formatMessage(messages.withdrawalID)} right={stepData?.bankAccountID ?? ""} />
                        <Row left={formatMessage(messages.date)} right={currentDate} />
                        {/* <Row left="EURO-Guthaben zuvor" right="600 €" /> */}
                        <Row left={formatMessage(messages.payoutAmount)} right={<CustomFormatNumber value={paymentAmount} />} />
                    </div>
                </div>

                <span className="text-16 d-inline-block mb-8 fw-500 secondary-light-color">
                    {formatMessage(messages.yourPayoutAccount)}
                </span>
                <AccountDetailsBlock className="mb-10" data={data.bankAccount} />

                <div className="bottom-bar-container">
                    <NextButton
                        title={formatMessage(messages.buttonText)}
                        onClick={() => {
                            setStep("walletOverview");
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PayOutOverview;

import ProjectActionTypes from "./types";

export const fetchProjectsStart = (result) => ({
    type: ProjectActionTypes.FETCH_PROJECTS_START,
    payload: { result },
});

export const fetchProjectsSuccess = (projectsData) => ({
    type: ProjectActionTypes.FETCH_PROJECTS_SUCCESS,
    payload: projectsData,
});

export const fetchProjectsFailure = (error) => ({
    type: ProjectActionTypes.FETCH_PROJECTS_FAILURE,
    payload: error,
});

export const fetchProductsStart = () => ({
    type: ProjectActionTypes.FETCH_PRODUCTS_START,
});

export const fetchProductsSuccess = (productData) => ({
    type: ProjectActionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: productData,
});

export const fetchProductsFailure = (error) => ({
    type: ProjectActionTypes.FETCH_PRODUCTS_FAILURE,
    payload: error,
});

export const fetchProductCatalogStart = () => ({
    type: ProjectActionTypes.FETCH_PRODUCT_CATALOG_START,
});

export const fetchProductCatalogSuccess = (productCatalogData) => ({
    type: ProjectActionTypes.FETCH_PRODUCT_CATALOG_SUCCESS,
    payload: productCatalogData,
});

export const fetchProductCatalogFailure = (error) => ({
    type: ProjectActionTypes.FETCH_PRODUCT_CATALOG_FAILURE,
    payload: error,
});

import React from "react";
import OtpInput from "react-otp-input";

const OTPInput = ({
    errorCode = "",
    value = "",
    onChange = () => {},
    isSubmitting,
    errorMessage,
    shouldAutoFocus = false,
    numInputs = 6,
    isShowAnimation = true,
}) => {
    return (
        <OtpInput
            shouldAutoFocus={shouldAutoFocus}
            containerStyle={`otp-input justify-between mb-3 ${
                (errorCode === "invalid_input" || errorMessage) && isShowAnimation ? "invalid-sms-code-efffect" : ""
            } ${(errorCode === "invalid_input" || errorMessage) && !isShowAnimation ? "invalid-sms-code-error" : ""}`}
            value={value}
            onChange={(otp) => {
                if (!isSubmitting) onChange(otp);
            }}
            numInputs={numInputs}
            inputType="number"
            renderInput={(props) => <input {...props} pattern="\d*" />}
        />
    );
};

export default OTPInput;

import AdequacyActionTypes from "./types";
import AccountActionTypes from "../account/types";

const INITIAL_ADEQUACY_TEST_DATA = {
    step: 1,
    survey_first_step: {},
    survey_second_step: {
        investment_funds: {},
        shares: {},
        sharing_rights: {},
        subordinated: {},
        bonds: {},
    },
    survey_third_step: {
        securities: {},
        transactions: {},
    },
};

const INITIAL_STATE = {
    adequacyData: INITIAL_ADEQUACY_TEST_DATA,
    adequacyRenewData: INITIAL_ADEQUACY_TEST_DATA,
    adequacyBackup: INITIAL_ADEQUACY_TEST_DATA,
    fetchAdequacyData: {},
    error: "",
    isLoading: false,
};

const adequacyReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        //Combined as return is same, split if need to change singularly.
        case AdequacyActionTypes.SET_ADEQUACY_START:
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_START:
        case AdequacyActionTypes.FETCH_ADEQUACY_START:
            return {
                ...state,
                isLoading: true,
            };
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_SUCCESS:
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case AdequacyActionTypes.SET_ADEQUACY_REVOKE:
            return {
                ...state,
                adequacyData: { ...INITIAL_ADEQUACY_TEST_DATA, ...payload },
            };
        case AdequacyActionTypes.SET_ADEQUACY_SUCCESS:
            return {
                ...state,
                adequacyBackup: !payload.step ? { ...state.adequacyBackup, ...payload } : { ...state.adequacyBackup },
                adequacyRenewData:
                    payload.step === 2 || payload.clearAll
                        ? { ...state.adequacyData, ...payload }
                        : { ...INITIAL_ADEQUACY_TEST_DATA, ...payload },
                adequacyData:
                    payload.step === 2 || payload.clearAll
                        ? { ...INITIAL_ADEQUACY_TEST_DATA, ...payload }
                        : { ...state.adequacyData, ...payload },
                isLoading: false,
            };

        case AdequacyActionTypes.FETCH_ADEQUACY_SUCCESS:
            return {
                ...state,
                fetchAdequacyData: payload,
                isLoading: false,
            };
        //Combined as return is same, split if need to change singularly.
        case AdequacyActionTypes.SET_ADEQUACY_FAILURE:
        case AdequacyActionTypes.FETCH_ADEQUACY_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
            };

        case AccountActionTypes.LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default adequacyReducer;

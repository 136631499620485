import UserActionTypes from "./types";

export const setTotalUsers = (totalUsers) => ({
    type: UserActionTypes.SET_TOTAL_USERS,
    payload: totalUsers,
});

export const fetchUsersStart = (offset, limit) => ({
    type: UserActionTypes.FETCH_ADMIN_USERS_START,
    payload: {
        offset,
        limit,
    },
});

export const fetchUsersSuccess = (usersData) => ({
    type: UserActionTypes.FETCH_ADMIN_USERS_SUCCESS,
    payload: usersData,
});

export const fetchUsersFailure = (error) => ({
    type: UserActionTypes.FETCH_ADMIN_USERS_FAILURE,
    payload: error,
});

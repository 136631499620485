// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";

function initiateSentry() {
    const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

    Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        enabled: process.env.NODE_ENV === "production",
        release: process.env.SENTRY_RELEASE,
        environment: process.env.SENTRY_ENVIRONMENT,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}

// Listening if user has already accepted the consent
const isCookieConsentAccepted = !!(Cookies.get("cookiefirst-consent") && localStorage.getItem("cookiefirst-consent"));
if (isCookieConsentAccepted) {
    initiateSentry();
} else {
    //Listening the event when user accept the consent.
    window.addEventListener("cf_consent", initiateSentry);
}

import TokenActionTypes from "./types";

export const fetchTokenStart = (offset, limit) => ({
    type: TokenActionTypes.FETCH_TOKEN_START,
    payload: { offset, limit },
});

export const fetchTokenSuccess = (payload) => ({
    type: TokenActionTypes.FETCH_TOKEN_SUCCESS,
    payload: payload,
});

export const fetchTokenFailure = (error) => ({
    type: TokenActionTypes.FETCH_TOKEN_FAILURE,
    payload: error,
});

export const fetchProjectTokenStart = (payload) => ({
    type: TokenActionTypes.FETCH_PROJECT_TOKEN_START,
    payload,
});

export const fetchProjectTokenSuccess = (payload) => ({
    type: TokenActionTypes.FETCH_PROJECT_TOKEN_SUCCESS,
    payload: payload,
});

export const fetchProjectTokenFailure = (error) => ({
    type: TokenActionTypes.FETCH_PROJECT_TOKEN_FAILURE,
    payload: error,
});

const Info = ({
    title,
    content,
    stepHeading,
    labelText,
    image,
    imageClass,
    bottomContent,
    isButtonSecondary,
    buttonText,
    onImageClick,
    onClick,
    backSlideEffect = ""
}) => {
    return (
        <div className={backSlideEffect}>
            <h3 className="mb-6">{title}</h3>
            <p className="text-16 mb-6">{content}</p>
            <h4 className="mb-6">{stepHeading}</h4>
            <span className="text-14">{labelText}</span>
            <img
                className={`mb-6 cursor-pointer mw-100 ${imageClass ? imageClass : ""}`}
                onClick={() => (onImageClick ? onImageClick() : null)}
                src={image}
                alt=""
            />
            {bottomContent && <p className="text-16 mb-6">{bottomContent}</p>}
            <button className={`mw-100 ${isButtonSecondary ? "secondary" : ""}`} onClick={onClick}>
                {buttonText}
            </button>
        </div>
    );
};

export default Info;

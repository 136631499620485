import React from "react";
import ToolTip from "components/ToolTip";

const TwoCellRow = ({
    left = "",
    right = "",
    containerStyle = {},
    rClick = () => {},
    lClick = () => {},
    removeContainerClass = false,
    containerClassname = "",
    lClassname = "",
    removeLClass = false,
    removeRClass = false,
    rClassname = "",
    renderRight,
    renderLeft,
}) => (
    <div style={containerStyle} className={`${removeContainerClass ? "" : "flex justify-between"} ${containerClassname}`}>
        {typeof renderLeft === "function" ? (
            renderLeft()
        ) : (
            <label onClick={lClick} className={`${removeLClass ? "" : "fw-400"} ${lClassname}`}>
                {left}
            </label>
        )}
        {typeof renderRight === "function" ? (
            renderRight()
        ) : (
            <span onClick={rClick} className={`${removeRClass ? "" : "text-16 fw-600"} ${rClassname}`}>
                {right}
            </span>
        )}
        <ToolTip id="two-cell-row" />
    </div>
);

export default TwoCellRow;

import { takeLatest, call, put, all } from "redux-saga/effects";

import KYCActionTypes from "./types";
import {
    setKYCSuccess,
    setKYCFailure,
    fetchKYCSuccess,
    fetchKYCFailure,
    fetchPostIdentSuccess,
    fetchIDnowSuccess,
    fetchIDnowFailure,
    fetchPostIdentFailure,
    fetchReKycSuccess,
    fetchReKycFailure,
    fetchKycFromProviderSuccess,
    fetchKycFromProviderFailure,
    setReKycSuccess,
    setReKycFailure,
    fetchKYCStart,
    fetchKycFromProviderStart,
    fetchIDnowStart,
    fetchPostIdentStart,
    fetchReKycStart,
    setReKycStart,
    fetchKYCSuccessFromAdmin,
    fetchKYCFailureFromAdmin,
    fetchKycServerError,
} from "./actions";

import {
    getIDnowLink,
    getKYC,
    getKYCCombined,
    getUserKYCDetailsFromAdmin,
    getPostident,
    getReKYCFlag,
    setReKYCFlag,
    submitKYC,
} from "api/FnxtApis";
import { extractKYCdata } from "store/helpers";
import { pick } from "lodash";
import { electronicFormatIBAN } from "ibantools";
import { fetchUserDetailsStart } from "../account/actions";

function extractKYCAPIdata(kycFormData, isProfileSubmission) {
    const userFields = ["Email", "TypeGender", "TypeTitle", "FirstName", "LastName"];

    //additional field added if changed from profile section
    !isProfileSubmission && userFields.push("Birthday", "IsUSTaxation", "IsPEP", "PreferredLang");
    let user = pick(kycFormData, userFields);
    if (user?.TypeTitle?.value) {
        user.TypeTitle = user?.TypeTitle?.value;
    } else {
        delete user.TypeTitle;
    }

    const Address = pick(kycFormData, [
        "TypeAddress",
        "Street",
        "Streetnumber",
        "StreetAddition",
        "Zipcode",
        "City",
        "Region",
        "Country",
    ]);

    Address["TypeAddress"] = Address?.TypeAddress || "typeAddressPrimary";
    Address["Country"] = Address?.Country?.value;
    !Address.StreetAddition && delete Address.StreetAddition;
    !Address.Region && delete Address.Region;
    user = {
        ...user,
        Address: [Address],
    };

    //
    if (!isProfileSubmission) {
        user.IsUSTaxation = user?.IsUSTaxation === "true";
        user.IsPEP = user?.IsPEP === "true";
        const BankAccount = pick(kycFormData, ["IBAN", "BIC", "Owner", "TypeBankAccount"]) || {};
        BankAccount.IBAN = electronicFormatIBAN(BankAccount.IBAN);
        BankAccount["TypeBankAccount"] = BankAccount.TypeBankAccount || "typeBankAccountUserPrimary";

        const Phone = pick(kycFormData, ["CountryCode", "DialCode", "Number", "TypePhone"]);
        Phone["TypePhone"] = Phone.TypePhone || "typePhoneMobile";
        Phone["CountryCode"] = Phone?.CountryCode?.toUpperCase() || "";

        const Identification = pick(kycFormData, "Birthplace", "BirthCountry", "Nationality", "MaritalStatus");
        Identification["BirthCountry"] = Identification?.BirthCountry?.value;
        Identification["Nationality"] = Identification?.Nationality?.value;
        Identification["MaritalStatus"] = Identification?.MaritalStatus?.value;

        user = {
            ...user,
            BankAccount: [BankAccount],
            Phone: [Phone],
            Identification: Identification,
        };
    } else {
        user.TypeGender = user?.TypeGender?.value;
    }

    return { user };
}

//Update KYC
function* setReKYCAsync() {
    try {
        const response = yield call(setReKYCFlag, { ReKYC: true });
        if (response.status == 200 || response.status == 201) {
            yield put(
                setReKycSuccess({
                    reKYC: response?.data?.ReKYC,
                })
            );
        } else {
            throw new TypeError(response.originalError.message);
        }
    } catch (err) {
        yield put(setReKycFailure(err));
    }
}

//Update KYC
function* setKYCAsync({ payload }) {
    try {
        const kycPayload = extractKYCAPIdata(payload.user, payload.fromProfile);
        const response = yield call(submitKYC, kycPayload, payload.currentKYCStatus);
        if ((response.status == 200 || response.status == 201) && response.data) {
            //Start Re-KYC if user patched from Profile
            if (payload.enableReKYC) {
                yield put(setReKycStart());
            }
            const kyc = response.data;
            const kycFormData = extractKYCdata(kyc);
            yield put(
                setKYCSuccess({
                    kycData: kyc,
                    kycFormData: kycFormData,
                    hasUserInitiated: true,
                })
            );
            yield put(fetchUserDetailsStart());
        } else {
            yield put(setKYCFailure(response?.messsage || response?.originalError?.message));
        }
    } catch (err) {
        yield put(setKYCFailure(err));
    }
}

function* fetchIDNowAsync({ payload = {} }) {
    const { tenant } = payload;

    try {
        const response = yield call(getIDnowLink, tenant);
        yield put(
            fetchIDnowSuccess({
                idNowRedirectUrl: response?.data?.RedirectURL,
                idNowStatus: response?.data?.Status,
            })
        );
    } catch (err) {
        yield put(fetchIDnowFailure(err));
    }
}

function* fetchPostidentAsync({ payload = {} }) {
    const { tenant } = payload;

    try {
        const response = yield call(getPostident, tenant);
        yield put(
            fetchPostIdentSuccess({
                submittedPostIdent: response.status === 200,
            })
        );
    } catch (err) {
        yield put(fetchPostIdentFailure(err));
    }
}

function* fetchReKycAsync() {
    try {
        const response = yield call(getReKYCFlag);
        if (response.status == 200) {
            yield put(
                fetchReKycSuccess({
                    reKYC: response?.data?.ReKYC,
                })
            );
        } else if ((response.status >= 500 && response.status < 600) || response.status == null) {
            fetchKycServerError(true);
        } else {
            throw new TypeError(response.originalError.message);
        }
    } catch (err) {
        yield put(fetchReKycFailure(err));
    }
}

function* fetchKYCAsync() {
    try {
        const response = yield call(getKYC);

        const kyc = response?.data;
        if (response.status == 200 && kyc) {
            const kycFormData = extractKYCdata(kyc);

            yield put(
                fetchKYCSuccess({
                    kycData: kyc,
                    kycFormData: kycFormData,
                    hasUserInitiated: true,
                })
            );
        } else if (response.status == 404) {
            yield put(fetchKYCFailure(kyc.detail));
        } else if ((response.status >= 500 && response.status < 600) || response.status == null) {
            yield put(fetchKycServerError(true));
        } else {
            throw new TypeError(response.originalError.message);
        }
    } catch (err) {
        yield put(fetchKYCFailure(err));
    }
}

function* fetchKYCDataAsyncForAdmin({ payload }) {
    const userId = payload;

    try {
        const response = yield call(getUserKYCDetailsFromAdmin, userId);

        const kyc = response?.data;
        if (response.status == 200 && kyc) {
            const kycFormData = kyc?.User ? extractKYCdata(kyc?.User) : {};
            const kycedData = kyc?.KYC?.List?.[0];
            const reKYC = kyc?.ReKYC?.ReKYC;

            yield put(
                fetchKYCSuccessFromAdmin({
                    kycData: kyc?.User || {},
                    kycFormData: kycFormData,
                    hasUserInitiated: !!kyc?.User,
                    isUserKYCed: kycedData && !reKYC,
                    providerData: kycedData,
                    reKYC: reKYC,
                })
            );
        } else if (response.status == 404) {
            yield put(fetchKYCFailureFromAdmin(kyc.detail));
        } else {
            throw new TypeError(response.originalError.message);
        }
    } catch (err) {
        yield put(fetchKYCFailureFromAdmin(err));
    }
}

function* fetchKycFromProviderAsync() {
    try {
        const response = yield call(getKYCCombined);

        const kyc = response?.data?.List;

        if (response.status === 200 && kyc) {
            yield put(fetchKycFromProviderSuccess({ isUserKYCed: true, providerData: kyc?.[0] }));
        } else if (response.status == 404) {
            yield put(fetchKycFromProviderFailure(kyc.detail));
        } else if ((response.status >= 500 && response.status < 600) || response.status == null) {
            yield put(fetchKycServerError(true));
        } else {
            throw new TypeError(response.originalError.message);
        }
    } catch (err) {
        yield put(fetchKycFromProviderFailure(err));
    }
}

function* callTheInitialKYCMethods({ payload = {} }) {
    yield put(fetchKYCStart(payload));
    yield put(fetchKycFromProviderStart(payload));
    yield put(fetchIDnowStart(payload));
    yield put(fetchPostIdentStart(payload));
    yield put(fetchReKycStart(payload));
}

function* setKYCStartFn() {
    yield takeLatest(KYCActionTypes.SET_KYC_START, setKYCAsync);
}

function* setReKYCStartFn() {
    yield takeLatest(KYCActionTypes.SET_REKYC_START, setReKYCAsync);
}

function* fetchKYCStartFn() {
    yield takeLatest(KYCActionTypes.FETCH_KYC_START, fetchKYCAsync);
}

function* fetchKycFromProviderStartFn() {
    yield takeLatest(KYCActionTypes.FETCH_KYC_FROM_PROVIDER_START, fetchKycFromProviderAsync);
}

function* fetchIDnowStartFn() {
    yield takeLatest(KYCActionTypes.FETCH_IDNOW_START, fetchIDNowAsync);
}

function* fetchPostidentStartFn() {
    yield takeLatest(KYCActionTypes.FETCH_POSTIDENT_START, fetchPostidentAsync);
}

function* fetchReKycStartFn() {
    yield takeLatest(KYCActionTypes.FETCH_REKYC_START, fetchReKycAsync);
}

//ADMIN
function* fetchKYCDataStartAdminFn() {
    yield takeLatest(KYCActionTypes.FETCH_KYC_START_ADMIN, fetchKYCDataAsyncForAdmin);
}

function* callTheInitialKYCFn() {
    yield takeLatest(KYCActionTypes.CALL_INITIAL_KYC_METHODS, callTheInitialKYCMethods);
}

export function* kycSagas() {
    yield all([
        call(setKYCStartFn),
        call(setReKYCStartFn),
        call(fetchKYCStartFn),
        call(fetchKycFromProviderStartFn),
        call(fetchIDnowStartFn),
        call(fetchPostidentStartFn),
        call(fetchReKycStartFn),
        //ADMIN
        call(fetchKYCDataStartAdminFn),
        call(callTheInitialKYCFn),
    ]);
}

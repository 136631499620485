import React from "react";

import BackButton from "components/BackButton";
import { backButtonText } from "i18n/messages/buttonTitles";
import { useFormattedMessage } from "hooks";

const IsolatedSection = ({
    className = "",
    title = "",
    content = "",
    open,
    onBackButtonClick,
    buttonText,
    onExitButtonClick,
}) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`container isolated-page broad-container mobile-display ${!open ? "none" : "open"} ${className}`}>
            <div className="article">
                <BackButton title={formatMessage(backButtonText)} onClick={onBackButtonClick} />
                <h2>{title}</h2>
                <p className="mt-5 mb-10">{content}</p>
                {buttonText && (
                    <button
                        onClick={() => {
                            onExitButtonClick();
                            onBackButtonClick();
                        }}
                    >
                        {buttonText}
                    </button>
                )}
            </div>
        </div>
    );
};

export default IsolatedSection;

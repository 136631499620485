import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Check2 from "assets/images/svg/check2.svg";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import { userDashboardDetailsStart } from "store/user/account/actions";
import { selectUserDashboardDetails } from "store/user/account/selectors";

const messages = {
    title: "your_identification_will_checked",
    content: "identification_currently_being_processed",
    buttonText: "exit",
};

const KycInfo = ({ className = "", onCloseButtonClick, userDashboardDetailsStart, onBack, open, backSlideEffect = "" }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    return (
        <>
            <div className={`kyc-info ${className} ${backSlideEffect}`}>
                <h3 className="mb-8">{formatMessage(messages.title)}</h3>
                <div className="content">
                    <Check2 />
                    <span className="text-14 fw-600">{formatMessage(messages.content)}</span>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton className="kyci-back-button" onClick={onBack} />
                    <NextButton
                        title={formatMessage(messages.buttonText)}
                        onClick={() => {
                            onCloseButtonClick && onCloseButtonClick();
                            userDashboardDetailsStart();
                        }}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userDashboardData: selectUserDashboardDetails,
});

const mapDispatchToProps = (dispatch) => ({
    userDashboardDetailsStart: () => dispatch(userDashboardDetailsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycInfo);

import NotificationActionTypes from "./types";

export const fetchNotificationsStart = ({ offset, limit, search = "", result }) => ({
    type: NotificationActionTypes.FETCH_NOTIFICATIONS_START,
    payload: { offset, limit, search, result },
});

export const fetchNotificationsSuccess = (payload) => ({
    type: NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
    payload: payload,
});

export const fetchNotificationsFailure = (error) => ({
    type: NotificationActionTypes.FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
});

export const pushNotification = (payload) => ({
    type: NotificationActionTypes.PUSH_NOTIFICATION,
    payload,
});

export const fetchNotificationStart = (ID) => ({
    type: NotificationActionTypes.FETCH_NOTIFICATION_START,
    payload: { ID },
});

export const fetchNotificationSuccess = (payload) => ({
    type: NotificationActionTypes.FETCH_NOTIFICATION_SUCCESS,
    payload: payload,
});

export const fetchNotificationFailure = (error) => ({
    type: NotificationActionTypes.FETCH_NOTIFICATION_FAILURE,
    payload: error,
});

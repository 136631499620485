import ProjectActionTypes from "./types";

export const fetchProjectStart = (payload) => ({
    type: ProjectActionTypes.FETCH_PROJECT_START,
    payload,
});

export const fetchProjectSuccess = (items) => ({
    type: ProjectActionTypes.FETCH_PROJECT_SUCCESS,
    payload: items,
});

export const fetchMarketplaceProjectSuccess = (items) => ({
    type: ProjectActionTypes.FETCH_MARKETPLACE_PROJECT_SUCCESS,
    payload: items,
});

export const fetchProjectFailure = (error) => ({
    type: ProjectActionTypes.FETCH_PROJECT_FAILURE,
    payload: error,
});

export const fetchProjectLimitedStart = (payload) => ({
    type: ProjectActionTypes.FETCH_LIMITED_PROJECT_START,
    payload,
});

export const fetchProjectLimitedSuccess = (items) => ({
    type: ProjectActionTypes.FETCH_LIMITED_PROJECT_SUCCESS,
    payload: items,
});

export const fetchProjectLimitedFailure = (error) => ({
    type: ProjectActionTypes.FETCH_LIMITED_PROJECT_FAILURE,
    payload: error,
});

export const setSelectedProjectId = (selectedProjectID) => ({
    type: ProjectActionTypes.SET_SELECTED_PROJECT_ID,
    payload: selectedProjectID,
});

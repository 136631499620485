const ProjectActionTypes = {
  FETCH_PROJECTS_START: "FETCH_PROJECTS_START",
  FETCH_PROJECTS_SUCCESS: "FETCH_PROJECTS_SUCCESS",
  FETCH_PROJECTS_FAILURE: "FETCH_PROJECTS_FAILURE",
  FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",
  FETCH_PRODUCT_CATALOG_START: "FETCH_PRODUCT_CATALOG_START",
  FETCH_PRODUCT_CATALOG_SUCCESS: "FETCH_PRODUCT_CATALOG_SUCCESS",
  FETCH_PRODUCT_CATALOG_FAILURE: "FETCH_PRODUCT_CATALOG_FAILURE",
};

export default ProjectActionTypes;

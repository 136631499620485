import MessageActionTypes from "./types";

const initialState = {
    messages: [],
    callToAction: [],
    error: "",
    isLoading: false,
    message: {},
    totalMessages: 0,
    unreadCount: 0,
};

const MessageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case MessageActionTypes.FETCH_MESSAGES_START:
        case MessageActionTypes.FETCH_MESSAGE_START:
            return {
                ...state,
                isLoading: true,
            };
        case MessageActionTypes.FETCH_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: payload.messages || [],
                callToAction: payload.callToAction || [],
                totalMessages: payload.totalMessages || 0,
                unreadCount: payload.unreadCount,
                isLoading: false,
            };

        case MessageActionTypes.PUSH_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: [...state.messages, ...payload.messages],
                totalMessages: payload.totalMessages || 0,
                unreadCount: payload.unreadCount || 0,
                isLoading: false,
            };
        case MessageActionTypes.UPDATE_MESSAGES_SUCCESS:
            const messages = payload.messages || [];
            const unreadCount = messages.some((item) => item.readDate === "");

            return {
                ...state,
                messages,
                unreadCount,
                isLoading: false,
            };
        case MessageActionTypes.FETCH_MESSAGES_FAILURE:
        case MessageActionTypes.FETCH_MESSAGE_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
            };
        case MessageActionTypes.FETCH_MESSAGE_SUCCESS:
            return {
                ...state,
                message: payload.message || {},
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default MessageReducer;

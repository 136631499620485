import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { TENANTS_LIST } from "constants/index";
import PrevButton from "components/PrevButton";
import { selectPlatformSourceFromApp, selectTenant } from "store/app/selectors";
import { getIDnowLink, getIDnowEstimatedTime, submitIDnow, submitPostident, getPostident } from "api/FnxtApis";
import Loader from "components/Loader";
import { selectIDnowRedirectURL, selectPostIdentStatus } from "store/user/kyc/selectors";

const messages = {
    providerHeading: "please_identify_yourself",
    freeText: "free",
    onlineText: "online",
    identifyText: "identify",
    onlineHomeText: "online_from_home",
    videoChatText: "video_chat_via_web_app",
    longOpeningText: "long_opening_times",
    oneMinute: "minute",
    moteThanOneMinute: "miutes",
    waitingTimeText: "current_waiting_time_identification",
    IDnowButtonText: "continue_with_idnow",
    personallyText: "personally",
    personallyBranchText: "personally_in_the_branch",
    texhnicalKnowledgeText: "without_technical_knowledge",
    printerNeededText: "printer_is_needed",
    postidentButtonText: "continue_with_postident",
    unknownText: "unknown_text",
};

const KycProvider = ({
    className = "",
    isRequiredBackButton,
    onPostidentClick,
    onBack,
    open,
    backSlideEffect = "",
    currentTenant,
    kycIDNowRedirect,
    submittedPostIdent,
    isApp,
}) => {
    useSideModalScrollToTop();

    const [idNowRedirectUrl, setIDNowRedirectUrl] = useState(kycIDNowRedirect);
    const { formatMessage } = useFormattedMessage();
    const [waitingTime, setWaitingTime] = useState("");

    const [isIDnowLoading, setIsIDnowLoading] = useState(false);
    const [isPostidentLoading, setIsPostidentLoading] = useState(false);

    const [isPostidentPosted, setIsPostidentPosted] = useState(submittedPostIdent);

    function getEstimatedTime() {
        getIDnowEstimatedTime(``).then((response) => {
            const time = (response?.data?.Seconds || 0) / 60;

            const error = response?.message;

            const text = time
                ? `${time} ${
                      time > 1 ? formatMessage(messages.moteThanOneMinute) : formatMessage(messages.oneMinute)
                  } ${formatMessage(messages.waitingTimeText)}`
                : error === "undefined"
                ? formatMessage(messages.unknownText)
                : "";

            setWaitingTime(text);
        });
    }

    function postIDnow() {
        setIsIDnowLoading(true);
        submitIDnow({ Tenant: currentTenant })
            .then((response) => {
                if (response.status == 201) {
                    setIDNowRedirectUrl(response?.data?.RedirectURL);
                    isApp
                        ? window?.ReactNativeWebView?.postMessage(
                              JSON.stringify({
                                  external_url: response?.data?.RedirectURL,
                              })
                          )
                        : window.open(response?.data?.RedirectURL);
                }
            })
            .finally(() => {
                setIsIDnowLoading(false);
            });
    }

    function applyPostident() {
        setIsPostidentLoading(true);
        submitPostident({ Tenant: currentTenant })
            .then((response) => {
                if (response.status == 201) {
                    setIsPostidentPosted(true);
                    onPostidentClick();
                }
            })
            .finally(() => {
                setIsPostidentLoading(false);
            });
    }

    function getIDnowStatus() {
        setIsIDnowLoading(true);
        getIDnowLink(currentTenant).then((response) => {
            setIsIDnowLoading(false);
            if (response.status == 200) {
                setIDNowRedirectUrl(response.data.RedirectURL);
            }
        });
    }

    function getPostidentStatus() {
        setIsPostidentLoading(true);
        getPostident(currentTenant)
            .then((response) => {
                setIsPostidentLoading(false);
                if (response.status == 200) {
                    setIsPostidentPosted(true);
                }
            })
            .catch(() => {
                setIsPostidentLoading(false);
            });
    }

    useEffect(() => {
        if (open) {
            getEstimatedTime();
            getIDnowStatus();
            getPostidentStatus();
        }
    }, [open]);

    function onClickIDNow() {
        if (idNowRedirectUrl) {
            isApp
                ? window?.ReactNativeWebView?.postMessage(
                      JSON.stringify({
                          external_url: idNowRedirectUrl,
                      })
                  )
                : window.open(idNowRedirectUrl);
        } else {
            postIDnow();
        }
    }

    return (
        <>
            <div className={`kyc-provider ${className} ${backSlideEffect}`}>
                <h3 className="mb-8">{formatMessage(messages.providerHeading)}</h3>
                <div className="provider-block idnow mt-0">
                    <img src={"/images/idnow.png"} alt="" />
                    <div className="points">
                        {formatMessage(messages.freeText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.onlineText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.identifyText)}
                    </div>
                    <ul>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.onlineHomeText)}
                        </li>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.videoChatText)}
                        </li>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.longOpeningText)}
                        </li>
                    </ul>
                    <div className="points" style={{ color: "black", wordBreak: "break-word" }}>
                        {waitingTime}
                    </div>
                    <button className={`mw-100 mb-0 ${isIDnowLoading ? "disable-grey" : ""}`} onClick={() => onClickIDNow()}>
                        {isIDnowLoading ? <Loader /> : ""}

                        {formatMessage(messages.IDnowButtonText)}
                        <i className="fas fa-external-link-alt ml-3" />
                    </button>
                </div>
                <div className="provider-block postident">
                    <img src={"/images/postident.png"} alt="" />
                    <div className="points">
                        {formatMessage(messages.freeText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.personallyText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.identifyText)}
                    </div>
                    <ul>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.personallyBranchText)}
                        </li>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.texhnicalKnowledgeText)}
                        </li>
                        <li>
                            <img src={"/images/cross.png"} alt="" />
                            {formatMessage(messages.printerNeededText)}
                        </li>
                    </ul>
                    <button
                        className={`mw-100 mb-0 ${isPostidentLoading ? "disable-grey" : ""}`}
                        onClick={() => {
                            isPostidentPosted ? onPostidentClick() : applyPostident();
                        }}
                    >
                        {isPostidentLoading ? <Loader /> : ""}
                        {formatMessage(messages.postidentButtonText)}
                    </button>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    {isRequiredBackButton && <PrevButton className="kp-back-button" onClick={onBack} />}
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    currentTenant: selectTenant,
    kycIDNowRedirect: selectIDnowRedirectURL,
    submittedPostIdent: selectPostIdentStatus,
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(KycProvider);

import TransactionActionTypes from "./types";

export const fetchTransactionsStart = () => ({
  type: TransactionActionTypes.FETCH_TRANSACTION_START,
});

export const fetchTransactionsSuccess = (transactions) => ({
  type: TransactionActionTypes.FETCH_TRANSACTION_SUCCESS,
  payload: transactions,
});

export const fetchTransactionsFailure = (error) => ({
  type: TransactionActionTypes.FETCH_TRANSACTION_FAILURE,
  payload: error,
});

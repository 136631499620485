export const taxIdentificationNumber = {
    taxIdentificationNumberPlaceholder: "tax_identification_number_placeholder",
    taxIdentificationNumberRequired: "tax_identification_number_required",
    taxIdentificationNumberInvalid: "tax_identification_number_invalid",
};

export const religion = {
    religionPlaceholder: "religion_placeholder",
    religionRequired: "religion_required",
    religionInvalid: "religion_invalid",
};

export const federalState = {
    federalStatePlaceholder: "federal_state_placeholder",
    federalStateRequired: "federal_state_required",
    federalStateInvalid: "federal_state_invalid",
};

export const protectionInYears = {
    protectionInYearsRequired: "protection_years_required",
    protectionInYearsPlaceholder: "protection_years_placeholder",
    protectionInYearsInvalid: "protection_years_invalid",
};

export const initialInvestmentPercentage = {
    initialInvestmentPercentageRequired: "initial_investment_percentage_required",
    initialInvestmentPercentagePlaceholder: "initial_investment_percentage_placeholder",
    initialInvestmentPercentageInvalid: "initial_investment_percentage_invalid",
};

export const followupPercentage = {
    followupPercentageRequired: "followup_percentage_required",
    followupPercentagePlaceholder: "followup_percentage_placeholder",
    followupPercentageInvalid: "followup_percentage_invalid",
};

export const provisioningAmount = {
    provisioningAmountInvalid: "provisioning_amount_invalid",
};

export const email = {
    emailRequired: "email_required",
    invalidEmail: "invalid_email",
    emailPlaceholder: "email_address_placeholder",
    emailRecipientAlready: "email_recipient_already",
};

export const password = {
    passwordRequired: "password_required",
    minimumPasswordLength: "minimum_pass_length",
    weakPassword: "weak_password",
    passwordPlaceholder: "password_placeholder",
    forgotPassword: "forgot_password",
    resetPassword: "reset_password",
    changePassword: "change_password",
    newPasswordPlaceholder: "new_password_placeholder",
    confirmPasswordPlaceholder: "confirm_password_placeholder",
    mismatchPassword: "mismatch_password",
    oldPasswordRequired: "old_password_required",
    newPasswordRequired: "new_password_required",
    confirmPasswordRequired: "confirm_password_required",
    passwordRequirement: "password_format",
    invalidPassword: "invalid_password",
    commonPassword: "common_password",
    oldPassword: "old_password",
    incorrectPassword: "incorrect_password",
    oldNewPasswordSame: "old_new_password_same",
};

export const message = {
    messagePlaceholder: "message_placeholder",
    messageRequired: "message_required",
};

export const name = {
    nameRequired: "name_required",
    namePlaceholder: "name_placeholder",
    nameInvalid: "name_invalid",
};

export const firstName = {
    firstNameRequired: "firstname_required",
    firstNamePlaceholder: "firstname",
    firstNameInvalid: "firstname_invalid",
};

export const lastName = {
    lastNameRequired: "lastname_required",
    lastNamePlaceholder: "lastname_placeholder",
    lastNameInvalid: "lastname_invalid",
};

export const mobilePhone = {
    phoneRequired: "phone_required",
    phoneInvalid: "phone_invalid",
    minimumPhoneLength: "minimum_phone_length",
    phonePlaceholder: "phone_placeholder",
    telephonePlaceholder: "telephone_placeholder",
};

export const landlinePhone = {
    landlinePhoneRequired: "landline_phone_required",
    landlinePhoneInvalid: "landline_phone_invalid",
    minimumLanlinePhoneLength: "minimum_landline_phone_length",
};

export const company = {
    companyRequired: "company_required",
    maCompany: "company_placeholder",
};

export const placedVolume = {
    placedVolumePlaceholder: "placed_volume_placeholder",
    placedVolumeRequired: "placed_volume_required",
};

export const titel = {
    titelPlaceholder: "title_placeholder",
    titelRequired: "product_title_required",
    titelInvalid: "title_invalid",
};

export const license = {
    licensePlaceholder: "license_placeholder",
    licenseRequired: "license_required",
};

export const gender = {
    genderPlaceholder: "salutation",
    genderRequired: "salutation_required",
    genderInvalid: "salutation_invalid",
};

export const website = {
    websitePlaceholder: "website_placeholder",
    websiteInvalid: "website_invalid",
    websiteRequired: "website_required",
};

export const brokerNumber = {
    brokerNumberPlaceholder: "broker_register_number",
    brokerNumberRequired: "broker_number_required",
};

export const street = {
    streetPlaceholder: "street_placeholder",
    streetRequired: "street_required",
};

export const streetNumber = {
    streetNumberPlaceholder: "house_number_placeholder",
    streetNumberRequired: "house_number_required",
};

export const streetAddition = {
    streetAdditionPlaceholder: "additional_address",
};

export const zipcode = {
    zipcodePlaceholder: "post_code",
    zipcodeRequired: "post_code_required",
};

export const city = {
    cityPlaceholder: "city_placeholder",
    cityRequired: "city_required",
};

export const country = {
    countryPlaceholder: "country_placeholder",
    countryRequired: "country_required",
};

export const owner = {
    ownerPlaceholder: "owner_placeholder",
    ownerRequired: "owner_required",
};

export const UStaxation = {
    ustaxationPlaceholder: "ustaxation_placeholder",
    pepNotAllowed: "pep_not_allowed",
    requiredField: "required_field",
};

export const IBAN = {
    ibanRequired: "iban_required",
    ibanInvalid: "iban_Invalid",
};

export const BIC = {
    bicRequired: "bic_required",
    bicInvalid: "bic_invalid",
};

export const PEP = {
    pepNotAllowed: "pep_not_allowed",
    pepInvalid: "pep_invalid",
};

export const shares = {
    sharesRequired: "shares_required",
    sharesInvalid: "shares_invalid",
    sharesMaximumValueError: "maximum_available_shares_error",
};

export const sharesPrice = {
    sharesPriceRequired: "shares_price_required",
    sharesPriceInvalid: "shares_price_invalidd",
};

export const applyAs = {
    private: "private_investor",
    applyForHeading: "apply_as",
    institutional: "institutional_investor",
};

export const taxID = {
    taxIDPlaceholder: "taxid_placeholder",
    taxIDRequired: "taxid_required",
};
export const formSectionHeadings = {
    identificationData: "meta_title_personal_data_page",
    withdrawalAccount: "withdrawal_account",
    taxInformation: "tax_information",
    contactDetails: "contact_details",
    myData: "meta_title_account_profile_page",
    registrationAddress: "registration_address",
    yourTipster: "your_tipster",
};

export const dataActions = {
    changeData: "change_data",
    saveData: "save_data",
};

export const depositAmount = {
    invalidAmount: "invalid_transfer_amount",
    amountRequired: "transfer_amount_required",
    minimumDepositAmount: "minimum_deposit_amount_one_euro",
    minimumDepositAmountFifty: "minimum_deposit_amount_fifty_euro",
    maximumDepositAmount: "maximum_deposit_amount_euros",
    severalDeposit: "several_pending_deposit",
    amountCannotBeZero: "amount_cannot_zero_error",
};

export const withdrawAmount = {
    invalidAmount: "insert_valid_amount",
    amountExceed: "payout_amount_exceed_error",
    amountExceedEuros: "payout_amount_exceed_euros_error",
    minimumWithdrawAmount: "minimum_withdraw_amount_error",
};

export const transferAmount = {
    invalidTransferAmount: "invalid_transfer_amount",
    transferAmountRequired: "transfer_amount_required",
    transferAmountMaximumValueError: "maximum_available_is",
    transferAmountMaximumAmountError: "maximum_euros_send",
    transferAmountMinimumAmountError: "minimum_euros_send",
    transferAmountCannotBeZero: "transfer_amount_cannot_be_zero",
};

export const positiveNumber = {
    invalidPositiveNumber: "invalid_positive_number",
};

export const integerNumber = {
    invalidIntegerNumber: "invalid_integer_number",
};

export const SMTPPlaceholder = {
    smtpUser: "smtp_user",
    smtpUserRequired: "smtp_user_required",

    smtpPassword: "smtp_password",
    smtpPasswordRequired: "smtp_password_required",

    domain: "smtp_domain",
    smtpDomainRequired: "smtp_domain_required",

    smtpServer: "smtp_server",
    smtpServerRequired: "smtp_server_required",

    smtpPort: "smtp_port",
    smtpPortRequired: "smtp_port_required",

    testSmtpButtonText: "smtp_test",
    smtpConfigInValid: "credentials_are_not_valid",
};

export const formButtonText = {
    submitButtonText: "form_submit",
    saveButtonText: "tipster_form_button_text",
    updateButtonText: "update",
    editButtonText: "form_edit",
    cancelButtonText: "form_cancel",
};

export const admin_tenant = {
    namePlaceholder: "name_placeholder",
    technicalNamePlaceholder: "technical_name_placeholder",
    smtpUserPlaceholder: "smtp_user_placeholder",
    smtpPasswordPlaceholder: "smtp_password_placeholder",
    smtpServerPlaceholder: "smtp_server_placeholder",
    smtpDomainPlaceholder: "tenant_setting_smtp_domain",
};

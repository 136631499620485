import React from "react";

const ErrorMessage = ({ className = "", children: error }) => {
    if (!error) {
        return null;
    }

    return <label className={`error-label alert-red-color text-left mt-0 mb-2 ${className}`}>{error}</label>;
};

export default ErrorMessage;

import React from "react";
import { tenantPrimaryColors } from "constants/index";

const MailWithDot = ({ currentTenant }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="dot-on-email-icon" width="33" height="30" viewBox="0 0 33 30">
            <defs>
                <clipPath id="a">
                    <rect
                        style={{ fill: "#fff", stroke: "#707070" }}
                        width="30"
                        height="30"
                        transform="translate(-23882 -22606)"
                    />
                </clipPath>
            </defs>
            <g transform="translate(-83 -18)">
                <g transform="translate(23965 22624)">
                    <g transform="translate(-23880 -22605)">
                        <g transform="translate(1 5)">
                            <path
                                className="c"
                                style={{ fill: "none", stroke: "#171926", strokeLinejoin: "round", strokeWidth: "2px" }}
                                d="M-23460-22473h24v18h-24Z"
                                transform="translate(23460 22473)"
                            />
                            <path
                                className="c"
                                style={{ fill: "none", stroke: "#171926", strokeLinejoin: "round", strokeWidth: "2px" }}
                                d="M9.471,9.389.982,1.824A2.936,2.936,0,0,1,0,0H23.785A2.936,2.936,0,0,1,22.8,1.824L14.318,9.389a3.731,3.731,0,0,1-4.848,0Z"
                                transform="translate(0.154 0.002)"
                            />
                        </g>
                        <line
                            className="d"
                            style={{ fill: "none", stroke: "#171926", strokeWidth: "2px" }}
                            y1="9"
                            x2="9"
                            transform="translate(1 14.5)"
                        />
                        <line
                            className="d"
                            style={{ fill: "none", stroke: "#171926", strokeWidth: "2px" }}
                            x1="9"
                            y1="9"
                            transform="translate(16 14.5)"
                        />
                    </g>
                </g>
                <g className="e" style={{ strokeWidth: "2px", stroke: "#fff" }} transform="translate(106 20)">
                    <circle
                        className="f"
                        style={{ stroke: "none", fill: tenantPrimaryColors[currentTenant]?.primary }}
                        cx="4"
                        cy="4"
                        r="4"
                    />
                    <circle className="g" style={{ fill: "none" }} cx="4" cy="4" r="5" />
                </g>
            </g>
        </svg>
    );
};

export default MailWithDot;

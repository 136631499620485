import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useFormattedMessage } from "hooks";
import DownloadButton from "components/DownloadButton";
import { createMarkup, convertDBDateToDefaultFormat } from "utils";
import { getDocumentsHost } from "common/helper";
import { markAsReadMessage } from "api";
import { updateMessagesSuccess } from "store/user/message/actions";

const DOCUMENT_HOST = getDocumentsHost();

const defineText = {
    attachedDocuments: "attached_documents",
};

const MessageDetailModal = ({ message, updateMessagesSuccess, messages, backSlideEffect = "" }) => {
    const { formatMessage } = useFormattedMessage();

    const isDocumentExists = (documentTitle) => {
        return message?.documents?.find((document) => document.Title === documentTitle);
    };

    const formateDate = (date) => {
        return moment(convertDBDateToDefaultFormat(date.split("_")[0])).format("DD.MM.YYYY");
    };

    useEffect(() => {
        if (!message.readDate) {
            markAsReadMessage([message.uid])
                .then((res) => {
                    if (res && res.data && res.data.errors && res.data.errors[0]) {
                        console.error("markAsReadMessage api error", res.data.errors[0]);
                    } else {
                        const updatedMessages = messages.map((item) => {
                            if (item.uid == message.uid) {
                                item.readDate = new Date();
                                return item;
                            }
                            return item;
                        });
                        updateMessagesSuccess({ messages: updatedMessages });
                    }
                })
                .catch((error) => {
                    console.error("markAsReadMessage api error", error);
                });
        }
    }, [message]);

    return (
        <div className={`flex column ${backSlideEffect}`}>
            <div className="flex justify-end message-detail-date">
                <span className="text-14 another-grey-color">
                    {message?.createdDate ? formateDate(message?.createdDate) : ""}
                </span>
            </div>
            <div className="flex mt-2 text-22 fw-600">
                <h2>{message.title}</h2>
            </div>
            <div className="mt-2">
                <div className="detail-para" dangerouslySetInnerHTML={createMarkup(message.body)} />
            </div>
            {Array.isArray(message?.documents) && message?.documents?.length > 0 && (
                <div className="mt-3">
                    <div className="text-18 fw-600">{formatMessage(defineText.attachedDocuments)}</div>
                    <div className="px-4 py-8">
                        {message?.documents.map(({ Title, Filename, Path }, index) => {
                            const file = Title && isDocumentExists(Title);
                            return (
                                file && (
                                    <DownloadButton
                                        isToken
                                        key={index}
                                        fileURL={`${DOCUMENT_HOST}/download${Path}${Filename}`}
                                        title={Title}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateMessagesSuccess: (notifications) => dispatch(updateMessagesSuccess(notifications)),
});

export default connect(null, mapDispatchToProps)(MessageDetailModal);

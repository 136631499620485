import { takeLatest, call, put, all } from "redux-saga/effects";

import AdequacyActionTypes from "./types";
import { fetchAdequacy } from "api";
import { setAdequacyStart, setAdequacySuccess, setAdequacyFailure, fetchAdequacySuccess, fetchAdequacyFailure } from "./actions";

import {
    yearsOptionsMapping,
    investmentServicesYearsOptionsMapping,
    transactionYearsOptionsMapping,
    transactionSizesOptionsMapping,
} from "constants/index";

function* setAdequacyAsync({ payload }) {
    try {
        yield put(setAdequacySuccess(payload));
    } catch (err) {
        yield put(setAdequacyFailure(err));
    }
}

function* fetchAdequacyAsync() {
    try {
        const response = yield call(fetchAdequacy);
        const { adequacy } = response.data.data.user.user;
        if (adequacy && adequacy.status !== "uncompleted") {
            const saveAdequacyData = {
                survey_first_step: { education: adequacy.education, job: adequacy.job },
                survey_second_step: {
                    bonds: {
                        bondsDuration:
                            adequacy.bondsDuration && yearsOptionsMapping.find((item) => item.value === adequacy.bondsDuration),
                        bondsXP: adequacy.bondsXP,
                    },
                    investment_funds: {
                        investFundsDuration:
                            adequacy.investFundsDuration &&
                            yearsOptionsMapping.find((item) => item.value === adequacy.investFundsDuration),
                        investFundsXP: adequacy.investFundsXP,
                    },
                    shares: {
                        sharesDuration:
                            adequacy.sharesDuration && yearsOptionsMapping.find((item) => item.value === adequacy.sharesDuration),
                        sharesXP: adequacy.sharesXP,
                    },
                    sharing_rights: {
                        profitPartDuration:
                            adequacy.profitPartDuration &&
                            yearsOptionsMapping.find((item) => item.value === adequacy.profitPartDuration),
                        profitPartXP: adequacy.profitPartXP,
                    },
                    subordinated: {
                        subordLoanDuration:
                            adequacy.subordLoanDuration &&
                            yearsOptionsMapping.find((item) => item.value === adequacy.subordLoanDuration),
                        subordLoanXp: adequacy.subordLoanXp,
                    },
                },
                survey_third_step: {
                    securities: {
                        bondsXPAssisted:
                            adequacy.bondsXPAssisted &&
                            investmentServicesYearsOptionsMapping.find((item) => item.value === adequacy.bondsXPAssisted),
                        bondsXPIndependent:
                            adequacy.bondsXPIndependent &&
                            investmentServicesYearsOptionsMapping.find((item) => item.value === adequacy.bondsXPIndependent),
                        bondsXPConsulted:
                            adequacy.bondsXPConsulted &&
                            investmentServicesYearsOptionsMapping.find((item) => item.value === adequacy.bondsXPConsulted),
                    },
                    transactions: {
                        bondsTransactions:
                            adequacy.bondsTransactions &&
                            transactionYearsOptionsMapping.find((item) => item.value === adequacy.bondsTransactions),
                        bondsAmount:
                            adequacy.bondsAmount &&
                            transactionSizesOptionsMapping.find((item) => item.value === adequacy.bondsAmount),
                    },
                },
                submitted_date: adequacy.dateTime,
            };
            yield put(setAdequacyStart(saveAdequacyData));
        }
        yield put(fetchAdequacySuccess(adequacy));
    } catch (err) {
        yield put(fetchAdequacyFailure(err));
    }
}

function* fetchSetAdequacyStart() {
    yield takeLatest(AdequacyActionTypes.SET_ADEQUACY_START, setAdequacyAsync);
}

function* fetchAdequacyStart() {
    yield takeLatest(AdequacyActionTypes.FETCH_ADEQUACY_START, fetchAdequacyAsync);
}

export function* adequacySagas() {
    yield all([call(fetchSetAdequacyStart), call(fetchAdequacyStart)]);
}

import { createSelector } from "reselect";

const callToAction = (state) => state.message.callToAction || [];
const messages = (state) => state.message.messages;
const message = (state) => state.message.message;
const totalMessages = (state) => state.message.totalMessages;
const unreadCount = (state) => state.message.unreadCount;
const isLoading = (state) => state.message.isLoading;

export const selectCallToAction = createSelector(callToAction, (callToAction) => callToAction);

export const selectMessage = createSelector(messages, (messages) => messages);

export const getMessage = createSelector(message, (message) => message);

export const getTotalMessages = createSelector(totalMessages, (totalMessages) => totalMessages);

export const getUnreadCount = createSelector(unreadCount, (unreadCount) => unreadCount);

export const getIsLoading = createSelector(isLoading, (isLoading) => isLoading);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "store/user/account/actions";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";

const messages = {
    title: "waiting_activation",
    subTitle: "access_released_through_umbrella",
    content1: "activation_access_to_secondary_market",
    content2: "security_tenant_platform",
    content3: "inform_about_status",
    buttonText: "close_btn",
};

const WaitingForActivation = ({ fetchUserDetailsStart, onCloseButtonClick, isPropertyDetail, backSlideEffect = "" }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        isPropertyDetail && fetchUserDetailsStart();
    }, []);

    return (
        <div className={`mb-20 ${backSlideEffect}`}>
            <h3 className="text-center">{formatMessage(messages.title)}</h3>
            <p>{formatMessage(messages.subTitle)}</p>
            <img className="mt-18 mb-15 mx-auto d-block" src={"/images/pause.png"} alt="" />
            <p className="text-center mb-10 text-16">{formatMessage(messages.content1)}</p>
            <p className="text-center mb-10 text-16">{formatMessage(messages.content2)}</p>
            <p className="text-center mb-15 text-16">{formatMessage(messages.content3)}</p>
            {/* <NavLink
                href="/dashboard"> */}
            <button onClick={() => onCloseButtonClick()} className="">
                {formatMessage(messages.buttonText)}
            </button>
            {/* </NavLink> */}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchUserDetailsStart: () => dispatch(fetchUserDetailsStart()),
});

export default connect(null, mapDispatchToProps)(WaitingForActivation);

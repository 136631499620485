import MiscellaneousActionTypes from "./types";

export const fetchTestUserEmailsStart = () => ({
  type: MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_START,
});

export const fetchTestUserEmailsSuccess = (testUserEmails) => ({
  type: MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_SUCCESS,
  payload: testUserEmails,
});

export const fetchTestUserEmailsFailure = (error) => ({
  type: MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_FAILURE,
  payload: error,
});

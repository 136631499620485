import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { setUserMobileData, userDashboardDetailsSuccess } from "store/user/account/actions";
import { selectUserMobileDetails, selectUserDashboardDetails, selectUserPhone } from "store/user/account/selectors";
import { PHONE_TYPES } from "constants/index";
import { TILE_STATUS } from "constants/status";
import MobileNumber from "components/MobileModal/MobileNumber";
import MobileCode from "components/MobileModal/MobileCode";

const messages = {
    heading: "confirmation_of_your_number",
    content: "mobile_tan_procedure",
    inputLabel: "provide_number_for_confirmation",
};

const MobileData = ({
    setStep,
    userMobileData,
    setUserMobileData,
    userDashboardData,
    userDashboardDetailsSuccess,
    userPhone,
    mobileSuccess,
    // backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const primaryPhone =
        Array.isArray(userPhone) && userPhone.length > 0 && userPhone.find((x) => x.TypePhone === PHONE_TYPES.TypePhoneMobile);

    return (
        <div className="secondary-market-mobile-data">
            {userMobileData && userMobileData.step === 3 ? (
                <MobileCode
                    changeNumber={() => {
                        setUserMobileData && setUserMobileData({ ...userMobileData, step: 2 });
                        setBackSlideEffect("step-slide-right");
                    }}
                    formSubmitSuccess={() => {
                        userDashboardDetailsSuccess({
                            ...userDashboardData,
                            StatusMobilePhone: TILE_STATUS.HIDE,
                        });
                        mobileSuccess();
                    }}
                />
            ) : (
                <MobileNumber
                    isRequiredBackButton
                    isReadable={!!(primaryPhone && primaryPhone.Number !== "")}
                    heading={formatMessage(messages.heading)}
                    content={formatMessage(messages.content)}
                    inputLabel={formatMessage(messages.inputLabel)}
                    onBack={() => {
                        setStep("start");
                        setBackSlideEffect("step-slide-right");
                    }}
                    formSubmitSuccess={(values) => {
                        setUserMobileData && setUserMobileData({ ...userMobileData, ...values, step: 3 });
                        setBackSlideEffect("step-slide-left");
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userMobileData: selectUserMobileDetails,
    userDashboardData: selectUserDashboardDetails,
    userPhone: selectUserPhone,
});

const mapDispatchToProps = (dispatch) => ({
    setUserMobileData: (value) => dispatch(setUserMobileData(value)),
    userDashboardDetailsSuccess: (value) => dispatch(userDashboardDetailsSuccess(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileData);

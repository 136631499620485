import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectPlatformSourceFromApp } from "store/app/selectors";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { isClient } from "utils/index";

function ToolTip(props) {
    return !props.isApp && (!isClient() ? 768 : window.innerWidth) > 767 ? <ReactTooltip {...props} /> : null;
}

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(ToolTip);

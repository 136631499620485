import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useUtils } from "hooks";
import SuccessAnimation from "components/SuccessAnimation";
import ChangePasswordForm from "components/ChangePassword/ChangePasswordForm";
import Auth from "utils/auth0";
import { selectUserLeadSource } from "store/user/account/selectors";

import { SOCIAL_LOGIN_LEAD_SOURCE } from "constants/index";
import { selectPlatformSourceFromApp } from "store/app/selectors";

const messages = {
    changePasswordNotice: "change_password_notice",
    leadSourceP1: "you_have_registered",
    leadSourceP2: "so_accept_title3",
    leadSourceP3: "please_use",
    leadSourceP4: "to_change_password",
    changeSuccessText: "change_success_text",
};

const ChangePasswordModal = ({ userLeadSource, isApp }) => {
    const { formatMessage } = useFormattedMessage();
    const { isTenantFinexity, isProdEnv } = useUtils();

    const [success, setSuccess] = useState(false);

    const onSuccessOfChangePassword = () => {
        setSuccess(true);
        setTimeout(() => {
            if (isApp) {
                window.ReactNativeWebView.postMessage("passwordChanged");
                return;
            }

            if (isProdEnv && !isTenantFinexity) {
                Auth.customLogout();
            } else Auth.logout();
        }, 2000);
    };

    useEffect(() => {
        success && jQuery("#change-pass").closest(".side-modal").scrollTop(0);
    }, [success]);

    return (
        <div id="change-pass" className="flex column py-3">
            <div className="text-16 fw-400">
                {formatMessage(success ? messages.changeSuccessText : messages.changePasswordNotice)}
            </div>
            <div className="mt-8">
                {SOCIAL_LOGIN_LEAD_SOURCE.includes(userLeadSource) ? (
                    <div className="form-holder text-center">
                        <p>
                            {formatMessage(messages.leadSourceP1)} {userLeadSource}
                            {formatMessage(messages.leadSourceP2)}
                        </p>
                        <p>
                            {formatMessage(messages.leadSourceP3)} {userLeadSource} {formatMessage(messages.leadSourceP4)}
                        </p>
                    </div>
                ) : !success ? (
                    <ChangePasswordForm onSuccessOfChangePassword={onSuccessOfChangePassword} />
                ) : (
                    <SuccessAnimation className="auth-common" />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userLeadSource: selectUserLeadSource,
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(ChangePasswordModal);

import MessageActionTypes from "./types";

export const fetchMessagesStart = (offset, limit, search) => ({
    type: MessageActionTypes.FETCH_MESSAGES_START,
    payload: { offset, limit, search },
});

export const fetchMessagesSuccess = (payload) => ({
    type: MessageActionTypes.FETCH_MESSAGES_SUCCESS,
    payload: payload,
});

export const pushMessagesSuccess = (payload) => ({
    type: MessageActionTypes.PUSH_MESSAGES_SUCCESS,
    payload: payload,
});

export const updateMessagesSuccess = (payload) => ({
    type: MessageActionTypes.UPDATE_MESSAGES_SUCCESS,
    payload: payload,
});

export const fetchMessagesFailure = (error) => ({
    type: MessageActionTypes.FETCH_MESSAGES_FAILURE,
    payload: error,
});

export const fetchMessageStart = (ID) => ({
    type: MessageActionTypes.FETCH_MESSAGE_START,
    payload: { ID },
});

export const fetchMessageSuccess = (payload) => ({
    type: MessageActionTypes.FETCH_MESSAGE_SUCCESS,
    payload: payload,
});

export const fetchMessageFailure = (error) => ({
    type: MessageActionTypes.FETCH_MESSAGE_FAILURE,
    payload: error,
});

import AccountActionTypes from "../account/types";

import DashboardActionTypes from "./types";

const INITIAL_STATE = {
    dashboardData: {},
    error: "",
    isLoading: false,
};

const dashboardReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case DashboardActionTypes.FETCH_DASHBOARD_DETAILS_START:
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_START:
            return { ...state, isLoading: true };
        case DashboardActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS:
            return { ...state, dashboardData: payload, isLoading: false };
        case DashboardActionTypes.FETCH_DASHBOARD_DETAILS_FAILURE:
            return { ...state, error: payload, isLoading: false };
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_SUCCESS:
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_FAILURE:
            return { ...state, isLoading: false };
        case AccountActionTypes.LOGOUT:
            return state;
        default:
            return state;
    }
};

export default dashboardReducer;

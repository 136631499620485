import { TENANTS_LIST } from "constants/index";
import AppActionTypes from "./types";
import { getFirstBrowserLanguage, isClient } from "utils/index";

function checkLocaleIsValid(locale) {
    return locale === "en" || locale === "de" ? true : false;
}

function checkLocale(locale) {
    return checkLocaleIsValid(locale) ? locale : "de";
}

const INITIAL_STATE = {
    isLoading: false,
    platformSource: null,
    mobilePlatform: null,
    isPlatformSourceFromApp: false,
    isMpin: false,
    isNotification: false,
    tenant: null,
    currentTenant: null,
    finxConfig: null,
    host: null,
    isProdEnv: false,
    documentHost: null,
    locale: checkLocale(getFirstBrowserLanguage()),
};

const setLocaleToCookie = (locale) => {
    locale = checkLocale(locale);
    if (isClient()) {
        const FINX_COOKIE = require("common/constants");
        require("js-cookie").set(FINX_COOKIE.LOCALE, locale);
    }
    return locale;
};

const getLocale = () => {
    let locale = INITIAL_STATE.locale;
    if (isClient()) {
        const FINX_COOKIE = require("common/constants");
        const cookieLocale = require("js-cookie").get(FINX_COOKIE.LOCALE);
        locale = checkLocaleIsValid(locale) ? cookieLocale : locale;
    }
    return locale;
};

const appReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case AppActionTypes.FETCH_TENANT_START:
            return {
                ...state,
                isLoading: true,
            };

        case AppActionTypes.SET_PLATFORM_SOURCE:
            return {
                ...state,
                platformSource: payload,
                isPlatformSourceFromApp: payload === "app",
            };
        case AppActionTypes.SET_MOBILE_PLATFORM:
            return {
                ...state,
                mobilePlatform: payload,
            };
        case AppActionTypes.MPIN:
            return {
                ...state,
                isMpin: payload == "true",
            };
        case AppActionTypes.MOBILE_NOTIFICATION:
            return {
                ...state,
                isNotification: payload == "true",
            };
        case AppActionTypes.IS_CLUBDEAL:
            return {
                ...state,
                isClubDeal: payload == "true",
            };

        case AppActionTypes.FETCH_TENANT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };

        case AppActionTypes.SET_ENTERED_URL:
            return {
                ...state,
                entered_url: payload,
            };

        case AppActionTypes.SET_TENANT:
            return {
                ...state,
                tenant: payload,
                isTenantFinexity: payload === TENANTS_LIST.FINEXITY,
                currentTenant: {
                    ...(state.currentTenant || {}),
                    TechnicalName: payload,
                },
            };

        case AppActionTypes.FETCH_TENANT_SUCCESS:
            const currentTenant = payload?.tenant || state.currentTenant;
            return {
                ...state,
                isLoading: false,
                currentTenant: {
                    ...(state.currentTenant || {}),
                    ...currentTenant,
                    TechnicalName: currentTenant?.TechnicalName || state?.currentTenant?.TechnicalName,
                },
            };

        case AppActionTypes.SET_FINX_CONFIG:
            return {
                ...state,
                finxConfig: payload,
                isProdEnv:
                    payload.REACT_APP_FINEXITY_ENVIRONMENT === "prod" || payload.REACT_APP_FINEXITY_ENVIRONMENT === "staging",
            };

        case AppActionTypes.SET_HOST:
            return {
                ...state,
                host: payload,
            };

        case AppActionTypes.SET_DOCUMENT_HOST:
            return {
                ...state,
                documentHost: payload,
            };

        //Locale
        case AppActionTypes.SET_LOCALE:
            return {
                ...state,
                locale: setLocaleToCookie(payload),
            };
        case AppActionTypes.PERSIST_REHYDRATE:
            return {
                ...state,
                locale: getLocale(),
            };
        default:
            return state;
    }
};

export default appReducer;

import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import SelectInput from "components/SelectInput";
import { transactionSizesOptions, transactionYearsOptions } from "constants/index";
import cn from "classnames";
import { useSideModalScrollToTop } from "hooks";
import { adequacySkipLinkText, pleaseWaitButtonText, nextButtonText } from "i18n/messages/buttonTitles";

const Transactions = ({
    onSubmit,
    isAdequacyLoading,
    bondsTransactionsValue,
    formatMessage,
    messages,
    setActiveStep,
    formData = {},
    setFormData,
    setAdequacyStart,
    backSlideEffect = "",
    setBackSlideEffect,
    open,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    return (
        <>
            <div className={`transactions multisteps ${backSlideEffect}`}>
                <div className="header-part">
                    <h4 className="primary-color">{formatMessage(messages.secondHeading)}</h4>
                </div>
                <div className="content">
                    <form method="post" className="survey-form">
                        <div className="mt-5">
                            <h4 className="mt-4">{formatMessage(messages.secondQuestion1)}</h4>
                            <SelectInput
                                maxMenuHeight={400}
                                isSearchable={false}
                                className="select form-item"
                                classNamePrefix="select"
                                name="transaction-years"
                                options={transactionYearsOptions()}
                                placeholder={formatMessage(messages.pleaseSelect)}
                                value={formData.transactions.BondsTransactions || ""}
                                onChange={(selectedOption) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        transactions: { ...prevState.transactions, BondsTransactions: selectedOption },
                                    }));
                                }}
                            />
                        </div>
                        <div className="mt-6 second-question">
                            <h4 className="mt-0">{formatMessage(messages.secondQuestion2)}</h4>
                            <SelectInput
                                maxMenuHeight={400}
                                isSearchable={false}
                                className="select form-item"
                                classNamePrefix="select"
                                name="transaction-sizes"
                                options={transactionSizesOptions()}
                                placeholder={formatMessage(messages.pleaseSelect)}
                                value={formData.transactions.BondsAmount || ""}
                                onChange={(selectedOption) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        transactions: { ...prevState.transactions, BondsAmount: selectedOption },
                                    }));
                                }}
                            />
                        </div>
                    </form>
                </div>
                {StatusAdequacy != "renew" && (
                    <span
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-right");
                        }}
                        className="skip-link"
                    >
                        {formatMessage(adequacySkipLinkText)}
                    </span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setActiveStep(1);
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        className={cn({
                            "px-0": true,
                            "disable-grey": bondsTransactionsValue === undefined || bondsTransactionsValue === "",
                            "disable-pointer": isAdequacyLoading,
                        })}
                        onClick={() => onSubmit()}
                        title={isAdequacyLoading ? formatMessage(pleaseWaitButtonText) : formatMessage(nextButtonText)}
                        isLoading={isAdequacyLoading}
                    />
                </div>
            )}
        </>
    );
};

export default Transactions;

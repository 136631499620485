import { put, all, takeLatest, call } from "redux-saga/effects";
import MessageActionTypes from "./types";

import {
    fetchMessagesSuccess,
    fetchMessagesFailure,
    fetchMessageSuccess,
    fetchMessageFailure,
    pushMessagesSuccess,
} from "./actions";
import { getInbox, getMessage } from "api";

function* fetchMessagesAsync({ payload: { offset, limit, search } }) {
    try {
        const response = yield call(getInbox, { offset, limit, search });
        const { callToAction, messages, totalMessages } = response.data.data.getInbox;
        const unreadCount = messages.some((item) => item.readDate === "");

        if (offset > 1) {
            yield put(pushMessagesSuccess({ callToAction, messages, totalMessages, unreadCount }));
        } else {
            yield put(fetchMessagesSuccess({ callToAction, messages, totalMessages, unreadCount }));
        }
    } catch (err) {
        yield put(fetchMessagesFailure(err));
    }
}

function* fetchMessagesStart() {
    yield takeLatest(MessageActionTypes.FETCH_MESSAGES_START, fetchMessagesAsync);
}

function* fetchMessageAsync({ payload: { ID } }) {
    try {
        const response = yield call(getMessage, ID);
        const { message } = response.data.data.getMessage;
        yield put(fetchMessageSuccess({ message }));
    } catch (err) {
        yield put(fetchMessageFailure(err));
    }
}

function* fetchMessageStart() {
    yield takeLatest(MessageActionTypes.FETCH_MESSAGE_START, fetchMessageAsync);
}

export function* messageSagas() {
    yield all([call(fetchMessageStart), call(fetchMessagesStart)]);
}

import React from "react";
import CustomFormatNumber from "components/CustomFormatNumber";
import RoundIconContainer from "./roundIconContainer";

const BankActivity = ({
    date,
    showIconBorderColor = false,
    iconBackgroundColor,
    onClick,
    iconComponent,
    name,
    isGreen,
    amount,
    status,
    type,
}) => {
    return (
        <>
            <div
                className={`card-container mt-4 flex bank-activity ${typeof onClick === "function" ? "cursor-pointer" : ""}`}
                onClick={onClick}
            >
                <RoundIconContainer
                    borderColor={showIconBorderColor ? (isGreen ? "rgba(47, 185, 98, 1)" : "#dd4545") : ""}
                    iconBackgroundColor={iconBackgroundColor}
                    iconComponent={iconComponent}
                />
                <div className="w-100 py-4">
                    <span className="flex justify-between align-center">
                        <span className="d-inline-block text-14 fw-600 mr-1 email-name item-text-overflow" title={name}>
                            {name}
                        </span>
                        <span
                            className={`d-flex text-14 fw-600 mr-4 white-space-nowrap activity-amount ${
                                isGreen ? "green-color" : "alert-red-color"
                            }`}
                        >
                            {isGreen ? "+ " : "- "}
                            <CustomFormatNumber value={amount} />
                        </span>
                    </span>
                    <span className="flex justify-between align-center mt-1">
                        <span className="text-12 secondary-light-color fw-500 w-30 w-sm-30">{status}</span>
                        <span className="d-inline-block text-12 fw-500 secondary-light-color w-30 w-sm-24 text-center">
                            {date}
                        </span>
                        <span className="text-right w-40 w-sm-36">
                            <span className="d-inline-block text-12 fw-500 secondary-light-color mr-4 transfer-type">{type}</span>
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
};

export default BankActivity;

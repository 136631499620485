import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import SelectInput from "components/SelectInput";
import { investmentServicesYearsOptions } from "constants/index";
import { useSideModalScrollToTop } from "hooks";
import { adequacySkipLinkText } from "i18n/messages/buttonTitles";

const Securities = ({
    formatMessage,
    messages,
    setActiveStep,
    formData = {},
    setFormData,
    setAdequacyStart,
    adequacyData,
    backSlideEffect = "",
    setBackSlideEffect,
    open,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    return (
        <>
            <div className={`securities multisteps ${backSlideEffect}`}>
                <div className="header-part">
                    <h4 className="primary-color">{formatMessage(messages.firstHeading)}</h4>
                </div>
                <div className="content">
                    <form method="post" className="survey-form">
                        <h3 className="mt-4 mb-7">{formatMessage(messages.firstQuestion)}</h3>
                        <div className="mt-7">
                            <h4 className="mt-0 bullet-type">{formatMessage(messages.firstQuestionOption1)}</h4>
                            <SelectInput
                                maxMenuHeight={400}
                                isSearchable={false}
                                className="select form-item"
                                classNamePrefix="select"
                                name="independent-advice"
                                options={investmentServicesYearsOptions()}
                                placeholder={formatMessage(messages.pleaseSelect)}
                                value={formData.securities.BondsXPIndependent || ""}
                                onChange={(selectedOption) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        securities: { ...prevState.securities, BondsXPIndependent: selectedOption },
                                    }));
                                }}
                            />
                        </div>
                        <div className="mt-7">
                            <h4 className="mt-0 bullet-type">{formatMessage(messages.firstQuestionOption2)}</h4>
                            <SelectInput
                                maxMenuHeight={400}
                                isSearchable={false}
                                className="select form-item"
                                classNamePrefix="select"
                                name="investment-advice"
                                options={investmentServicesYearsOptions()}
                                placeholder={formatMessage(messages.pleaseSelect)}
                                value={formData.securities.BondsXPConsulted || ""}
                                onChange={(selectedOption) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        securities: { ...prevState.securities, BondsXPConsulted: selectedOption },
                                    }));
                                }}
                            />
                        </div>
                        <div className="mt-7">
                            <h4 className="mt-0 bullet-type">{formatMessage(messages.firstQuestionOption3)}</h4>
                            <SelectInput
                                maxMenuHeight={400}
                                isSearchable={false}
                                menuPlacement="top"
                                className="select form-item"
                                classNamePrefix="select"
                                name="asset-management"
                                options={investmentServicesYearsOptions()}
                                placeholder={formatMessage(messages.pleaseSelect)}
                                value={formData.securities.BondsXPAssisted || ""}
                                onChange={(selectedOption) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        securities: { ...prevState.securities, BondsXPAssisted: selectedOption },
                                    }));
                                }}
                            />
                        </div>
                    </form>
                </div>
                {StatusAdequacy != "renew" && (
                    <span
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-right");
                        }}
                        className="skip-link"
                    >
                        {formatMessage(adequacySkipLinkText)}
                    </span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setAdequacyStart({ step: 6 });
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        className={`${Object.keys(formData.securities)?.length === 3 ? "" : "disable-grey"}`}
                        onClick={() => {
                            if (Object.keys(formData.securities)?.length === 3) {
                                setAdequacyStart({
                                    step: 7,
                                    survey_third_step: { ...adequacyData.survey_third_step, ...formData, step: 2 },
                                });
                                setActiveStep(2);
                                setBackSlideEffect("step-slide-left");
                            }
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default Securities;

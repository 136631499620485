import React, { Fragment, useState } from "react";
import { useFormattedMessage } from "hooks";
import MobileModal from "components/MobileModal";
import ArrowNext from "assets/images/svg/next.svg";

const messages = {
    mobileTileTitle: "your_mobile_number",
    mobileTileContent: "confirm_mobile_number",
};

const WrappedMobileModal = ({ setMobilePhoneModalFlag, onCloseButtonClick, mobilePhoneModalFlag }) => {
    const { formatMessage } = useFormattedMessage();
    const [mobileSuccessFlag, setMobileSuccessFlag] = useState(false);

    return (
        <Fragment>
            <MobileModal
                open={mobilePhoneModalFlag}
                onCloseButtonClick={onCloseButtonClick}
                onBackButtonClick={() => setMobilePhoneModalFlag(false)}
                setMobileSuccessFlag={() => setMobileSuccessFlag(true)}
            />

            {!mobileSuccessFlag && (
                <div
                    className={"identity-block mobile-phone-block border-radius pointer bg-primary-light"}
                    onClick={() => setMobilePhoneModalFlag(true)}
                >
                    <div className="d-flex justify-between slign-center">
                        <span className="body-medium">{formatMessage(messages.mobileTileTitle)}</span>
                        <span className="next-arrow mb-4">
                            <ArrowNext />
                        </span>
                    </div>
                    <div className="equal description">
                        <div className="my-0 text-16 fw-600">{formatMessage(messages.mobileTileContent)}</div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default WrappedMobileModal;

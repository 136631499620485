
const TokenActionTypes = {
    FETCH_TOKEN_START: "FETCH_TOKEN_START",
    FETCH_TOKEN_SUCCESS: "FETCH_TOKEN_SUCCESS",
    FETCH_TOKEN_FAILURE: "FETCH_TOKEN_FAILURE",

    FETCH_PROJECT_TOKEN_START: "FETCH_PROJECT_TOKEN_START",
    FETCH_PROJECT_TOKEN_SUCCESS: "FETCH_PROJECT_TOKEN_SUCCESS",
    FETCH_PROJECT_TOKEN_FAILURE: "FETCH_PROJECT_TOKEN_FAILURE",
};

export default TokenActionTypes;

import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useFormattedMessage } from "hooks";
import { toGermanCurrency, toEnglishCurrency } from "utils";
import { numberRegexAcceptDotAndComma } from "constants/validationRegex";
import { selectLocale } from "store/app/selectors";

import Input from "components/Input";

const messages = {
    amountInputPlaceholder: "enter_amount_placeholder",
};

const CurrencyInput = ({
    className,
    inputSymbolClass,
    inputClassName,
    id,
    name,
    hiddenInputName,
    label,
    sublabel,
    placeholder,
    inputSymbol,
    onCurrencyInputChange,
    values,
    onBlur,
    errorMessage,
    error,
    locale,
}) => {
    const { formatMessage } = useFormattedMessage();

    const toCurrency = (e) => {
        const { value } = e.target;
        if (!numberRegexAcceptDotAndComma.test(value)) return;
        const { formattedAmount, resetAmount } = locale === "en" ? toEnglishCurrency(value) : toGermanCurrency(value);

        onCurrencyInputChange(formattedAmount, resetAmount);
    };

    return (
        <>
            {hiddenInputName && <input name={hiddenInputName} type="hidden" value={values[hiddenInputName]} />}
            <Input
                className={className}
                inputSymbolClass={inputSymbolClass}
                inputClassName={inputClassName}
                id={id}
                type="text"
                name={name}
                label={label}
                sublabel={sublabel}
                placeholder={placeholder || formatMessage(messages.amountInputPlaceholder)}
                inputSymbol={inputSymbol || "€"}
                onChange={(e) => toCurrency(e)}
                value={values[name]}
                onBlur={onBlur}
                errorMessage={errorMessage}
                error={error}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps)(CurrencyInput);

const ProjectActionTypes = {
    FETCH_PROJECT_START: "FETCH_PROJECT_START",
    FETCH_PROJECT_SUCCESS: "FETCH_PROJECT_SUCCESS",
    FETCH_MARKETPLACE_PROJECT_SUCCESS: "FETCH_MARKETPLACE_PROJECT_SUCCESS",
    FETCH_PROJECT_FAILURE: "FETCH_PROJECT_FAILURE",

    FETCH_LIMITED_PROJECT_START: "FETCH_LIMITED_PROJECT_START",
    FETCH_LIMITED_PROJECT_SUCCESS: "FETCH_LIMITED_PROJECT_SUCCESS",
    FETCH_LIMITED_PROJECT_FAILURE: "FETCH_LIMITED_PROJECT_FAILURE",

    SET_SELECTED_PROJECT_ID: "SET_SELECTED_PROJECT_ID",
};

export default ProjectActionTypes;

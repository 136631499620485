import { put, takeLatest, call, all } from "redux-saga/effects";

import { fetchProductCatalogProjects, fetchProductCatalogProjectsLimited } from "api";
import ProjectActionTypes from "./types";
import { fetchProjectSuccess, fetchProjectFailure, fetchProjectLimitedSuccess, fetchProjectLimitedFailure } from "./actions";

function* fetchProjectAsync({ payload }) {
    const { count = 0, projectID = "" } = payload || {};
    try {
        const response = yield call(fetchProductCatalogProjects, count, projectID);
        yield put(fetchProjectSuccess(response.data.data.productCatalogProjects.projects));
    } catch (err) {
        yield put(fetchProjectFailure(err));
    }
}

function* fetchProjectLimitedDataAsync({ payload }) {
    const { count = 0, projectID = "" } = payload || {};
    try {
        const response = yield call(fetchProductCatalogProjectsLimited, count, projectID);
        yield put(fetchProjectLimitedSuccess(response.data.data.productCatalogProjects.projects));
    } catch (err) {
        yield put(fetchProjectLimitedFailure(err));
    }
}

function* fetchProjectSaga() {
    yield takeLatest(ProjectActionTypes.FETCH_PROJECT_START, fetchProjectAsync);
}
function* fetchProjectLimitedDataSaga() {
    yield takeLatest(ProjectActionTypes.FETCH_LIMITED_PROJECT_START, fetchProjectLimitedDataAsync);
}
export function* projectSagas() {
    yield all([call(fetchProjectSaga), call(fetchProjectLimitedDataSaga)]);
}

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchUserDetailsStart, userDashboardDetailsStart, setUserMobileData } from "store/user/account/actions";
import { selectUserMobileDetails } from "store/user/account/selectors";
import SuccessAnimation from "components/SuccessAnimation";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";

const messages = {
    heading: "thank_you_heading",
    subheading: "your_information_saved",
    content: "click_exit_will_take_you_back",
    buttonText: "exit",
};

const MobileSuccess = ({
    className = "",
    onCloseButtonClick,
    setMobileSuccessFlag,
    fetchUserDetailsStart,
    userDashboardDetailsStart,
    setUserMobileData,
    userMobileData,
    backSlideEffect = "",
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setMobileSuccessFlag && setMobileSuccessFlag();
        fetchUserDetailsStart();
    }, []);

    return (
        <div className={`mobile-tan-container mobile-tan-success text-center ${className} ${backSlideEffect}`}>
            <h3>{formatMessage(messages.heading)}</h3>
            <p>{formatMessage(messages.subheading)}</p>
            <SuccessAnimation />
            <p>{formatMessage(messages.content)}</p>
            <button
                className="mb-0 mw-100"
                onClick={() => {
                    setUserMobileData({ ...userMobileData, step: 1 });
                    onCloseButtonClick && onCloseButtonClick();
                    setTimeout(() => {
                        userDashboardDetailsStart();
                    }, 5000);
                }}
            >
                {formatMessage(messages.buttonText)}
            </button>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userMobileData: selectUserMobileDetails,
});

const mapDispatchToProps = (dispatch) => ({
    setUserMobileData: (data) => dispatch(setUserMobileData(data)),
    fetchUserDetailsStart: () => dispatch(fetchUserDetailsStart()),
    userDashboardDetailsStart: () => dispatch(userDashboardDetailsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSuccess);

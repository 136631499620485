import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy, selectFetchAdequacy } from "store/user/adequacy/selectors";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import TwoCellRow from "components/TwoCellRow";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import {
    HIGHEST_EDUCATION_DATA,
    CURRENT_JOB_DATA,
    yearsOptions,
    transactionSizesOptions,
    transactionYearsOptions,
    investmentServicesYearsOptions,
} from "constants/index";
import { splitDateStringForProperDate, formateDate } from "utils";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import IntlFormattedMessage from "components/IntlFormattedMessage";

const messages = {
    none: "none",
    heading: "experience_and_kowledge",
    subheading: "are_your_details_upto_date",
    subHeadingOverviewOnly: "overview_of_your_information",
    renewText: "renew",
    jobQuestion: "profession_currently_practiced",
    educationQuestion: "highest_degree",
    knowledgeFinancialQuestion: "your_knowledge_of_financial_investments",
    knowledgeFinancialQuestionItem1: "investment_funds",
    knowledgeFinancialQuestionItem2: "bonds",
    knowledgeFinancialQuestionItem3: "adequacy_financial_shares",
    knowledgeFinancialQuestionItem4: "adequacy_participation_rights",
    knowledgeFinancialQuestionItem5: "knowledge_financial_question",
    securityServicesQuestion: "your_experience_with_securities_services",
    securityServicesQuestionItem1: "free_of_advice",
    securityServicesQuestionItem2: "investment_advice",
    securityServicesQuestionItem3: "wealth_managment",
    investmentServicesQuestion: "your_expertise_in_number_and_volume",
    investmentServicesQuestionItem1: "number_of_transaction_per_year",
    investmentServicesQuestionItem2: "equivalent_value_per_transaction",
    wantedBottomNoteText: "nothing_changed_in_experience_knowledge",
    notWantedBottomNoteText: "can_assess_the_risk",
    notWantedContent: "about_your_experience_and_knowledge",
    endedText: "adequacy_test_ended_without_information",
    endedTextAnswer: "not_specified",
    experienceAndKnowledgeChanged: "have_your_experience_and_knowledge",
};

const INITIAL_TEST_ANSWER = {
    survey_first_step: {},
    survey_second_step: {
        bonds: {},
        investment_funds: {},
        shares: {},
        sharing_rights: {},
        subordinated: {},
    },
    survey_third_step: {
        securities: {},
        transactions: {},
    },
};

const OverviewStep = ({
    className = "",
    userDashboardData = {},
    // setAdequacyRequest,
    adequacyData,
    fetchAdequacyData,
    onPropsBack,
    onPropsSubmit,
    onRenew,
    backAndCountinueDisabled,
    backSlideEffect = "",
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const { submitted_date, ...rest } = adequacyData;
    const { IsNotWantedConfirmed, IsNotWantedConfirmedDate } = fetchAdequacyData;
    const adequacyTestAnswer = rest || INITIAL_TEST_ANSWER;

    const currentJob = CURRENT_JOB_DATA().find((item) => item.value === adequacyTestAnswer.survey_first_step.TypeJob);
    const highestEducation = HIGHEST_EDUCATION_DATA().find(
        (item) => item.value === adequacyTestAnswer.survey_first_step.TypeEducation
    );

    const getBonds = adequacyTestAnswer.survey_second_step.bonds;
    const getBondsDuration = getBonds && getBonds.IsBondsXPConfirmedDate;
    const bonds = yearsOptions().find((x) => x.value === (getBondsDuration && getBondsDuration.value)) || "";

    const getInvestmentFunds = adequacyTestAnswer.survey_second_step.investment_funds;
    const getInvestFundsDuration = getInvestmentFunds && getInvestmentFunds.IsInvestFundsXPConfirmedDate;
    const investment_funds =
        yearsOptions().find((x) => x.value === (getInvestFundsDuration && getInvestFundsDuration.value)) || "";

    const getShares = adequacyTestAnswer.survey_second_step.shares;
    const getSharesDuration = getShares && getShares.IsSharesXPConfirmedDate;
    const shares = yearsOptions().find((x) => x.value === (getSharesDuration && getSharesDuration.value)) || "";

    const getSharingRights = adequacyTestAnswer.survey_second_step.sharing_rights;
    const getProfitPartDuration = getSharingRights && getSharingRights.IsProfitPartXPConfirmedDate;
    const sharing_rights = yearsOptions().find((x) => x.value === (getProfitPartDuration && getProfitPartDuration.value)) || "";

    const getSubordinated = adequacyTestAnswer.survey_second_step.subordinated;
    const getSubordLoanDuration = getSubordinated && getSubordinated.IsSubordLoanXpConfirmedDate;
    const subordinated = yearsOptions().find((x) => x.value === (getSubordLoanDuration && getSubordLoanDuration.value)) || "";

    const getSecurities = adequacyTestAnswer.survey_third_step.securities;

    const getBondsXPAssisted = getSecurities && getSecurities.BondsXPAssisted;
    const asset_management =
        investmentServicesYearsOptions().find((x) => x.value === (getBondsXPAssisted && getBondsXPAssisted.value)) || "";

    const getBondsXPIndependent = getSecurities && getSecurities.BondsXPIndependent;
    const independent_advice =
        investmentServicesYearsOptions().find((x) => x.value === (getBondsXPIndependent && getBondsXPIndependent.value)) || "";

    const getBondsXPConsulted = getSecurities && getSecurities.BondsXPConsulted;
    const investment_advice =
        investmentServicesYearsOptions().find((x) => x.value === (getBondsXPConsulted && getBondsXPConsulted.value)) || "";

    const getTransactions = adequacyTestAnswer.survey_third_step.transactions;

    const getBondsTransactions = getTransactions && getTransactions.BondsTransactions;
    const transaction_years =
        transactionYearsOptions().find((x) => x.value === (getBondsTransactions && getBondsTransactions.value)) || "";

    const getBondsAmount = getTransactions && getTransactions.BondsAmount;
    const transaction_sizes = transactionSizesOptions().find((x) => x.value === (getBondsAmount && getBondsAmount.value)) || "";

    let newSubmittedDate, newNotWantedDate;
    if (!IsNotWantedConfirmed) {
        if (submitted_date === undefined) {
            newSubmittedDate = "";
        } else {
            if (typeof submitted_date === "string") {
                newSubmittedDate = new Date(...splitDateStringForProperDate(submitted_date));
            } else {
                newSubmittedDate = submitted_date;
            }
        }
        newSubmittedDate = newSubmittedDate ? formateDate(newSubmittedDate, "date", "month", "year", ".") : "";
    } else {
        if (IsNotWantedConfirmedDate === undefined) {
            newNotWantedDate = "";
        } else {
            if (typeof IsNotWantedConfirmedDate === "string") {
                newNotWantedDate = new Date(...splitDateStringForProperDate(IsNotWantedConfirmedDate));
            } else {
                newNotWantedDate = IsNotWantedConfirmedDate;
            }
        }
        newNotWantedDate = newNotWantedDate ? formateDate(newNotWantedDate, "date", "month", "year", ".") : "";
    }
    return (
        <>
            <div className={`${className} adequacy-container third-step ${backSlideEffect}`}>
                <div className="header-part">
                    {!backAndCountinueDisabled && <h3>{formatMessage(messages.heading)}</h3>}
                    <p>
                        {backAndCountinueDisabled
                            ? formatMessage(messages.subHeadingOverviewOnly)
                            : formatMessage(messages.subheading)}
                    </p>
                    <div className="border-x" />
                </div>
                {!IsNotWantedConfirmed ? (
                    <div className="content">
                        <TwoCellRow
                            containerClassname="action-container mb-4"
                            renderLeft={() => <span className="text-14 date">{newSubmittedDate}</span>}
                            rClassname="change primary-color text-16 d-block"
                            removeRClass
                            renderRight={backAndCountinueDisabled ? () => {} : ""}
                            rClick={() => onRenew(userDashboardData, adequacyData)}
                            right={formatMessage(messages.renewText)}
                        />
                        <TwoCellRow
                            removeContainerClass
                            containerClassname="part mb-5"
                            left={formatMessage(messages.jobQuestion)}
                            lClassname="d-block"
                            removeLClass
                            right={currentJob && currentJob.label}
                        />
                        <TwoCellRow
                            removeContainerClass
                            containerClassname="part mb-5"
                            left={formatMessage(messages.educationQuestion)}
                            lClassname="d-block"
                            removeLClass
                            right={highestEducation && highestEducation.label}
                        />
                        <div className="part mb-5">
                            <label className="d-block mb-1">{formatMessage(messages.knowledgeFinancialQuestion)}</label>
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.knowledgeFinancialQuestionItem1)} - `}
                                removeLClass
                                right={(investment_funds && investment_funds.label) || formatMessage(messages.none)}
                            />
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.knowledgeFinancialQuestionItem2)} - `}
                                removeLClass
                                right={(bonds && bonds.label) || formatMessage(messages.none)}
                            />
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.knowledgeFinancialQuestionItem3)} - `}
                                removeLClass
                                right={(shares && shares.label) || formatMessage(messages.none)}
                            />
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.knowledgeFinancialQuestionItem4)} - `}
                                removeLClass
                                right={(sharing_rights && sharing_rights.label) || formatMessage(messages.none)}
                            />
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.knowledgeFinancialQuestionItem5)} - `}
                                removeLClass
                                right={(subordinated && subordinated.label) || formatMessage(messages.none)}
                            />
                        </div>
                        <div className="part mb-5">
                            <label className="d-block mb-1">{formatMessage(messages.securityServicesQuestion)}</label>
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.securityServicesQuestionItem1)} - `}
                                removeLClass
                                right={(independent_advice && independent_advice.label) || formatMessage(messages.none)}
                            />
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.securityServicesQuestionItem2)} - `}
                                removeLClass
                                right={(investment_advice && investment_advice.label) || formatMessage(messages.none)}
                            />
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.securityServicesQuestionItem3)} - `}
                                removeLClass
                                right={(asset_management && asset_management.label) || formatMessage(messages.none)}
                            />
                        </div>
                        <div className="part mb-5">
                            <label className="d-block mb-1">{formatMessage(messages.investmentServicesQuestion)}</label>
                            <TwoCellRow
                                removeContainerClass
                                left={`- ${formatMessage(messages.investmentServicesQuestionItem1)} - `}
                                removeLClass
                                right={(transaction_years && transaction_years.label) || formatMessage(messages.none)}
                            />
                            {transaction_years && transaction_years.label != "none" && (
                                <TwoCellRow
                                    removeContainerClass
                                    left={`- ${formatMessage(messages.investmentServicesQuestionItem2)} - `}
                                    removeLClass
                                    right={(transaction_sizes && transaction_sizes.label) || formatMessage(messages.none)}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="content">
                        <TwoCellRow
                            containerClassname="action-container mb-4"
                            renderLeft={() => <span className="text-14 date">{newNotWantedDate}</span>}
                            rClassname="change primary-color text-16 d-block"
                            removeRClass
                            renderRight={backAndCountinueDisabled ? () => {} : ""}
                            rClick={() => onRenew(userDashboardData, adequacyData)}
                            right={formatMessage(messages.renewText)}
                        />
                        <TwoCellRow
                            removeContainerClass
                            containerClassname="part mb-5"
                            left={formatMessage(messages.endedText)}
                            lClassname="d-block"
                            removeLClass
                            right={formatMessage(messages.endedTextAnswer)}
                        />
                        <div className="part mb-5">
                            <IntlFormattedMessage id={messages.notWantedContent} />
                        </div>
                    </div>
                )}
                <div className="border-x" />
                <p>
                    {backAndCountinueDisabled
                        ? formatMessage(messages.experienceAndKnowledgeChanged)
                        : !IsNotWantedConfirmed
                        ? formatMessage(messages.wantedBottomNoteText)
                        : formatMessage(messages.notWantedBottomNoteText)}
                </p>
            </div>

            {backAndCountinueDisabled && (
                <button className="mw-100 mt-7" onClick={() => onRenew(userDashboardData, adequacyData)}>
                    {formatMessage(messages.renewText)}
                </button>
            )}

            {(backAndCountinueDisabled !== true || !backAndCountinueDisabled) && (
                <div className="bottom-bar-container">
                    <PrevButton onClick={onPropsBack} />
                    <NextButton onClick={onPropsSubmit} />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
    fetchAdequacyData: selectFetchAdequacy,
    userDashboardData: selectUserDashboardDetails,
});

export default connect(mapStateToProps)(OverviewStep);

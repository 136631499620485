/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import Head from "next/head";
import Cookies from "js-cookie";
import { createStructuredSelector } from "reselect";
import Header from "components/MultiAsset/Header";
import Footer from "components/Footer";
import DashBoardHeader from "components/DashBoardHeader";
import ToolTipBottomSheet from "components/ToolTipBottomSheet";

import { stringToHTML, injectNoScript, getTenantAssets } from "utils/index";

import {
    PATHS_WITH_DASHBOARD_VISIBLE,
    BLOCKED_PATHS_FOR_PROD,
    DELETED_PATHS,
    PATHS_WITH_FOOTER_HIDDEN,
    PATHS_WITH_NOT_DEFAULT_OG_IMAGE,
    PRIVATE_ROUTE,
    WALLET_TYPE,
} from "constants/index";
import { MANGOPAY_STATUS } from "constants/status";

import { fetchPayInWalletBalanceStart, fetchBlockedWalletBalanceStart } from "store/user/account/actions";
import {
    selectCurrentTenant,
    selectFinxConfig,
    selectIsCurrentTenantFinexity,
    selectPlatformSourceFromApp,
    selectLocale,
} from "store/app/selectors";
import {
    selectIsLoggedIn,
    selectIsUserAuthenticated,
    selectIsUserFetched,
    selectStatusMangopay,
    selectUserRoles,
    selectUserStatusEmail,
    selectUserTermsAndConditionsAccepted,
    selectIsUserStatusEmailVerified,
    selectIsUserOnboardingCompleted,
} from "store/user/account/selectors";

import { useUtils } from "hooks";
import { I18nextProvider, withSSR } from "react-i18next";
import i18next from "i18n/i18n";

const FINX_COOKIE = require("common/constants");
const FINX_COMMON_CONFIG = require("common/config").common;

const IntlProvider = ({
    notSupportedBrowser = false,
    children,
    customLocale,
    router: history,
    isLoggedIn,
    isAuthenticated,
    statusMangopay,

    fetchPayInWalletBalanceStart,
    fetchBlockedWalletBalanceStart,
    finxConfig,
    isApp,
    isUserFetched,
    tenant,
    isCurrentTenantFinexity,
    isUserStatusEmailVerified,
    isUserOnboardingCompleted,
    isUserTermsAndConditionsAccepted,
}) => {
    const router = useRouter();
    const { pathname, asPath } = router;

    const isProdEnv = useUtils().isProdEnv;
    const documentHost = useUtils().documentHost;

    const isDevEnv = finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT === "dev";
    const isTestEnv = finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT === "test";
    const isLocalEnv = finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT === "local";

    const [isCookieAccepted, setIsCookieAccepted] = useState(false);

    const setCookieFirstConsent = () => (isDevEnv || isProdEnv) && setIsCookieAccepted(true);

    const cookieFirstConsentHandler = () => {
        setCookieFirstConsent();
        triggeredGtmCookieFirstEvents();
    };

    useEffect(() => {
        const isCookieConsentAccepted = !!(Cookies.get("cookiefirst-consent") && localStorage.getItem("cookiefirst-consent"));
        if (isCookieConsentAccepted) setCookieFirstConsent();

        window.addEventListener("cf_consent", cookieFirstConsentHandler);
        return () => window.removeEventListener("cf_consent", cookieFirstConsentHandler);
    }, []);

    const triggeredGtmCookieFirstEvents = () => {
        if (typeof dataLayer !== "undefined") {
            dataLayer.push({ event: "cf_consent_necessary" });
            dataLayer.push({ event: "cf_consent_advertising" });
            dataLayer.push({ event: "cf_consent_performance" });
            dataLayer.push({ event: "cf_consent_functional" });
        }
    };

    useEffect(() => {
        // Append Google Tag Manager script in body
        if (isCookieAccepted) {
            const newItem = document.createElement("noscript");
            const textnode = document.createTextNode(
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59MBX97" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            );
            newItem.appendChild(textnode);

            (document.body.firstChild.id === "__next" || document.body.firstChild.getAttribute("data-cookiefirst-key")) &&
                document.body.insertBefore(newItem, document.body.firstChild);
        }
    }, [isCookieAccepted]);

    useEffect(() => {
        if (isProdEnv || isTestEnv) {
            const isPageBlocked = !!BLOCKED_PATHS_FOR_PROD.find((path) => pathname.startsWith(path));
            isProdEnv && isPageBlocked && router.push("/404");
        }

        if (DELETED_PATHS.find((path) => pathname.startsWith(path))) {
            router.push("/404");
        }
    }, [pathname, asPath]);

    // Wallet balance API call for web and app
    useEffect(() => {
        // Check to refresh the wallet balance
        const isUserFirstLogin =
            isApp && history?.query?.refreshBalance === "true" ? "true" : localStorage.getItem(FINX_COOKIE.IS_USER_INITIAL_LOGIN);
        const refreshBalance = !!(isUserFirstLogin === "true" || isUserFirstLogin === 1);

        if (
            isAuthenticated &&
            statusMangopay &&
            (statusMangopay === MANGOPAY_STATUS.ACTIVE || statusMangopay === MANGOPAY_STATUS.BLOCKED)
        ) {
            fetchPayInWalletBalanceStart({ type: WALLET_TYPE.PAY_IN_WALLET, refreshBalance });
            fetchBlockedWalletBalanceStart({ type: WALLET_TYPE.BLOCKED_WALLET, refreshBalance });

            // Remove the local storage
            !isApp &&
                setTimeout(() => {
                    localStorage.removeItem(FINX_COOKIE.IS_USER_INITIAL_LOGIN);
                }, 1000);
        }
    }, [isAuthenticated, statusMangopay]);

    useEffect(() => {
        if (tenant?.ConsentManagerHead) {
            $("head").append(stringToHTML(tenant?.ConsentManagerHead).toString());
        }
    }, [tenant?.ConsentManagerHead, finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT]);

    useEffect(() => {
        if (finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT !== "local" && tenant?.ConsentManagerBody) {
            injectNoScript(stringToHTML(tenant?.ConsentManagerBody));
        }
    }, [tenant?.ConsentManagerBody, finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT]);

    const isNotApplyDefaultOgImage = !!PATHS_WITH_NOT_DEFAULT_OG_IMAGE.find((path) => pathname == path);
    const isDashBoardHeaderVisible = !!PATHS_WITH_DASHBOARD_VISIBLE.find((path) => pathname == path);
    const isFooterVisible = !PATHS_WITH_FOOTER_HIDDEN.find((path) => pathname == path);
    const isForceUpdatePath = pathname.includes("force-update");

    let isUserOnPagePrivate = false;
    let isUserHaveEmailConfirmation = true;
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (PRIVATE_ROUTE.findIndex((route) => pathname.search(route) > -1) > -1) {
        isUserOnPagePrivate = true;
        if (
            !pathname.includes("retail/accept-terms-conditions") &&
            !pathname.includes("email-confirmation") &&
            !pathname.includes("user-onboarding") &&
            isUserFetched &&
            (!isUserStatusEmailVerified || !isUserOnboardingCompleted || !isUserTermsAndConditionsAccepted)
        ) {
            isUserHaveEmailConfirmation = false;
        }
    }

    const isAllowToShowPrivatePage = isUserOnPagePrivate ? isAuthenticated : true;

    const ogImage = tenant?.OGImage && getTenantAssets(documentHost, tenant?.TechnicalName).tenantOGImage;

    return (
        <>
            {isCookieAccepted && (
                <Head>
                    {/*<script
                        dangerouslySetInnerHTML={{
                            __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=Q9dtJ1ZEiTV4wN8lnviMxw&gtm_preview=env-116&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-59MBX97');
                        `,
                        }}
                    />*/}
                </Head>
            )}
            {!isNotApplyDefaultOgImage && (
                <Head>
                    <meta property="og:title" content={customLocale === "en" ? tenant?.OGTitle_EN : tenant?.OGTitle_DE} />
                    <meta
                        property="og:description"
                        content={customLocale === "en" ? tenant?.OGDescription_EN : tenant?.OGDescription_DE}
                    />
                    {isCurrentTenantFinexity ? (
                        <meta property="og:image" content={ogImage || `/docs/social/FacebookOG.png`} />
                    ) : (
                        <meta property="og:image" content={ogImage} />
                    )}
                </Head>
            )}
            {!isApp && isCurrentTenantFinexity && !isLocalEnv && (
                <Head>
                    {/* App Banner */}
                    <meta name="smartbanner:title" content="FINEXITY App" />
                    <meta name="smartbanner:author" content="FINEXITY" />
                    <meta name="smartbanner:price" content={customLocale === "en" ? "Free" : "Gratis"} />
                    <meta
                        name="smartbanner:price-suffix-apple"
                        content={` - ${customLocale === "en" ? "Download now" : "jetzt downloaden"}`}
                    />
                    <meta
                        name="smartbanner:price-suffix-google"
                        content={` - ${customLocale === "en" ? "Download now" : "jetzt downloaden"}`}
                    />
                    <meta name="smartbanner:icon-apple" content="/images/finexity-store-icon.png" />
                    <meta name="smartbanner:icon-google" content="/images/finexity-store-icon.png" />
                    <meta name="smartbanner:button" content={customLocale === "en" ? "VIEW" : "ANZEIGEN"} />
                    <meta name="smartbanner:button-url-apple" content={FINX_COMMON_CONFIG.WEBSITE_IOS_PAGE} />
                    <meta name="smartbanner:button-url-google" content={FINX_COMMON_CONFIG.WEBSITE_ANDROID_PAGE} />
                    <meta name="smartbanner:enabled-platforms" content="android, ios" />
                    {/* (android, ios) */}
                    <meta name="smartbanner:close-label" content="Close" />
                    {/* End */}

                    {/* Smart Banner */}
                    <script src="/js/smartbanner.min.js" />
                    <link href="/css/smartbanner.min.css" rel="stylesheet" />
                    <script src="/js/smart-banner.js" />
                </Head>
            )}
            <I18nextProvider i18n={i18next} defaultNS={"translation"}>
                <div className={`theme-wrapper ${isApp ? "app-light-theme" : "light-theme"}`}>
                    {!notSupportedBrowser &&
                        !isApp &&
                        (isUserHaveEmailConfirmation && isUserTermsAndConditionsAccepted && isUserOnboardingCompleted ? (
                            isDashBoardHeaderVisible && isLoggedIn ? (
                                <DashBoardHeader />
                            ) : (
                                <Header />
                            )
                        ) : (
                            <Header />
                        ))}
                    <div
                        className={`main-app-container broad-container ${isApp ? "is-app" : ""} ${
                            isForceUpdatePath ? "force-update-main" : ""
                        }`}
                    >
                        {isUserHaveEmailConfirmation && isAllowToShowPrivatePage ? React.cloneElement(children, {}) : null}
                    </div>
                    {isApp || (typeof window === "undefined" ? 768 : window.innerWidth) <= 767 ? <ToolTipBottomSheet /> : null}
                    {isFooterVisible && !notSupportedBrowser && !isApp ? (
                        <Footer isCurrentTenantFinexity={isCurrentTenantFinexity} />
                    ) : null}
                </div>
            </I18nextProvider>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    customLocale: selectLocale,
    isLoggedIn: selectIsLoggedIn,
    isAuthenticated: selectIsUserAuthenticated,
    statusMangopay: selectStatusMangopay,
    userStatusEmail: selectUserStatusEmail,
    userRoles: selectUserRoles,
    isApp: selectPlatformSourceFromApp,
    finxConfig: selectFinxConfig,
    isUserFetched: selectIsUserFetched,
    isCurrentTenantFinexity: selectIsCurrentTenantFinexity,
    isUserStatusEmailVerified: selectIsUserStatusEmailVerified,
    isUserTermsAndConditionsAccepted: selectUserTermsAndConditionsAccepted,
    isUserOnboardingCompleted: selectIsUserOnboardingCompleted,
    tenant: selectCurrentTenant,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPayInWalletBalanceStart: (value) => dispatch(fetchPayInWalletBalanceStart(value)),
    fetchBlockedWalletBalanceStart: (value) => dispatch(fetchBlockedWalletBalanceStart(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSSR()(IntlProvider));

import DocumentsActionTypes from "./types";

export const fetchDocumentsStart = (offset, limit, TypeDocument, Path, result) => ({
    type: DocumentsActionTypes.FETCH_DOCUMENTS_START,
    payload: {
        offset,
        limit,
        TypeDocument,
        Path,
        result,
    },
});

export const fetchDocumentsSuccess = (documents, totalDocuments) => ({
    type: DocumentsActionTypes.FETCH_DOCUMENTS_SUCCESS,
    payload: { documents, totalDocuments },
});

export const fetchDocumentsFailure = (error) => ({
    type: DocumentsActionTypes.FETCH_DOCUMENTS_FAILURE,
    payload: error,
});

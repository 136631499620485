import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy } from "store/user/adequacy/selectors";
import { setAdequacyStart } from "store/user/adequacy/actions";
import { useFormattedMessage } from "hooks";
import CurrentJob from "./CurrentJob";
import HighestEducation from "./HighestEducation";

const messages = {
    jobHeading: "question_about_the_profession",
    jobQuestion: "what_is_your_current_job",
    educationHeading: "question_about_education",
    educationQuestion: "highest_level_of_education",
};

const JobEducationStep = ({
    className = "",
    setAdequacyStart,
    adequacyData,
    changeStepBack,
    hitButtonBack,
    backWorked,
    setBackWorked,
    labelIDPrefix,
    backSlideEffect = "",
    setBackSlideEffect,
    open,
    setPreviousStep,
    StatusAdequacy,
}) => {
    const { formatMessage } = useFormattedMessage();

    const activeStep = adequacyData.survey_first_step.step || 1;
    const [formData, setFormData] = useState(adequacyData.survey_first_step);

    useEffect(() => {
        if (changeStepBack && !backWorked) {
            hitButtonBack();
            if (activeStep === 1) {
                setAdequacyStart({ step: 1 });
            } else {
                setAdequacyStart({
                    step: 4,
                    survey_first_step: {
                        ...adequacyData.survey_first_step,
                        step: 1,
                    },
                });
            }
        } else if (backWorked) {
            setBackWorked();
        }
    }, [changeStepBack]);

    useEffect(() => {
        setFormData(adequacyData.survey_first_step);
    }, [adequacyData]);

    const renderStep = (step) => {
        switch (step) {
            case 2:
                return (
                    <HighestEducation
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        formatMessage={formatMessage}
                        messages={messages}
                        labelIDPrefix={labelIDPrefix}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 1:
            default:
                return (
                    <CurrentJob
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        formData={formData}
                        setFormData={setFormData}
                        setAdequacyStart={setAdequacyStart}
                        adequacyData={adequacyData}
                        formatMessage={formatMessage}
                        messages={messages}
                        labelIDPrefix={labelIDPrefix}
                        setPreviousStep={setPreviousStep}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
        }
    };

    return <div className={`${className} adequacy-container fourth-step`}>{renderStep(activeStep)}</div>;
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobEducationStep);

const AppActionTypes = {
    SUBSCRIBE: "SUBSCRIBE",
    PROPERTY_FILTERS: "PROPERTY_FILTERS",
    PROPERTY_SORT: "PROPERTY_SORT",
    SET_ENTERED_URL: "SET_ENTERED_URL",
    SET_PLATFORM_SOURCE: "SET_PLATFORM_SOURCE",
    MPIN: "MPIN",
    IS_CLUBDEAL: "IS_CLUBDEAL",
    MOBILE_NOTIFICATION: "MOBILE_NOTIFICATION",
    SET_TENANT: "SET_TENANT",
    SET_FINX_CONFIG: "SET_FINX_CONFIG",
    SET_HOST: "SET_HOST",
    SET_MOBILE_PLATFORM: "SET_MOBILE_PLATFORM",
    SET_DOCUMENT_HOST: "SET_DOCUMENT_HOST",
    FETCH_TENANT_START: "FETCH_TENANT_START",
    FETCH_TENANT_SUCCESS: "FETCH_TENANT_SUCCESS",
    FETCH_TENANT_FAILURE: "FETCH_TENANT_FAILURE",

    //locale
    SET_LOCALE: "SET_LOCALE",
    PERSIST_REHYDRATE: "persist/REHYDRATE",
};

export default AppActionTypes;

const DashboardActionTypes = {
    FETCH_DASHBOARD_DETAILS_START: "FETCH_DASHBOARD_DETAILS_START",
    FETCH_DASHBOARD_DETAILS_SUCCESS: "FETCH_DASHBOARD_DETAILS_SUCCESS",
    FETCH_DASHBOARD_DETAILS_FAILURE: "FETCH_DASHBOARD_DETAILS_FAILURE",

    FETCH_USER_DASHBOARD_DETAILS_START: "FETCH_USER_DASHBOARD_DETAILS_START",
    FETCH_USER_DASHBOARD_DETAILS_SUCCESS: "FETCH_USER_DASHBOARD_DETAILS_SUCCESS",
    FETCH_USER_DASHBOARD_DETAILS_FAILURE: "FETCH_USER_DASHBOARD_DETAILS_FAILURE",

    SET_TIPSTER_DATA: "SET_TIPSTER_DATA",
};

export default DashboardActionTypes;

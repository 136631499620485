import App from "next/app";
import React from "react";
import { Provider } from "react-redux";
import debounce from "lodash/debounce";
import { compose } from "recompose";
import Router from "next/router";
import withRedux from "next-redux-wrapper";
import { UserAgentProvider } from "@quentin-sommer/react-useragent";

import "react-datepicker/dist/react-datepicker.css";
import "react-intl-tel-input/dist/main.css";
import "assets/style/general.scss";

import {
    storeAuthTokenData,
    logout,
    exitInheritUser,
    fetchUserAllDetailsStart,
    fetchUserDetailsFailure,
} from "store/user/account/actions";
import initializeStore from "store/create-store";
import { fetchTenantStart, fetchTenantSuccess } from "store/app/actions";
import { fetchProjectLimitedStart } from "store/project/actions";
import IntlProvider from "components/IntlProvider";
import CheckUserRole from "components/CheckUserRole";
import EmailNotFoundInToken from "components/HOC/EmailNotFoundInToken";

import OldBrowserInfo from "components/OldBrowserInfo";
import AppTabBar from "components/AppTabBar";

import SingletonApi from "api/SingletonApi";
import { getTenant } from "api/index";
import i18n from "i18next";
import {
    PRIVATE_ROUTE,
    PREVENT_ROUTE_WITH_TOKEN,
    ADMIN_ROUTE_WITH_TOKEN,
    UMBRELLA_ROUTE_WITH_TOKEN,
    USER_ROLES,
    BROKER_ROUTE_WITH_TOKEN,
    CLUBDEALS_ROUTE_WITH_TOKEN,
    ALLOWED_PATHS_FOR_MOBILE,
    TENANT_ROUTE_WITH_TOKEN,
    TENANT_ALLOWED_ROUTE,
    API_RESPONSE_CODE,
    API_UNAUTHORIZED_MSG,
    TENANTS_LIST,
    RESPONSE_ERRORS,
} from "constants/index";

import Auth from "utils/auth0";
import { checkBrowserNotSupported, getAccessToken, parseRolesFromCookie, isClient, getFnxtAccessToken } from "utils/index";
import { configureRequest, configureSentryUser } from "utils/sentry";

import UtilContextComponent from "components/UtilContextComponent";
import i18nInstance from "i18n/i18n";
import { initReactI18next } from "react-i18next";
import FnxtSingletonApi from "api/FnxtSingletonApi";
import { callTheInitialKYCMethods } from "store/user/kyc/actions";

const FINX_COOKIE = require("common/constants");
let Cookies = null;

if (isClient()) {
    Cookies = require("js-cookie");

    import("smoothscroll-polyfill").then((smoothScrollPolyfill) => {
        smoothScrollPolyfill.polyfill();
        window.__forceSmoothScrollPolyfill__ = true;
    });
}

let isTenantApiCalled = false;

class MyApp extends App {
    static async getInitialProps({ Component, ctx }) {
        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        const { req, store } = ctx;

        const storeState = store.getState();

        if (req) {
            // Configuring sentry scope for current request for proper error stacktrace
            configureRequest(req);
            SingletonApi.setBaseURL(req.baseApiUrl);
            SingletonApi.setHeader("finexity-tenant", storeState.app.tenant);
        }

        if (!storeState.app?.currentTenant?.LegalName) {
            if (isClient() && isTenantApiCalled) {
            } else {
                isTenantApiCalled = true;
                await getTenant().then((res) => {
                    store.dispatch(fetchTenantSuccess({ tenant: res?.data?.data?.tenant?.tenant }));
                });
            }
        }
        const initialNow = Date.now();
        const tenant = storeState.app.tenant || (req && req.tenant);
        const locale = storeState.app.locale || (req && req.locale);
        const userAgent = req ? req.headers["user-agent"] : navigator.userAgent || navigator.vendor || window.opera;
        const isBrowserNotSupported = userAgent && checkBrowserNotSupported(userAgent);
        i18nInstance.use(initReactI18next).init({
            lng: locale,
            interpolation: {
                escapeValue: false,
            },
        });

        const { isProdEnv, isTenantFinexity, isPlatformSourceFromApp } = storeState.app;
        const isProdTenantNotFinexity = isProdEnv && !isTenantFinexity;

        return {
            isProdEnv,
            pageProps,
            locale,
            initialNow,
            userAgent,
            tenant,
            isTenantFinexity,
            isBrowserNotSupported,
            isProdTenantNotFinexity,
            isPlatformSourceFromApp,
        };
    }

    checkAndRenewSession() {
        if (this.isInheritUserEnabled) exitInheritUser();
        else {
            Auth.reNewSession(this.props.isPlatformSourceFromApp).then((auth_response) => {
                if (auth_response?.accessToken) {
                    this.props.store.dispatch(storeAuthTokenData(auth_response.accessToken));
                    Cookies.set(FINX_COOKIE.USER_EMAIL, auth_response.email);
                    if (window.isPlatformFinexity) {
                        Cookies.set(FINX_COOKIE.FINX_SESSION, auth_response.accessToken, { domain: ".finexity.com" });
                    }
                }

                if (auth_response?.error === API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED) {
                    this.expireUserSession();
                }
            });
        }
    }

    debouncedCallForAuthCheck = debounce((response) => {
        const message = response?.data?.errors && response.data.errors[0] && response.data.errors[0].message;
        const isUnAuthorized =
            response.status === API_RESPONSE_CODE.UNAUTHORIZED || response.data === API_UNAUTHORIZED_MSG.INVALID_TOKEN;
        const isExpired =
            message === API_UNAUTHORIZED_MSG.DGRAPH_UNAUTHORIZED || message === API_UNAUTHORIZED_MSG.GRAPHQL_UNAUTHORIZED;
        const isEmailMissingInJWT = !response.ok && response?.data?.includes?.(RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN);
        const isUserMadeTooManyRequest = !response.ok && response?.data?.includes?.(RESPONSE_ERRORS.TOO_MANY_REQUEST);

        if (isUserMadeTooManyRequest) {
            console.log(RESPONSE_ERRORS.TOO_MANY_REQUEST);
        } else if (isEmailMissingInJWT) {
            this.props.store.dispatch(fetchUserDetailsFailure(RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN));
        } else if (isExpired || isUnAuthorized) {
            this.checkAndRenewSession();
        }
    }, 500);

    debouncedCallForAuthCheckRestAPI = debounce((response) => {
        const message = response?.originalError?.code;
        const isUnAuthorized =
            response.status === API_RESPONSE_CODE.UNAUTHORIZED || response.data === API_UNAUTHORIZED_MSG.INVALID_TOKEN;
        const isExpired = message === API_UNAUTHORIZED_MSG.JWT_EXPIRED || message === API_UNAUTHORIZED_MSG.GRAPHQL_UNAUTHORIZED;

        if (isExpired || isUnAuthorized) {
            this.checkAndRenewSession();
        }
    }, 100);

    handleAsyncRequestTransform = (request) => async () => {
        const token = getAccessToken();
        const inheritToken = Cookies.get(FINX_COOKIE.INHERIT_USER_TOKEN);
        const fnxtAccessToken = getFnxtAccessToken();

        request.headers.type = this.props.isPlatformSourceFromApp ? "app" : "web";
        request.headers.deviceinfo = navigator.appVersion;

        if (token && (token !== "undefined" || token !== "null")) {
            request.headers["Authorization"] = `Bearer ${token}`;
        }
        if (fnxtAccessToken && (fnxtAccessToken !== "undefined" || fnxtAccessToken !== "null")) {
            request.headers["Authorization-Fnxt"] = `Bearer ${fnxtAccessToken}`;
        }

        if (inheritToken && (inheritToken !== "undefined" || inheritToken !== "null")) {
            request.headers["Authorization-Proxy"] = `Bearer ${inheritToken}`;
        }
        const isAdmin = window?.location?.pathname?.includes?.("admin") || window?.location?.href?.includes?.("admin");
        request.headers["finexity-tenant"] =
            this.props.tenant == TENANTS_LIST.FINEXITY && isAdmin ? "platform" : this.props.tenant;
    };

    handleAsyncRequestTransformRestAPI = (request) => async () => {
        const fnxtAccessToken = getFnxtAccessToken();

        request.headers.type = this.props.isPlatformSourceFromApp ? "app" : "web";
        request.headers.deviceinfo = navigator.appVersion;

        if (fnxtAccessToken && (fnxtAccessToken !== "undefined" || fnxtAccessToken !== "null")) {
            request.headers["Authorization"] = `Bearer ${fnxtAccessToken}`;
        }
    };

    async componentDidMount() {
        window.isFinxProdEnv = window.location.host === "finexity.com" || window.location.host === "www.finexity.com";
        window.isPlatformFinexity = window.location.host.includes("finexity.com");

        if (!checkBrowserNotSupported()) {
            const { store } = this.props;
            // Attaching middleware handler on on-going api call
            //for GQL APIs
            SingletonApi.addMonitor(this.debouncedCallForAuthCheck);
            SingletonApi.addAsyncRequestTransform(this.handleAsyncRequestTransform);
            //For rest APIs
            FnxtSingletonApi.addMonitor(this.debouncedCallForAuthCheckRestAPI);
            FnxtSingletonApi.addAsyncRequestTransform(this.handleAsyncRequestTransformRestAPI);

            this.checkIsPathPrivate(window.location.href.replace(window.location.origin, ""));

            if (
                window.isFinxProdEnv &&
                window.location.href.includes(`${window.location.origin}/login`) &&
                Router.router.query[FINX_COOKIE.REDIRECT_URL]?.includes("finexity.com")
            )
                Cookies.set(FINX_COOKIE.REDIRECT_URL, Router.router.query[FINX_COOKIE.REDIRECT_URL]);

            if (Router.router.query.tipster && !store.getState().user.isLoggedIn) {
                Cookies.set("tipsterId", Router.router.query.tipster);
            }

            if (Router.router.query.utm_campaign) {
                Cookies.set("utm_campaign", Router.router.query.utm_campaign);
            }

            this.initialState = store.getState();

            if (!this.isInitialized) {
                this.isInitialized = true;

                const preventCallProjectQueryRoute = ["/club-deals", "/dashboard", "/marketplace"];
                const shouldCallProjectQuery = !preventCallProjectQueryRoute.find(
                    (path) => window.location.pathname.startsWith(path) && !window.location.pathname.startsWith("/marketplace/")
                );
                shouldCallProjectQuery && store.dispatch(fetchProjectLimitedStart());
                store.dispatch(fetchTenantStart());
                Auth.reNewSession(this.props.isPlatformSourceFromApp).then((response) => {
                    window.isLoggedInByAuthSdk = !!response?.isLoggedInByAuthSdk;

                    if (response?.accessToken) {
                        const inheritUserToken = Cookies.get(FINX_COOKIE.INHERIT_USER_TOKEN);
                        const inheritUserEmail = Cookies.get(FINX_COOKIE.INHERIT_USER_EMAIL);

                        this.isInheritUserEnabled = !!(inheritUserToken && inheritUserEmail);

                        // Configuring sentry scope for current user for proper error stacktrace
                        configureSentryUser({ email: (inheritUserToken && inheritUserEmail) || response.email });

                        Cookies.set(FINX_COOKIE.USER_EMAIL, (inheritUserToken && inheritUserEmail) || response.email);
                        if (window.isPlatformFinexity) {
                            Cookies.set(FINX_COOKIE.FINX_SESSION, response.accessToken, { domain: ".finexity.com" });
                        }

                        store.dispatch(storeAuthTokenData((inheritUserEmail && inheritUserToken) || response.accessToken));
                        this.initializeApp && this.initializeApp();
                    }

                    if (response?.error === API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED) {
                        this.expireUserSession();
                    }

                    this.checkAllowedMobileRoutes(window.location.href.replace(window.location.origin, ""));

                    if (
                        !this.props.isPlatformSourceFromApp &&
                        window.location.href.includes(`${window.location.origin}/token#`) === false
                    ) {
                        this.checkAuthenticationByUserRoleUsingRoute(window.location.href.replace(window.location.origin, ""));
                    }
                });
            }

            Router.events.on("routeChangeStart", this.onRouteChangeHandler);
        }
        window.addEventListener("message", (event) => {
            if (event?.data === "logoutSuccess") {
                this.props.store.dispatch(logout());
            }
        });
    }

    isUrlPrivate = (url) => PRIVATE_ROUTE.some((route) => url.search(route) > -1);

    checkIsPathPrivate = (url) => {
        this.isPathProtected = this.isUrlPrivate(url);
        return this.isPathProtected;
    };

    onRouteChangeHandler = (url) => {
        const lastUrl = window.location.href;

        const isAdmin = url.includes?.("admin");
        SingletonApi.setHeader(
            "finexity-tenant",
            this.props.tenant == TENANTS_LIST.FINEXITY && isAdmin ? "platform" : this.props.tenant
        );

        Cookies.set(
            FINX_COOKIE.FINEXITY_TENANT,
            this.props.tenant == TENANTS_LIST.FINEXITY && isAdmin ? "platform" : this.props.tenant
        );
        Cookies.set(FINX_COOKIE.LAST_VISITED_URL, lastUrl);

        if (lastUrl.indexOf("provide-email") > -1 && this.isUrlPrivate(url)) {
            Router.events.emit("routeChangeError");
            throw "routeChange aborted.";
        }

        this.checkIsPathPrivate(url);
        this.checkAllowedMobileRoutes(url);
        this.checkAuthenticationByUserRoleUsingRoute(url);
    };

    checkAllowedMobileRoutes = (url) => {
        if (this.props.isPlatformSourceFromApp) {
            const isNotAllowed = !ALLOWED_PATHS_FOR_MOBILE.find((path) => url.search(path) > -1);
            if (isNotAllowed) {
                setTimeout(() => Router.push("/404"), 200);
            }
        }
    };

    checkAuthenticationByUserRoleUsingRoute = (url) => {
        const authRole = parseRolesFromCookie();
        const accessToken = getAccessToken();
        const isTenantFinexity = this.props.isTenantFinexity;

        let isRouteAllowed = true;
        const splittedUrl = url.split("?")[0];
        if (!isTenantFinexity && splittedUrl !== "/") {
            isRouteAllowed = TENANT_ALLOWED_ROUTE.findIndex((route) => splittedUrl.startsWith(route)) > -1;
            if (splittedUrl.startsWith("/club-deals/onboarding") && this.props.isProdEnv) {
                isRouteAllowed = false;
            }
            !isRouteAllowed && setTimeout(() => Router.push(accessToken ? "/dashboard" : "/"), 200);
        }

        if (isRouteAllowed && PRIVATE_ROUTE.findIndex((route) => url.search(route) > -1) > -1) {
            this.isPathProtected = true;
            if (accessToken) {
                if (
                    (ADMIN_ROUTE_WITH_TOKEN.findIndex((route) => url.startsWith(route)) > -1 &&
                        (!authRole || (authRole && authRole.indexOf(USER_ROLES.ADMIN) === -1))) ||
                    (BROKER_ROUTE_WITH_TOKEN.findIndex((route) => url.startsWith(route)) > -1 &&
                        (!authRole || (authRole && authRole.indexOf(USER_ROLES.BROKER) === -1))) ||
                    (CLUBDEALS_ROUTE_WITH_TOKEN.findIndex((route) => url.startsWith(route)) > -1 &&
                        (!authRole || (authRole && authRole.indexOf(USER_ROLES.CLUBDEALS) === -1))) ||
                    (TENANT_ROUTE_WITH_TOKEN.findIndex((route) => url.startsWith(route)) > -1 &&
                        (!authRole || (authRole && authRole.indexOf(USER_ROLES.TENANT) === -1))) ||
                    (UMBRELLA_ROUTE_WITH_TOKEN.findIndex((route) => url.startsWith(route)) > -1 &&
                        (!authRole || (authRole && authRole.indexOf(USER_ROLES.UMBRELLA) === -1)))
                )
                    setTimeout(() => Router.push("/dashboard"), 200);
            } else {
                this.expireUserSession();
            }
        } else {
            if (!accessToken) this.expireUserSession();
            if (PREVENT_ROUTE_WITH_TOKEN.findIndex((route) => url.startsWith(route)) > -1 && accessToken) {
                setTimeout(() => Router.push("/dashboard"), 200);
            }
        }
    };

    expireUserSession = () => {
        this.props.store.dispatch(logout());
        if (this.isPathProtected) {
            this.isPathProtected = false;
            setTimeout(() => Router.push("/login"), 1000);
        }
    };

    initializeApp = () => {
        const { store } = this.props;
        store.dispatch(callTheInitialKYCMethods({ tenant: store.getState()?.app?.tenant }));

        if (window.location.href.includes(`${window.location.origin}/token#`) === false) {
            store.dispatch(fetchUserAllDetailsStart());
        }
    };

    componentWillUnmount() {
        Router.events.off("routeChangeStart", this.onRouteChangeHandler);
    }

    render() {
        const { Component, pageProps, store, userAgent } = this.props;
        const isServer = !isClient();
        const isBrowserNotSupported = isServer ? this.props.isBrowserNotSupported : checkBrowserNotSupported();

        return (
            <>
                {isBrowserNotSupported ? (
                    <div className="main-app-container broad-container">
                        <Provider store={store}>
                            <UtilContextComponent>
                                <IntlProvider i18n={i18n} notSupportedBrowser>
                                    <OldBrowserInfo />
                                </IntlProvider>
                            </UtilContextComponent>
                        </Provider>
                    </div>
                ) : (
                    <Provider store={store}>
                        <>
                            <UserAgentProvider ua={userAgent}>
                                <UtilContextComponent>
                                    <CheckUserRole>
                                        <EmailNotFoundInToken>
                                            <IntlProvider i18n={i18n}>
                                                <AppTabBar>
                                                    <Component {...pageProps} />
                                                </AppTabBar>
                                            </IntlProvider>
                                        </EmailNotFoundInToken>
                                    </CheckUserRole>
                                </UtilContextComponent>
                            </UserAgentProvider>
                        </>
                    </Provider>
                )}
            </>
        );
    }
}
export { i18n };
export default compose(withRedux(initializeStore))(MyApp);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import * as yup from "yup";
import { withFormik } from "formik";
import { compose } from "recompose";
import cn from "classnames";
import { registerLocale } from "react-datepicker";
const pick = require("lodash/pick");
import { de, enUS } from "date-fns/locale";

import { calculateAge } from "utils";
import { getDocumentsHost } from "common/helper";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { selectLocale } from "store/app/selectors";
import { textNumberRegex } from "constants/validationRegex";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import Input from "components/Input";
import DateInput from "components/Input/DateInput";
import Checkbox from "components/Checkbox";
import DownloadButton from "components/DownloadButton";
import IsolatedSection from "components/IsolatedSection";
import ErrorMessage from "components/ErrorMessage";
import { IDENTIFICATION_FORM_INPUTS, DOCS_PATH } from "constants/index";
import { nextButtonText } from "i18n/messages/buttonTitles";
import { selectUserTermsAndConditionsAcceptance } from "store/user/account/selectors";
import CountryPicker from "components/CountryPicker";
import { selectKYCSubmitting } from "store/user/kyc/selectors";
import { sendFormError } from "i18n/messages/errors";

const DOCUMENT_HOST = getDocumentsHost();

const messages = {
    sendFormError,
    heading: "identification_data_heading",
    docHeading: "customer_information_effecta",
    docContent: "effecta_liability",
    docCheckContent: "read_accept_customer_information",
    dob: "date_of_birth",
    pob: "place_of_birth",
    poc: "country_of_birth",
    nationality: "nationality",
    taxInformation: "tax_information",
    usTaxpayer: "are_you_us_taxed",
    pep: "politically_exposed_person",
    noText: "no_text",
    yesText: "yes_text",
    investmentIdentificationFormDOBValidationRequired: "date_of_birth_required",
    investmentIdentificationFormDOBValidationValid: "must_be_in_between",
    investmentIdentificationFormPOBValidationRequired: "place_of_birth_required",
    investmentIdentificationFormPOCValidationRequired: "country_of_birth_required",
    investmentIdentificationFormNationalityValidationRequired: "nationality_is_required",
    investmentIdentificationFormUsTaxpayerValidationRequired: "this_is_required_field",
    investmentIdentificationFormPepValidationRequired: "pep_is_required",
    partnerProgramFirstNameValidationRequired: "iban_is_required",
    defaultMessage: "Dieses Feld ist erforderlich",
    pleaseSelect: "please_select",
    taxIdContent: "tax_id_content",
    customerContent: "customer_content",
    pepContent: "pep_content",
    usTaxContent: "us_tax_content",
    usTaxationNotAllowedTitle: "pep_not_allowed_title",
    usTaxationNotAllowedContent: "us_tax_content",
    pepNotAllowedTitle: "pep_not_allowed_title",
    pepNotAllowedContent: "pep_content",
    exit: "exit",
    specialCharactersNotAllowed: "please_enter_valid_character",
};

const IdentificationData = ({
    className = "",
    handleChange,
    handleBlur,
    submitForm,
    values,
    setFieldValue,
    touched,
    errors,
    validateForm,
    setFieldTouched,
    onBack,
    locale,
    onCloseButtonClick,
    IsEffectaCustomerInformationConfirmed,
    open,
    backSlideEffect = "",
    isInvestment = false,
    isKYCSubmitting,
    isSubmitButtonLoading = false,
    kycError,
}) => {
    useSideModalScrollToTop();
    const [explainModalFlag, setExplainModalFlag] = useState(false);
    const [explainModalTitle, setExplainModalTitle] = useState("");
    const [explainModalContent, setExplainModalContent] = useState("");
    const [explainModalButtonText, setExplainModalButtonText] = useState("");

    useEffect(() => {
        locale === "en" ? registerLocale("en", enUS) : registerLocale("de", de);
    }, [locale]);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    function setExplainModal(option) {
        !explainModalFlag && setExplainModalFlag(true);
        setExplainModalTitle(ExplanatoryData[option].title);
        setExplainModalContent(ExplanatoryData[option].content);
        setExplainModalButtonText(ExplanatoryData[option].buttonText);
        window.outerWidth < 992 && jQuery("body").addClass("overflow-hidden");
    }

    const { formatMessage } = useFormattedMessage();

    const demoContent =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu sodales est. In convallis ligula in urna condimentum rutrum. Ut quis porttitor turpis. Ut id aliquam magna. Fusce eleifend erat eget tellus dapibus congue. Donec porttitor odio vel porta bibendum. Nam sit amet dolor mattis, scelerisque metus et, dictum justo. Nullam vitae magna orci. Aliquam fermentum ligula et posuere condimentum. Sed volutpat augue nibh, nec maximus massa rutrum eu. Donec finibus magna ut est sagittis, sed porta tortor rhoncus. Integer tellus nibh, convallis eget varius sed, elementum et felis.";

    const ExplanatoryData = {
        taxId: {
            title: "Steuer-ID",
            content: formatMessage(messages.taxIdContent),
        },
        usTaxpayer: {
            title: formatMessage(messages.usTaxpayer),
            content: formatMessage(messages.usTaxContent),
        },
        pep: {
            title: formatMessage(messages.pep),
            content: formatMessage(messages.pepContent),
        },
        usTaxationNotAllowed: {
            title: formatMessage(messages.usTaxationNotAllowedTitle),
            content: formatMessage(messages.usTaxContent),
            buttonText: formatMessage(messages.exit),
        },
        pepNotAllowed: {
            title: formatMessage(messages.pepNotAllowedTitle),
            content: formatMessage(messages.pepNotAllowedContent),
            buttonText: formatMessage(messages.exit),
        },
        contract: {
            title: "Rahmenvermittlervertrag",
            content: demoContent,
        },
        customer: {
            title: formatMessage(messages.docHeading),
            content: formatMessage(messages.customerContent),
        },
    };

    useEffect(() => {
        setFieldValue("IsEffectaCustomerInformationConfirmed", IsEffectaCustomerInformationConfirmed);
    }, [IsEffectaCustomerInformationConfirmed]);

    return (
        <>
            <IsolatedSection
                open={explainModalFlag}
                onBackButtonClick={() => {
                    setExplainModalFlag(!explainModalFlag);
                    window.outerWidth < 992 && jQuery("body").removeClass("overflow-hidden");
                }}
                title={explainModalTitle}
                content={explainModalContent}
                buttonText={explainModalButtonText}
                onExitButtonClick={() => onCloseButtonClick()}
            />
            <div className={`${className} identification-data ${backSlideEffect}`}>
                <h3 className="mb-8">{formatMessage(messages.heading)}</h3>
                <form method="post">
                    <DateInput
                        name="Birthday"
                        label={`${formatMessage(messages.dob)} *`}
                        onChange={(value) => {
                            setFieldValue("Birthday", value?.floatValue ? value : "");
                            setFieldTouched("Birthday", true);
                        }}
                        format={"##.##.####"}
                        value={
                            values?.Birthday?.formattedValue ||
                            (values.Birthday ? moment(values.Birthday).format("DD.MM.YYYY") : null)
                        }
                        placeholder={locale == "de" ? "TT.MM.JJJJ" : "DD.MM.YYYY"}
                        error={touched.Birthday && errors.Birthday}
                        errorMessage={errors.Birthday ? formatMessage(messages[errors.Birthday]) : null}
                    />
                    <Input
                        className="mb-5"
                        label={`${formatMessage(messages.pob)} *`}
                        type="text"
                        name="Birthplace"
                        placeholder=""
                        id="Birthplace"
                        inputClassName="id-birth-place-input"
                        onChange={handleChange}
                        value={values.Birthplace}
                        onBlur={handleBlur}
                        errorMessage={errors.Birthplace ? formatMessage(messages[errors.Birthplace]) : null}
                        error={touched.Birthplace && errors.Birthplace}
                    />
                    <CountryPicker
                        className="mb-5"
                        label={`${formatMessage(messages.poc)} *`}
                        maxMenuHeight={400}
                        name="BirthCountry"
                        selectClassName="id-birth-country-input"
                        placeholder={formatMessage(messages.pleaseSelect)}
                        value={values.BirthCountry}
                        onCountryChange={(value) => setFieldValue("BirthCountry", value)}
                        errorMessage={errors.BirthCountry ? formatMessage(messages[errors.BirthCountry]) : null}
                        isError={touched.BirthCountry && errors.BirthCountry}
                    />
                    <CountryPicker
                        className="mb-8"
                        label={`${formatMessage(messages.nationality)} *`}
                        name="Nationality"
                        selectClassName="id-nationality-input"
                        placeholder={formatMessage(messages.pleaseSelect)}
                        value={values.Nationality}
                        onCountryChange={(value) => setFieldValue("Nationality", value)}
                        errorMessage={errors.Nationality ? formatMessage(messages[errors.Nationality]) : null}
                        isError={touched.Nationality && errors.Nationality}
                    />
                    <div className="taxes">
                        <h4>{formatMessage(messages.taxInformation)}</h4>
                        <div className={`form-element mb-5 ${touched.IsUSTaxation && errors.IsUSTaxation && "error-element"}`}>
                            <div className="flex">
                                <label className="mb-2 d-inline-block">{formatMessage(messages.usTaxpayer)} *</label>
                                <div className="field-hint" onClick={() => setExplainModal("usTaxpayer")}>
                                    ?
                                </div>
                            </div>
                            <div className="tabs flex us-taxpayer tabs-input">
                                <input
                                    value={"false"}
                                    checked={values.IsUSTaxation === "false"}
                                    onChange={() => setFieldValue("IsUSTaxation", "false")}
                                    id={isInvestment ? "investmentUsTaxpayerNo" : "usTaxpayerNo"}
                                    type="radio"
                                    name="IsUSTaxation"
                                    className="id-us-taxes-no-radio"
                                />
                                <label
                                    className="medium mw-100"
                                    htmlFor={isInvestment ? "investmentUsTaxpayerNo" : "usTaxpayerNo"}
                                >
                                    {formatMessage(messages.noText)}
                                </label>

                                <input
                                    value={"true"}
                                    checked={values.IsUSTaxation === "true"}
                                    onChange={() => setFieldValue("IsUSTaxation", "true")}
                                    id={isInvestment ? "investmentUsTaxpayerYes" : "usTaxpayerYes"}
                                    type="radio"
                                    name="IsUSTaxation"
                                    className="id-us-taxes-yes-radio"
                                />
                                <label
                                    className="medium mw-100"
                                    htmlFor={isInvestment ? "investmentUsTaxpayerYes" : "usTaxpayerYes"}
                                >
                                    {formatMessage(messages.yesText)}
                                </label>
                            </div>
                            {touched.IsUSTaxation && errors.IsUSTaxation && (
                                <ErrorMessage>{formatMessage(messages[errors.IsUSTaxation])}</ErrorMessage>
                            )}
                        </div>
                        <div className={`form-element mb-10 ${touched.IsPEP && errors.IsPEP && "error-element"}`}>
                            <div className="flex id-mobile-text-wrap">
                                <label className="mb-2 d-inline-block">{formatMessage(messages.pep)} *</label>
                                <div className="field-hint" onClick={() => setExplainModal("pep")}>
                                    ?
                                </div>
                            </div>
                            <div className="tabs flex pep tabs-input">
                                <input
                                    value={"false"}
                                    checked={values.IsPEP === "false"}
                                    onChange={() => setFieldValue("IsPEP", "false")}
                                    id={isInvestment ? "investmentPepNo" : "pepNo"}
                                    type="radio"
                                    name="IsPEP"
                                    className="id-pep-no-radio"
                                />
                                <label className="medium mw-100" htmlFor={isInvestment ? "investmentPepNo" : "pepNo"}>
                                    {formatMessage(messages.noText)}
                                </label>

                                <input
                                    value={"true"}
                                    checked={values.IsPEP === "true"}
                                    onChange={() => setFieldValue("IsPEP", "true")}
                                    id={isInvestment ? "investmentPepYes" : "pepYes"}
                                    type="radio"
                                    name="IsPEP"
                                    className="id-pep-yes-radio"
                                />
                                <label htmlFor={isInvestment ? "investmentPepYes" : "pepYes"}>
                                    {formatMessage(messages.yesText)}
                                </label>
                            </div>
                            {touched.IsPEP && errors.IsPEP && (
                                <ErrorMessage>{formatMessage(messages[errors.IsPEP])}</ErrorMessage>
                            )}
                        </div>
                        {!IsEffectaCustomerInformationConfirmed ? (
                            <div className="bg-grey-full document-box">
                                <div className="upper-part">
                                    <h6 className="mt-0">{formatMessage(messages.docHeading)}</h6>
                                    <div className="field-info" onClick={() => setExplainModal("customer")}>
                                        ?
                                    </div>
                                    <p className="mb-0">{formatMessage(messages.docContent)}</p>
                                </div>
                                <div className="bottom-part">
                                    <div className="check-section bg-grey-full flex space-between">
                                        <Checkbox
                                            inputId="IsEffectaCustomerInformationConfirmed"
                                            name="IsEffectaCustomerInformationConfirmed"
                                            value={
                                                values.IsEffectaCustomerInformationConfirmed === "true" ||
                                                values.IsEffectaCustomerInformationConfirmed === true
                                            }
                                            label={formatMessage(messages.docCheckContent)}
                                            onChange={() => {
                                                setFieldValue(
                                                    "IsEffectaCustomerInformationConfirmed",
                                                    !(
                                                        values.IsEffectaCustomerInformationConfirmed === "true" ||
                                                        values.IsEffectaCustomerInformationConfirmed === true
                                                    )
                                                );
                                            }}
                                            isError={
                                                touched.IsEffectaCustomerInformationConfirmed &&
                                                errors.IsEffectaCustomerInformationConfirmed
                                            }
                                        />
                                    </div>
                                    {touched.IsEffectaCustomerInformationConfirmed &&
                                        errors.IsEffectaCustomerInformationConfirmed && (
                                            <div className="error">
                                                {formatMessage(messages[errors.IsEffectaCustomerInformationConfirmed])}
                                            </div>
                                        )}
                                    <DownloadButton
                                        fileURL={`${DOCUMENT_HOST}${DOCS_PATH.FINEXITY_EFFECTA_CUSTOMER_INFORMATION}`}
                                        title={formatMessage(messages.docHeading)}
                                        description="Stand 08/2019 - PDF - 132 KB"
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </form>
                {kycError && !isKYCSubmitting && (
                    <span className="error pl-0 mb-0 d-block mw-100">{formatMessage(messages["sendFormError"])}</span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton className="id-back-button" onClick={onBack} />
                    <NextButton
                        className={cn({
                            "px-0": true,
                            "disable-grey": Object.keys(errors).length || isKYCSubmitting || isSubmitButtonLoading,
                            "disable-pointer": isKYCSubmitting || isSubmitButtonLoading,
                        })}
                        title={isKYCSubmitting || isSubmitButtonLoading ? " " : formatMessage(nextButtonText)}
                        onClick={() => {
                            if (values.IsUSTaxation === "true" && values.IsPEP === "true") {
                                setExplainModal("pepNotAllowed");
                            } else if (values.IsUSTaxation === "true") {
                                setExplainModal("usTaxationNotAllowed");
                            } else if (values.IsPEP === "true") {
                                setExplainModal("pepNotAllowed");
                            } else {
                                // submitForm().then(() => {
                                //     handleSubmit(birthday);
                                // });
                                submitForm();
                            }
                        }}
                        isLoading={isKYCSubmitting || isSubmitButtonLoading}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    IsEffectaCustomerInformationConfirmed: selectUserTermsAndConditionsAcceptance(state).isEffectaCustomerInformationConfirmed,
    locale: selectLocale(state),
    isKYCSubmitting: selectKYCSubmitting(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            const existingData =
                props.data && Object.keys(props.data).length
                    ? {
                          ...props.data,
                          IsPEP: props.data.IsPEP,
                          IsUSTaxation: props.data.IsUSTaxation,
                      }
                    : {};
            // const currentDate = new Date().toISOString();
            return {
                // Birthday: currentDate,
                Birthday: "", // Changed current date to blank
                Birthplace: "",
                BirthCountry: "",
                Nationality: "",
                IsEffectaCustomerInformationConfirmed: false,
                ...existingData,
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: () => {
            return yup.object().shape({
                Birthday: yup
                    .mixed()
                    .test("Birthday", "investmentIdentificationFormDOBValidationValid", (value) => {
                        const dateArr = value?.formattedValue?.includes(".")
                            ? value?.formattedValue?.split(".")
                            : value?.formattedValue?.split("/");
                        const formattedDate = `${dateArr?.[2]}-${dateArr?.[1]}-${dateArr?.[0]}`;
                        return value?.formattedValue
                            ? formattedDate.includes("_")
                                ? false
                                : calculateAge(new Date(formattedDate))
                            : calculateAge(new Date(value));
                    })
                    .required("investmentIdentificationFormDOBValidationRequired")
                    .nullable(true),
                Birthplace: yup
                    .string()
                    .required("investmentIdentificationFormPOBValidationRequired")
                    .test("Birthplace", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                BirthCountry: yup.object().required("investmentIdentificationFormPOCValidationRequired").nullable(true),
                Nationality: yup.object().required("investmentIdentificationFormNationalityValidationRequired").nullable(true),
                IsUSTaxation: yup
                    .bool()
                    // .test("IsUSTaxation", "investmentIdentificationFormUsTaxpayersNotAllowed", value => {
                    //     return !!(value !== 'usTaxpayerYes');
                    // })
                    .required("investmentIdentificationFormUsTaxpayerValidationRequired")
                    .nullable(true),
                IsPEP: yup
                    .bool()
                    // .test("IsPEP", "investmentIdentificationFormPepNotAllowed", value => {
                    //     return !!(value !== 'pepYes');
                    // })
                    .required("investmentIdentificationFormPepValidationRequired")
                    .nullable(true),
                IsEffectaCustomerInformationConfirmed: yup
                    .bool()
                    .required("partnerProgramFirstNameValidationRequired")
                    .oneOf([true], "partnerProgramFirstNameValidationRequired"),
            });
        },
        handleSubmit: (values, { setSubmitting, props }) => {
            setSubmitting(false);
            const birthDate = values.Birthday?.formattedValue?.includes(".")
                ? values.Birthday?.formattedValue?.split(".")
                : values.Birthday?.formattedValue?.split("/");
            const formattedDate = `${birthDate?.[2]}-${birthDate?.[1]}-${birthDate?.[0]}`;

            values.Birthday = values.Birthday?.formattedValue
                ? moment(new Date(formattedDate)).format("YYYY-MM-DD")
                : moment(values.Birthday).format("YYYY-MM-DD");

            const formData = pick(values, IDENTIFICATION_FORM_INPUTS);
            formData["PreferredLang"] = props.locale;
            props.onSubmit && props.onSubmit(formData);
        },
        displayName: "InvestmentIdentificationForm",
    })
)(IdentificationData);

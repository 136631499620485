import React, { useEffect } from "react";
import NextButton from "components/NextButton";
// import MoneyProfit from "assets/images/svg/moneyProfit.svg";
import TopGreyDiv from "../components/topGreyDiv";
import { getInitialsFromEmail, getRegionFormattedDate } from "utils";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import MoneyCurrency from "assets/images/svg/money-currency.svg";
import CustomFormatNumber from "components/CustomFormatNumber";

const messages = {
    transferTitle: "ewallet_send_credit",
    payee: "receiver",
    heading: "transaction_submitted_successfully",
    transactionInfo: "transaction_information",
    transactionId: "success_transaction_id",
    date: "date",
    tranferAmount: "amount",
    buttonText: "to_overview",
};

const Row = ({ left, right }) => (
    <div className="flex justify-between mb-3">
        <label className="another-grey-color d-inline-block mr-2">{left}</label>
        <span className="text-14 fw-600">{right}</span>
    </div>
);

function TransferEMoneyOverview({ setShowDate, setCloseTitle, setStep, stepData, backSlideEffect = "", setBackSlideEffect }) {
    useSideModalScrollToTop();

    const currentDate = getRegionFormattedDate(Date.now());
    const creditedUserEmail = (stepData && stepData.creditedUserEmail) || "";
    const transferAmount = stepData && stepData.transferAmount ? stepData.transferAmount : 0.0;
    const transactionId = stepData && stepData.transcationID ? stepData.transcationID : "VFG4RF";

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.transferTitle));
    }, []);

    return (
        <div className={`transfer-e-money-success ${backSlideEffect}`}>
            <TopGreyDiv icon />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3 className="mt-6">{formatMessage(messages.heading)}</h3>
                <p className="text-16 secondary-light-color mt-8 mb-8">{formatMessage(messages.payee)}</p>
                <div className="card-container flex mb-10">
                    <div className="round-icon-container">
                        <span className="round-holder bg-dark">{getInitialsFromEmail(creditedUserEmail)}</span>
                    </div>
                    <div className="flex align-center hf-20">
                        <span className="email text-16 word-break pl-3">{creditedUserEmail}</span>
                    </div>
                </div>
                <span className="text-16 d-inline-block mt-0 mb-8 secondary-light-color">
                    {formatMessage(messages.transactionInfo)}
                </span>
                <div className="overview-details d-flex align-start p-4 mb-15">
                    <div className="round-holder mr-4 bg-dark">
                        <MoneyCurrency width="25" height="25" />
                    </div>
                    <div className="ml-4 w-100">
                        <Row left={formatMessage(messages.transactionId)} right={transactionId} />
                        <Row left={formatMessage(messages.date)} right={currentDate} />
                        <Row left={formatMessage(messages.tranferAmount)} right={<CustomFormatNumber value={transferAmount} />} />
                    </div>
                </div>

                <div className="bottom-bar-container">
                    <NextButton
                        title={formatMessage(messages.buttonText)}
                        onClick={() => {
                            setStep("walletOverview");
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default TransferEMoneyOverview;

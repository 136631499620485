import SecondaryMarketActionTypes from "./types";

export const fetchSecondaryMarketStart = (offset, limit, role, result) => ({
    type: SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_START,
    payload: {
        offset,
        limit,
        role,
        result,
    },
});

export const fetchSecondaryMarketSuccess = (users) => ({
    type: SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_SUCCESS,
    payload: users,
});

export const fetchSecondaryMarketFailure = (error) => ({
    type: SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_FAILURE,
    payload: error,
});

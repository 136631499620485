import AdequacyActionTypes from "./types";

export const setAdequacyStart = (reqData) => ({
    type: AdequacyActionTypes.SET_ADEQUACY_START,
    payload: reqData,
});

export const setAdequacyRevoke = (reqData) => ({
    type: AdequacyActionTypes.SET_ADEQUACY_REVOKE,
    payload: reqData,
});

export const setAdequacySuccess = (adequacyData) => ({
    type: AdequacyActionTypes.SET_ADEQUACY_SUCCESS,
    payload: adequacyData,
});

export const setAdequacyFailure = (error) => ({
    type: AdequacyActionTypes.SET_ADEQUACY_FAILURE,
    payload: error,
});

export const fetchAdequacyStart = () => ({
    type: AdequacyActionTypes.FETCH_ADEQUACY_START,
});

export const fetchAdequacySuccess = (fetchAdequacyData) => ({
    type: AdequacyActionTypes.FETCH_ADEQUACY_SUCCESS,
    payload: fetchAdequacyData,
});

export const fetchAdequacyFailure = (error) => ({
    type: AdequacyActionTypes.FETCH_ADEQUACY_FAILURE,
    payload: error,
});

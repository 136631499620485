import apisauce from "apisauce";
import { getApiGatewayUrl } from "common/helper";

var Singleton = (function () {
    var instance;

    function createInstance() {
        return apisauce.create({
            baseURL: getApiGatewayUrl(),
            headers: {
                "Content-Type": "application/json",
            },
            timeout: 150000,
        });
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default Singleton.getInstance();

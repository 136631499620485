import React from "react";

import ArrowRight from "assets/images/svg/arrow-right.svg";

const PrevButton = ({ className = "", onClick, title = "", disable = false }) => (
    <button
        disabled={disable}
        type="button"
        className={`prev medium cta-inverse ${className}`}
        onClick={() => onClick()}
    >
        <ArrowRight />
        {title}
    </button>
);

export default PrevButton;

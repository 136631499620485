/* eslint-disable no-new */
/* eslint-disable prefer-destructuring */
import AUTH_CONFIG from "constants/auth0";
import { API_UNAUTHORIZED_MSG, TENANTS_LIST } from "constants/index";
import { clearAllAuthCookie } from "store/user/account/actions";
import {
    getAccessToken,
    getHashQueryStringParam,
    parseAuth0JwtToken,
    isClient,
    getFnxtAccessToken,
    checkIsSafariBrowser,
} from "utils/index";
import { getTenantFromHost, loadCurrentHostConfig } from "common/helper";

const FINX_COOKIE = require("common/constants");

const finxConfig = loadCurrentHostConfig();

let auth0 = null;

if (isClient()) {
    auth0 = require("auth0-js");
}

class Auth {
    constructor() {
        if (!Auth.instance) {
            if (isClient()) {
                this.auth0 = new auth0.WebAuth({
                    domain: AUTH_CONFIG.domain,
                    clientID: AUTH_CONFIG.clientId,
                    responseType: "token id_token",
                    audience: AUTH_CONFIG.audience,
                    redirectUri: AUTH_CONFIG.callbackUrl,
                    realm: "Username-Password-Authentication",
                    scope: AUTH_CONFIG.scope,
                });
            }
            Auth.instance = this;
        }
        return Auth.instance;
    }

    signinWithProvider(provider, callback) {
        if (isClient()) {
            localStorage.setItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER, provider);
            this.auth0.authorize(
                {
                    connection: provider,
                    authParamsMap: { tenantName: getTenantFromHost(window.location.host) },
                },
                callback
            );
        }
    }

    customLogin(credentials) {
        localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
        return fetch("https://login.finexity.com/oauth/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                grant_type: "password",
                realm: "Username-Password-Authentication",
                username: credentials.username,
                password: credentials.password,
                client_id: AUTH_CONFIG.appClientId,
                audience: AUTH_CONFIG.audience,
                scope: AUTH_CONFIG.scope,
            }),
        });
    }

    login(credentials, callback) {
        if (isClient()) {
            localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
            this.auth0.login(
                {
                    username: credentials.username,
                    password: credentials.password,
                },
                callback
            );
        }
    }

    changePassword(credentials, callback) {
        if (isClient()) {
            this.auth0.changePassword(
                {
                    email: credentials.email,
                    connection: "Username-Password-Authentication",
                },
                callback
            );
        }
    }

    reNewSession(isPlatformSourceFromApp) {
        if (isClient()) {
            return new Promise((resolve) => {
                const isCallbackRoute = window.location.href.includes(`${window.location.origin}/token#`) === true;
                const connectionProvider = localStorage.getItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
                const isTenantFinexity = getTenantFromHost(window.location.host) === TENANTS_LIST.FINEXITY;
                const isLocalHost = window.location.host.includes("localhost");

                const isSafariBrowser = checkIsSafariBrowser();

                const isProdEnv =
                    finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT === "prod" ||
                    finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT === "staging";

                // isLocalHost && connectionProvider - is to handle social login on safari browsers
                if (
                    (isProdEnv && !isTenantFinexity) ||
                    isPlatformSourceFromApp ||
                    (isLocalHost && connectionProvider && isSafariBrowser)
                ) {
                    let id_token = getAccessToken();
                    let fnxt_access_token = getFnxtAccessToken();

                    if (connectionProvider && isCallbackRoute) {
                        id_token = getHashQueryStringParam("id_token") || id_token;
                        fnxt_access_token = getHashQueryStringParam("access_token") || fnxt_access_token;
                    }

                    const authResult = parseAuth0JwtToken(id_token);
                    const isTokenExpired = this.isTokenExpired(authResult);

                    const fnxtAuthResult = parseAuth0JwtToken(fnxt_access_token);
                    const isFnxtTokenExpired = this.isTokenExpired(fnxtAuthResult);

                    if (!isFnxtTokenExpired) {
                        localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, fnxt_access_token);
                    }

                    if (id_token && authResult && !isTokenExpired) {
                        resolve({ accessToken: id_token, error: null, email: authResult.email });
                    } else {
                        localStorage.removeItem(FINX_COOKIE.FINX_SESSION);
                        localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
                        resolve({ accessToken: null, error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED, email: null });
                    }
                } else {
                    this.auth0.checkSession({}, (err, authResult) => {
                        if (authResult?.accessToken && authResult?.idToken) {
                            localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, authResult.accessToken);

                            resolve({
                                accessToken: authResult.idToken,
                                email: authResult.idTokenPayload.email,
                                isLoggedInByAuthSdk: true,
                            });
                        } else {
                            localStorage.removeItem(FINX_COOKIE.FINX_ACCESS_TOKEN);
                            localStorage.removeItem(FINX_COOKIE.FINX_SESSION);
                            localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
                            if (err?.error === API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED) {
                                resolve({ accessToken: null, error: err.error, email: null });
                            } else resolve({ accessToken: null, error: null, email: null });
                        }
                    });
                }
            });
        }
    }

    customLogout(optionalParams) {
        const redirectToLogin = optionalParams ? optionalParams?.redirectToLogin : true;
        return new Promise((resolve) => {
            if (isClient()) {
                localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
                fetch("/logout").then((response) => {
                    if (response && response.ok) {
                        clearAllAuthCookie();
                        resolve(true);
                        if (redirectToLogin) {
                            window.location = "/login";
                        }
                    } else resolve(false);
                });
            } else resolve(false);
        });
    }

    logout(optionalParams) {
        const returnTo = optionalParams?.returnTo || `${window.location.origin}/login`;
        return new Promise((resolve) => {
            if (isClient()) {
                localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
                fetch("/logout").then((response) => {
                    if (response && response.ok) {
                        resolve(true);
                        clearAllAuthCookie();
                        this.auth0.logout({ returnTo });
                    } else resolve(false);
                });
            } else resolve(false);
        });
    }

    isTokenExpired(authResult) {
        return Math.floor(new Date().getTime() / 1000) >= authResult?.exp;
    }
}

const instance = new Auth();

export default instance;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import cn from "classnames";
import TopGreyDiv from "../components/topGreyDiv";
import AccountDetailsBlock from "../components/accountDetailsBlock";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import Bank from "assets/images/svg/bank.svg";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { sendFormError } from "i18n/messages/errors";
import { selectBankAccount } from "store/user/account/selectors";
import { setPreferredBankAccount } from "api";

const messages = {
    addBankAccountTitle: "change_bank_account",
    edit: "form_edit",
    primaryBankAccount: "primary_bank_account",
    add: "form_add",
    saveSelectedBankAccount: "save_selected_bank_account",
    selectedPayoutBankAccount: "selected_payout_bank_account",
    otherSavedBankAccounts: "other_saved_bank_accounts",
    mangopayBankIsNotExist: "mangopay_bank_not_exist",
    noMoreBankAccounts: "bank_accounts_not_available",
};

const AllBankAccount = ({
    setShowDate,
    setCloseTitle,
    data,
    setStep,
    bankAccounts,
    stepData,
    setStepData,
    updateData,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const allBankAccounts = data && data.allBankAccounts;
    const [requestError, setRequestError] = useState(false);

    const selectedBankID = data && data.selectedBankAccount && data.selectedBankAccount.uid;

    const [selectedBankUid, setSelectedBankUid] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState({});

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.addBankAccountTitle));
    }, []);

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(
                formatMessage(
                    status.requestError === "mangopay_bank_account_not_available"
                        ? messages.mangopayBankIsNotExist
                        : sendFormError
                )
            );
        }
    }, [status]);

    const onBankAccountClicked = (uid) => {
        setSelectedBankUid(uid);
    };

    const onPreferredBankAccount = async () => {
        try {
            setSubmitting(true);
            const res = await setPreferredBankAccount({ bankAccountID: selectedBankUid });
            setSubmitting(false);
            if (res && res.data && res.data.errors && res.data.errors[0]) {
                setStatus({ requestError: res.data.errors[0].message });
            }

            if (!res || res.errors || res.data.errors || res.data.data.errors) return;
            setRequestError(false);
            const selectedBankAccount =
                Array.isArray(bankAccounts) && bankAccounts.length && bankAccounts.find((x) => x.uid === selectedBankUid);

            updateData({
                ...data,
                selectedBankAccount,
            });

            setStep("payOut");
            setBackSlideEffect("step-slide-right");
        } catch (error) {
            setSubmitting(false);
            setStatus({ requestError: error });
        }
    };

    return (
        <div className={`pay-out ${backSlideEffect}`}>
            <TopGreyDiv icon svgIcon={<Bank width="65" height="65" />} />
            <div className="py-10 px-5 mw-450 mx-auto">
                <p className="secondary-light-color mb-0">{formatMessage(messages.selectedPayoutBankAccount)}</p>

                <AccountDetailsBlock
                    className="mt-8 mb-5"
                    data={
                        data.selectedBankAccount && Object.keys(data.selectedBankAccount).length
                            ? data.selectedBankAccount
                            : data.bankAccount
                    }
                />

                <div className="mt-15 flex justify-between">
                    <p className="secondary-light-color mb-0">{formatMessage(messages.otherSavedBankAccounts)}</p>
                    <span
                        className="text-14 primary-color cursor-pointer"
                        onClick={() => {
                            setStep("addBankAccount");
                            setStepData({
                                ...stepData,
                                addBankAccount: data,
                            });
                            setBackSlideEffect("step-slide-left");
                        }}
                    >
                        {formatMessage(messages.add)}
                    </span>
                </div>
                {allBankAccounts && allBankAccounts.length > 1 ? (
                    allBankAccounts.map((item, index) => {
                        if (item.uid !== selectedBankID) {
                            return (
                                <div
                                    className={`cursor-pointer ${selectedBankUid === item.uid ? "selected-bank" : ""}`}
                                    onClick={() => onBankAccountClicked(item.uid)}
                                    key={index}
                                >
                                    <AccountDetailsBlock className="mt-8 mb-5" data={item} />
                                </div>
                            );
                        }
                    })
                ) : (
                    <div className="p-5 no-bank-account mt-8">
                        <div className="round-holder mx-auto bg-dark">
                            <Bank />
                        </div>
                        <label className="text-14 mt-3 flex justify-center">{formatMessage(messages.noMoreBankAccounts)}</label>
                    </div>
                )}
                {requestError && <span className="error pl-0">{requestError}</span>}
            </div>
            <div className="bottom-bar-container mt-25">
                <PrevButton
                    onClick={() => {
                        setStep("payOut");
                        setBackSlideEffect("step-slide-right");
                    }}
                    disable={isSubmitting}
                />
                {allBankAccounts && allBankAccounts.length && (
                    <NextButton
                        className={cn({
                            "disable-grey": isSubmitting || !selectedBankUid,
                            "mw-fit": true,
                        })}
                        title={formatMessage(messages.saveSelectedBankAccount)}
                        disable={isSubmitting || !selectedBankUid}
                        onClick={onPreferredBankAccount}
                        isLoading={isSubmitting}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    bankAccounts: selectBankAccount,
});

export default connect(mapStateToProps)(AllBankAccount);

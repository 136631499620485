import React from "react";

import { useFormattedMessage, useUtils } from "hooks";

const messages = {
    firstLine: "using_outdated_browser",
    secondLine: "install_current_browser",
    thirdLine: "contact_our_support",
};

const OldBrowserInfo = () => {
    const { formatMessage } = useFormattedMessage();
    const currentTenant = useUtils().tenant;

    return (
        <div className="main header-none flex column align-center justify-center">
            <div className="main-container">
                <div className="flex column no-found-info">
                    <div className="flex column info-text">
                        <h1>Sorry!</h1>
                        <h2>{formatMessage(messages.firstLine)}</h2>
                        <h3>{formatMessage(messages.secondLine)}</h3>
                        <h3>{formatMessage(messages.thirdLine)}</h3>
                        <h4 className="email">
                            <a href={`mailto:${currentTenant?.SMTPUser}`}>{currentTenant?.SMTPUser}</a>
                        </h4>
                    </div>
                    <i className="fas fa-exclamation-triangle" />
                </div>
            </div>
        </div>
    );
};
export default OldBrowserInfo;

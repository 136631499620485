import { createSelector } from "reselect";

const getApp = (state) => state.app;

export const selectHost = createSelector(getApp, (app) => app?.host);
export const selectIsMpin = createSelector(getApp, (app) => app?.isMpin);
export const selectTenant = createSelector(getApp, (app) => app?.tenant);
export const selectModals = createSelector(getApp, (app) => app?.modals);
export const selectLocale = createSelector(getApp, (app) => app?.locale);
export const selectIsProdEnv = createSelector(getApp, (app) => app?.isProdEnv);
export const selectFinxConfig = createSelector(getApp, (app) => app?.finxConfig);
export const selectEnteredUrl = createSelector(getApp, (app) => app?.entered_url);
export const selectIsAppClubDeal = createSelector(getApp, (app) => app?.isClubDeal);
export const selectDocumentHost = createSelector(getApp, (app) => app?.documentHost);
export const selectCurrentTenant = createSelector(getApp, (app) => app?.currentTenant);
export const selectIsNotification = createSelector(getApp, (app) => app?.isNotification);
export const selectPlatformSource = createSelector(getApp, (app) => app?.platformSource);
export const selectMobilePlatform = createSelector(getApp, (app) => app?.mobilePlatform);
export const selectIsCurrentTenantFinexity = createSelector(getApp, (app) => app?.isTenantFinexity);
export const selectPlatformSourceFromApp = createSelector(getApp, (app) => app?.isPlatformSourceFromApp);
